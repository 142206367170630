import React ,{Component} from "react";

import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import ListarControlV from "../controlVehicular/ListarControlV";
import Cookies from "universal-cookie";
import { MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption } from "mdbreact";
const cookies = new Cookies();

export default class CrearControlV extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			codigo: "",
			descripcion: "",
			id_condomino: "",
			usuario_modificacion: "",
			errores: [],
			condominos:[],
			datosCargados:false,
		};
	}
	cargarDatos() {
		
		fetch(Api+"copropietarios.php"+"?listar="+cookies.get("id_empresa"))
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				
				this.setState({
					datosCargados: true,
					condominos: datosRespuesta,
				});

			})
			.catch(console.log);
	};

	componentDidMount() {
		this.cargarDatos();		
	}


	enviarDatos = (e) => {
		e.preventDefault();
		
		const changeLanguage = (event: any)=> {  
  const state = this.state;
  state[id_condomino] = event.target.value;
	
}
		const { codigo, descripcion, id_condomino ,usuario_modificacion} = this.state;
		var datosEnviar = {
			codigo: codigo,
			descripcion: descripcion,
			id_condomino:cookies.get('id_condomino'),
			usuario_modificacion:cookies.get('id'),
		};
		
		var errores = [];
		if (!codigo) errores.push("error_codigo");
		if (!descripcion) errores.push("error_descripcion");
		this.setState({ errores: errores });

		if (errores.length > 0) return false;

	fetch(Api + "controlVehicular.php" + "?insertar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				
				window.location.href = "/listarControlV";
			})
			.catch(console.log);
	};

	cambioValor = (e) => {
		const state = this.state;
		state[e.target.name] = e.target.value;

		this.setState({ state, errores: [] });
	};
	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}

	render() {
		const { codigo, descripcion, id_condomino,condominos,usuario_modificacion } = this.state;
		const changeLanguage = (event: any)=> {  
  const state = this.state;
  state[id_condomino] = event.target.value;
	cookies.set('id_condomino',state[id_condomino],{path:"/"});
	
}
		return (
			<div className="card text-primary">
				<div className="card-header">
					<h4> Crear Control Vehicular</h4>
				</div>
				<div className="card-body">
					<form onSubmit={this.enviarDatos}>
					
						<div className="form-group">
						<label htmlFor="">Condomino:</label>
						<br/>
						<select class="custom-select custom-select-lg|custom-select-sm" onChange={(event)=>{changeLanguage(event)}}>
							<option selected>Seleccionar</option>
							{condominos.map((condomino) => (
							<option value={condomino.ID_CONDOMINO}>{condomino.NOMBRE} {condomino.APELLIDO}</option>
								
								))}
							</select>
							<br/>
							<small id="helpNombre" className="invalid-feeback">
								Seleccione Condomino
							</small>
							<br />

							<label htmlFor="">Código:</label>
							<input
								onChange={this.cambioValor}
								value={codigo}
								type="number"
								name="codigo"
								id="codigo"
								className={
									(this.verificarError("error_codigo")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Código"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Código del Control
							</small>
							<br />
							<label htmlFor="">Descripción:</label>
							<input
								onChange={this.cambioValor}
								value={descripcion}
								type="text"
								name="descripcion"
								id="descripcion"
								className={
									(this.verificarError("error_descripcion")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Descripción"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe la descripción del control
							</small>
						</div>
						<div
							className="btn-group"
							role="group"
							aria-label="Basic example"
						>
							<button type="submit" className="btn btn-success">
								Guardar Control
							</button>
							<Link
								to={"/listaControlP"}
								className="btn btn-primary"
							>
								Cancelar
							</Link>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
