import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import { format } from "date-fns";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class ListarInventario extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			inventario: [],
		};
	}

	cargarDatos() {
		console.log("id" + cookies.get("id"));
		fetch(
			Api +
				"inventario.php" +
				"?listar=" +
				cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					inventario: datosRespuesta,
				});
			})
			.catch(console.log);
	}

	componentDidMount() {
		this.cargarDatos();
	}

	borrarRegistro = (ID_INVENTARIO) => {
		fetch(Api + "inventario.php" + "?borrar=" + ID_INVENTARIO)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(ID_INVENTARIO);
				console.log(datosRespuesta);
				this.cargarDatos();
				window.location.href = "/listarInventario";
			})
			.catch(console.log);
	};
	formatear(date){
		const formattedDate =format(date, "MMMM , yyyy");
		console.log(formattedDate);
	}
	render() {
		const date = new Date("2016-01-04 10:34:23");

		const formattedDate = format(date, "MMMM , yyyy");

		console.log(formattedDate);
		const { datosCargados, inventario } = this.state;
		return (
			<Fragment>
				<div className="card text-primary">
					<div className={(cookies.get("perfil") == 'DIRECTIVOS' ? "d-none" : "") +" card-header"}>
						<Link
							to={"/registrarInventario"}
							className="btn btn-success"
						>
							Agregar Nuevo Registro
						</Link>
					</div>
					<div className="card-body">
						<h4>Lista de Inventarios</h4>
						<table className="table table-hover">
							<caption>Lista de Iventarios</caption>
							<thead className="thead-dark|thead-light">
								<tr>
									<th scope="col">Id</th>
									<th scope="col">Código</th>
									<th scope="col">Nombre</th>
									<th scope="col">Descripción</th>
									<th scope="col">Valor</th>
									<th scope="col">Acciones</th>
									</tr>
							</thead>
							<tbody>
								{inventario.map((inventarios) => (
									<tr key={inventarios.ID_INVENTARIO}>
										<th scope="row">
											{inventarios.ID_INVENTARIO}
										</th>
										<td>{inventarios.CODIGO}</td>
										<td>{inventarios.NOMBRE}</td>
										<td>{inventarios.DESCRIPCION}</td>
										<td>{inventarios.VALOR}</td>
										
										<td>
											{" "}
											<div
												className={(cookies.get("perfil") == 'DIRECTIVOS' ? "d-none" : "") +" btn-group"}
												role="group"
												aria-label="Basic example"
											>
												<Link
													to={
														"/editarInventario/" +
														inventarios.ID_INVENTARIO
													}
													className="btn btn-warning"
												>
													Editar
												</Link>

												<Link
													to={"/"}
													className="btn btn-danger"
													onClick={() =>
														this.borrarRegistro(
															inventarios.ID_INVENTARIO
														)
													}
												>
													Borrar
												</Link>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</Fragment>
		);
	}
}
