import React from "react";
import Api from "../servicios/Api";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import {
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
} from "mdbreact";
import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
const cookies = new Cookies();
export default class EditarIngreso extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			ingresos: [],
			errores: [],
			condominos: [],
			id_condomino: "",
			mes : "",
		};
		this.cambioValor = this.cambioValor.bind(this);
	}



	cargarDatos() {
		console.log("id" + cookies.get("id"));
		fetch(
			Api + "copropietarios.php" + "?listar=" + cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					condominos: datosRespuesta,
				});
			})
			.catch(console.log);
	}
	cambioValor = (e) => {
		const state = this.state.ingresos;
		state[e.target.name] = e.target.value;
		this.setState({ ingresos: state, errores: [] });
	};
	enviarDatos = (e) => {
		e.preventDefault();
		console.log("Formulario enviado.....");
		const {
			ID_OTROS,
			NUMERO_COMPROBANTE,
			DESCRIPCION,
			VALOR,
			ID_CONDOMINO,
			USUARIO_MODIFICACION,
			FECHA_REGISTRO,

		} = this.state.ingresos;
		if (cookies.get("id_condomino") != null && cookies.get("mes")!=null) {
			var datosEnviar = {
				ID_OTROS: ID_OTROS,
				NUMERO_COMPROBANTE : NUMERO_COMPROBANTE,
				DESCRIPCION: DESCRIPCION,
				VALOR: VALOR,
				ID_CONDOMINO: cookies.get("id_condomino"),
				USUARIO_MODIFICACION: cookies.get("id"),
				FECHA_REGISTRO:cookies.get("mes"),
			};
		} 
		if (cookies.get("id_condomino") == null && cookies.get("mes")!=null) {
			var datosEnviar = {
				ID_OTROS: ID_OTROS,
				NUMERO_COMPROBANTE : NUMERO_COMPROBANTE,
				DESCRIPCION: DESCRIPCION,
				VALOR: VALOR,
				ID_CONDOMINO: ID_CONDOMINO,
				USUARIO_MODIFICACION: cookies.get("id"),
				FECHA_REGISTRO:cookies.get("mes"),
			};
		} 
		if (cookies.get("id_condomino") != null && cookies.get("mes")==null) {
			var datosEnviar = {
				ID_OTROS: ID_OTROS,
				NUMERO_COMPROBANTE : NUMERO_COMPROBANTE,
				DESCRIPCION: DESCRIPCION,
				VALOR: VALOR,
				ID_CONDOMINO:cookies.get("id_condomino"),
				USUARIO_MODIFICACION: cookies.get("id"),
				FECHA_REGISTRO:FECHA_REGISTRO,
			};
		}
		if (cookies.get("id_condomino") == null && cookies.get("mes")==null) {
			var datosEnviar = {
				ID_OTROS: ID_OTROS,
				NUMERO_COMPROBANTE : NUMERO_COMPROBANTE,
				DESCRIPCION: DESCRIPCION,
				VALOR: VALOR,
				ID_CONDOMINO: ID_CONDOMINO,
				USUARIO_MODIFICACION: cookies.get("id"),
				FECHA_REGISTRO:FECHA_REGISTRO,
			};
		}
		
		var errores = [];
		if (!NUMERO_COMPROBANTE) errores.push("error_numero_comprobante");
		if (!DESCRIPCION) errores.push("error_descripcion");
		if (!VALOR) errores.push("error_valor");
		this.setState({ errores: errores });
		console.log(errores);
		if (errores.length > 0) return false;
		console.log(datosEnviar);
		fetch(Api + "ingresos.php" + "?actualizar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
			
		})
		
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				window.location.href = "/listarIngresos";
			})
			.catch(console.log);
	};
	componentDidMount() {
		console.log(this.props.match.params.id);
		fetch(
			Api +
				"ingresos.php" +
				"?consultar=" +
				this.props.match.params.id
		)
			.then((respuesta) => respuesta.json())
			.then((datosIngreso) => {
				console.log(datosIngreso);
				this.setState({
					datosCargados: true,
					ingresos: datosIngreso[0],
				});
			})
			.catch(console.log);
		this.cargarDatos();

	}

	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}
	render() {
		const dateValue :Date = new Date(new Date().getFullYear(), new Date().getMonth,20);
		const changeMes = (event: any) => {
			const state = this.state;
			state[mes] = event.target.value;
			cookies.set("mes", state[mes], { path: "/" });
			console.log(cookies.get('mes'));
		};
		const { datosCargados, ingresos, condominos, id_condomino ,mes} =
			this.state;
		const changeLanguage = (event: any) => {
			const state = this.state;
			state[id_condomino] = event.target.selectedIndex;
			cookies.set("id_condomino", state[id_condomino], { path: "/" });
		};
		let selectedOptionId = 0;
		return (
			<div className="card text-primary|secondary|success|danger|warning|info|light|dark bg-primary|secondary|success|danger|warning|info|light|dark">
				<div className="card-header">Featured</div>
				<div className="card-body">
					<form onSubmit={this.enviarDatos}>

					<div className="form-group ">
							<label htmlFor="">Id</label>
							<input
								onChange={this.cambioValor}
								readOnly
								type="text"
								className="form-control"
								name="ID_OTROS"
								id="ID_OTROS"
								value={ingresos.ID_OTROS}
							/>
						</div>

						<div className="form-group">
							<label htmlFor="">Condomino:</label>
							<br />
							<select
								class="custom-select custom-select-lg|custom-select-sm"
								onChange={(event) => {
									changeLanguage(event);
								}}
							>
								<option selected>Seleccionar</option>
								{condominos.map((condomino) => (
									<option value={condomino.ID_CONDOMINO}>
										{condomino.NOMBRE} {condomino.APELLIDO}
									</option>
								))}
							</select>
							<br />
							<small id="helpNombre" className="invalid-feeback">
								Seleccione Condomino
							</small>
							<br />
							<div className="form-group ">
							<label htmlFor="">
								Nombres Condomino Registrado
							</label>
							<div>
								<div className="alert alert-success">
									<strong>
										{ingresos.NOMBRE}{" "}
										{ingresos.APELLIDO}
									</strong>
								</div>
							</div>
						</div>
							<label htmlFor="">Número Comprobante:</label>
							<input
								onChange={this.cambioValor}
								value={ingresos.NUMERO_COMPROBANTE}
								type="number"
								name="NUMERO_COMPROBANTE"
								id="NUMERO_COMPROBANTE"
								className={
									(this.verificarError(
										"error_numero_comprobante"
									)
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Número Comprobante"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Número Comprobante
							</small>
							<br />
							<label htmlFor="">Descripción:</label>
							<input
								onChange={this.cambioValor}
								value={ingresos.DESCRIPCION}
								type="text"
								name="DESCRIPCION"
								id="DESCRIPCION"
								className={
									(this.verificarError(
										"error_descripcion"
									)
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Descripción"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Descripción
							</small>
							<br />
							<label htmlFor="">Fecha de Registro:</label>
							<CalendarComponent  onChange={(event) => {
									changeMes(event);
								}} value={dateValue} start="Decade" depth="Year" id="calendar" />

							<br />
							
							<small id="helpNombre" className="invalid-feeback">
								Escoja la Fecha del Registro
							</small>
							<br/>
							<label htmlFor="">Fecha Registrada</label> 
							<div className="alert alert-success">							
							<br/>							
							<label className="lbl lbl-success" htmlFor=""><strong>{ingresos.FECHA_REGISTRO}</strong></label> 
							</div>							
							<label htmlFor="">Valor:</label>
							<input pattern="^\d*(\.\d{0,2})?$"
								onChange={this.cambioValor}
								value={ingresos.VALOR}
								type="number"
								name="VALOR"
								id="VALOR"
								className={
									(this.verificarError("error_valor")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Valor"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Valor del Registro
							</small>
						</div>
						<div
							className="btn-group"
							role="group"
							aria-label="Basic example"
						>
							<button type="submit" className="btn btn-success">
								Guardar Registro
							</button>
							<Link
								to={"/listarIngresos"}
								className="btn btn-primary"
							>
								Cancelar
							</Link>
						</div>
					</form>
				</div>
			
			</div>
		);
	}
}
