import React from "react";
import Api from "../servicios/Api";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import {
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
} from "mdbreact";
import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
const cookies = new Cookies();
export default class EditarServicio extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			servicio: [],
			errores: [],
			condominos: [],
			id_condomino: "",
			inventarios:[],
			id_inventario:"",			
		};
		this.cambioValor = this.cambioValor.bind(this);
	}



	cargarDatos() {
		console.log("id" + cookies.get("id"));
		fetch(
			Api + "copropietarios.php" + "?listar=" + cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					condominos: datosRespuesta,
				});
			})
			.catch(console.log);
	}

	cargarInventario() {
		console.log("id" + cookies.get("id"));
		fetch(
			Api + "inventario.php" + "?listar=" + cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					inventarios: datosRespuesta,
				});
			})
			.catch(console.log);
	}

	cambioValor = (e) => {
		const state = this.state.servicio;
		state[e.target.name] = e.target.value;
		this.setState({ servicio: state, errores: [] });
	};

	enviarDatos = (e) => {
		e.preventDefault();
		console.log("Formulario enviado.....");
		const {
			ID_SOLICITUD,
			DESCRIPCION,
			DIAS,
			ID_INVENTARIO,
			ID_CONDOMINO,
			USUARIO_MODIFICACION,
		} = this.state.servicio;
		if (cookies.get("id_condomino") != null && cookies.get("id_inventario")!=null) {
			var datosEnviar = {
				ID_SOLICITUD: ID_SOLICITUD,
				DESCRIPCION : DESCRIPCION,
				ID_INVENTARIO: cookies.get("id_inventario"),
				DIAS: DIAS,
				ID_CONDOMINO: cookies.get("id_condomino"),
				USUARIO_MODIFICACION: cookies.get("id"),
			};
		} 
		if (cookies.get("id_condomino") != null && cookies.get("id_inventario")==null) { 
			var datosEnviar = {
				ID_SOLICITUD: ID_SOLICITUD,
				DESCRIPCION : DESCRIPCION,
				ID_INVENTARIO: ID_INVENTARIO,
				DIAS: DIAS,
				ID_CONDOMINO: cookies.get("id_condomino"),
				USUARIO_MODIFICACION: cookies.get("id"),
			};
		}
		if (cookies.get("id_condomino") == null && cookies.get("mes")!=null) {
			var datosEnviar = {
				ID_SOLICITUD: ID_SOLICITUD,
				DESCRIPCION : DESCRIPCION,
				ID_INVENTARIO: cookies.get("id_inventario"),
				DIAS: DIAS,
				ID_CONDOMINO: ID_CONDOMINO,
				USUARIO_MODIFICACION: cookies.get("id"),
			};
		}
		if (cookies.get("id_condomino") == null && cookies.get("mes")==null) {
			var datosEnviar = {
				ID_SOLICITUD: ID_SOLICITUD,
				DESCRIPCION : DESCRIPCION,
				ID_INVENTARIO: ID_INVENTARIO,
				DIAS: DIAS,
				ID_CONDOMINO: ID_INVENTARIO,
				USUARIO_MODIFICACION: cookies.get("id"),
			};
		}
		var errores = [];
		if (!DESCRIPCION) errores.push("error_descripcion");
		if (!DIAS) errores.push("error_dias");
		this.setState({ errores: errores });

		if (errores.length > 0) return false;
		console.log(datosEnviar);
		fetch(Api + "servicio.php" + "?actualizar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
			
		})
		
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				window.location.href = "/listarServicio";
			})
			.catch(console.log);
	};
	componentDidMount() {
		console.log(this.props.match.params.id);
		fetch(
			Api +
				"servicio.php" +
				"?consultar=" +
				this.props.match.params.id
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					servicio: datosRespuesta[0],
				});
			})
			.catch(console.log);
		this.cargarDatos();
		this.cargarInventario();

	}

	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}
	render() {
		const {
			descripcion,
			dias,
			id_inventario,
			id_condomino,
			condominos,
			usuario_modificacion,
			inventarios,
			servicio,
		} = this.state;
		const changeLanguage = (event: any) => {
			const state = this.state;
			state[id_condomino] = event.target.value;
			cookies.set("id_condomino", state[id_condomino], { path: "/" });
		};
		const changeInv = (event: any) => {
			const state = this.state;
			state[id_inventario] = event.target.value;
			cookies.set("id_inventario", state[id_inventario], { path: "/" });
		};
		let selectedOptionId = 0;
		return (
			<div className="card text-primary|secondary|success|danger|warning|info|light|dark bg-primary|secondary|success|danger|warning|info|light|dark">
				<div className="card-header">Featured</div>
				<div className="card-body">
					<form onSubmit={this.enviarDatos}>

					<div className="form-group ">
							<label htmlFor="">Id</label>
							<input
								onChange={this.cambioValor}
								readOnly
								type="text"
								className="form-control"
								name="ID_SOLICITUD"
								id="ID_SOLICITUD"
								value={servicio.ID_SOLICITUD}
							/>
						</div>

						<div className="form-group">
							<label htmlFor="">Condomino:</label>
							<br />
							<select defaultValue={selectedOptionId}
								class="custom-select custom-select-lg|custom-select-sm"
								onChange={(event) => {
									changeLanguage(event);
								}}
							>
								<option selected>Seleccionar</option>
								{condominos.map((condomino) => (
									<option value={condomino.ID_CONDOMINO}>
										{condomino.NOMBRE} {condomino.APELLIDO}
									</option>
								))}
							</select>
							<br />
							<small id="helpNombre" className="invalid-feeback">
								Seleccione Condomino
							</small>
							<br />
							<div className="form-group ">
							<label htmlFor="">
								Nombres Condomino Registrado
							</label>
							<div>
								<div className="alert alert-success">
									<strong>
										{servicio.NOMBRE}{" "}
										{servicio.APELLIDO}
									</strong>
								</div>
							</div>
						</div>
							<label htmlFor="">Descripción:</label>
							<input
								onChange={this.cambioValor}
								value={servicio.DESCRIPCION}
								type="text"
								name="DESCRIPCION"
								id="DESCRIPCION"
								className={
									(this.verificarError(
										"error_descripcion"
									)
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Descripción"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe la Descripción
							</small>
							<br />
							<label htmlFor="">Inventario:</label>
							<select defaultValue={selectedOptionId}
								class="custom-select custom-select-lg|custom-select-sm"
								onChange={(event) => {
									changeInv(event);
								}}
							>
								<option selected>Seleccionar</option>
								{inventarios.map((inventario) => (
									<option value={inventario.ID_INVENTARIO}>
										{inventario.NOMBRE}
									</option>
								))}
							</select>

							<br />
							
							<small id="helpNombre" className="invalid-feeback">
								Seleccione el Item del Inventario
							</small>
							<br/>
							<label htmlFor="">Bien Registrado</label> 
							<div className="alert alert-success">							
							<br/>							
							<label className="lbl lbl-success" htmlFor=""><strong>{servicio.BIEN}</strong></label> 
							</div>							
							<label htmlFor="">Días:</label>
							<input pattern="^\d*(\.\d{0,2})?$"
								onChange={this.cambioValor}
								value={servicio.DIAS}
								type="number"
								name="DIAS"
								id="DIAS"
								className={
									(this.verificarError("error_dias")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Días"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe los días de prestamo
							</small>
						</div>
						<div
							className="btn-group"
							role="group"
							aria-label="Basic example"
						>
							<button type="submit" className="btn btn-success">
								Guardar Registro
							</button>
							<Link
								to={"/listarServicio"}
								className="btn btn-primary"
							>
								Cancelar
							</Link>
						</div>
					</form>
				</div>
			
			</div>
		);
	}
}
