import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import { format } from "date-fns";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class ListarCostos extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			costos: [],
		};
	}

	cargarDatos() {
		console.log("id" + cookies.get("id"));
		fetch(
			Api + "costosBancarios.php" + "?listar=" + cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					costos: datosRespuesta,
				});
			})
			.catch(console.log);
	}

	componentDidMount() {
		this.cargarDatos();
	}

	borrarRegistro = (ID_OTROS) => {
		fetch(Api + "costosBancarios.php" + "?borrar=" + ID_OTROS)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(ID_OTROS);
				console.log(datosRespuesta);
				this.cargarDatos();
				window.location.href = "/ListarCostos";
			})
			.catch(console.log);
	};

	render() {
		const { datosCargados, costos } = this.state;
		return (
			<Fragment>
				<div className="card text-primary">
					<div
						className={
							(cookies.get("perfil") == "DIRECTIVOS"
								? "d-none"
								: "") + " card-header"
						}
					>
						<Link
							to={"/registrarCostos"}
							className="btn btn-success"
						>
							Agregar Nuevo Registro
						</Link>
					</div>
					<div className="card-body">
						<h4>Lista de Costos Bancarios</h4>
						<table className="table table-hover">
							<caption>Lista de Costos Bancarios</caption>
							<thead className="thead-dark|thead-light">
								<tr>
									<th scope="col">Id</th>
									<th scope="col">Descripción</th>
									<th scope="col">Valor</th>
									<th scope="col">Fecha Registro</th>
								</tr>
							</thead>
							<tbody>
								{costos.map((costo) => (
									<tr key={costo.ID_COSTO}>
										<th scope="row">{costo.ID_COSTO}</th>
										<td>{costo.DESCRIPCION}</td>
										<td>{costo.VALOR}</td>
										<td>{costo.FECHA_REGISTRO}</td>
										<td>
											{" "}
											<div
												className={
													(cookies.get("perfil") ==
													"DIRECTIVOS"
														? "d-none"
														: "") + "btn-group"
												}
												role="group"
												aria-label="Basic example"
											>
												<Link
													to={
														"/editarCosto/" +
														costo.ID_COSTO
													}
													className="btn btn-warning"
												>
													Editar
												</Link>

												<Link
													to={"/"}
													className="btn btn-danger"
													onClick={() =>
														this.borrarRegistro(
															costo.ID_COSTOS
														)
													}
												>
													Borrar
												</Link>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</Fragment>
		);
	}
}
