import React from "react";
import Api from "../servicios/Api";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import {
	DatePickerComponent,
	RenderDayCellEventArgs,
	ChangedEventArgs,
	CalendarComponent,
} from "@syncfusion/ej2-react-calendars";
const cookies = new Cookies();
export default class EditarGasto extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			gastos: [],
			errores: [],
			fecha:"",
		};
		this.cambioValor = this.cambioValor.bind(this);
	}

	cambioValor = (e) => {
		const state = this.state.gastos;
		state[e.target.name] = e.target.value;
		this.setState({ gastos: state, errores: [] });
	};
	enviarDatos = (e) => {
		e.preventDefault();
		console.log("Formulario enviado.....");
		const { ID_GASTOS, COMPROBANTES, NOMBRE, DESCRIPCION, VALOR, USUARIO_MODIFICACION,fecha } =
			this.state.gastos;

		var datosEnviar = {
			ID_GASTOS: ID_GASTOS,
			COMPROBANTES: COMPROBANTES,
			DESCRIPCION: DESCRIPCION,
			VALOR: VALOR,			
			USUARIO_MODIFICACION: cookies.get("id"),
			fecha:cookies.get("fecha"),
		};

		var errores = [];
		if (!COMPROBANTES) errores.push("error_comprobantes");
		if (!VALOR) errores.push("error_valor");
		this.setState({ errores: errores });

		if (errores.length > 0) return false;
		fetch(Api + "gastos.php" + "?actualizar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				window.location.href = "/listarGasto";
			})
			.catch(console.log);
	};
	componentDidMount() {
		console.log(this.props.match.params.id);
		fetch(
			Api +
				"gastos.php" +
				"?consultar=" +
				this.props.match.params.id
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					gastos: datosRespuesta[0],
				});
			})
			.catch(console.log);		
	}

	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}
	render() {
		
		
		const { datosCargados, gastos,fecha} =
			this.state;
		const dateValue: Date = new Date(
			new Date().getFullYear(),
			new Date().getMonth,
			20
		);
		const changefecha = (event: any) => {
			const state = this.state;
			state[fecha] = event.target.value;
			cookies.set("fecha", state[fecha], { path: "/" });
			var d = new Date(state[fecha]);
			const options = {
				day: "numeric",
				month: "numeric",
				year: "numeric",
			};
			
		};
		return (
			<div className="card text-primary|secondary|success|danger|warning|info|light|dark bg-primary|secondary|success|danger|warning|info|light|dark">
				<div className="card-header">Featured</div>
				<div className="card-body">
					<form onSubmit={this.enviarDatos}>
					<div class="col-md-6">
							<label htmlFor="">Fecha:</label>
							<div className="datepicker-control-section">
								<DatePickerComponent
									onChange={(event) => {
										changefecha(event);
									}}
									dateFormat="YYYY/MM/DD"
									ref={(calendar) =>
										(this.datepickerInstance = calendar)
									}
									value={dateValue}
								></DatePickerComponent>
							</div>
							<br />
						</div>
						<div className="form-group ">
							<label htmlFor="">Id</label>
							<input
								onChange={this.cambioValor}
								readOnly
								type="text"
								className="form-control"
								name="ID_GASTOS"
								id="ID_GASTOS"
								value={gastos.ID_GASTOS}
							/>
						</div>

						<div className="form-group">
						
							<label htmlFor="">Comprobantes</label>
							<input
								onChange={this.cambioValor}
								value={gastos.COMPROBANTES}
								type="text"
								name="COMPROBANTES"
								id="COMPROBANTES"
								className={
									(this.verificarError(
										"error_comprobantes"
									)
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Comprobantes"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe los números de comprobante
							</small>
							
							<br />
							<label htmlFor="">Descripción</label>
							<input
								onChange={this.cambioValor}
								value={gastos.DESCRIPCION}
								type="text"
								name="DESCRIPCION"
								id="DESCRIPCION"
								className={
									(this.verificarError(
										"error_numero"
									)
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Descripción"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe la Descripción
							</small>
							<br />							
							<label htmlFor="">Valor:</label>
							<input
								pattern="^\d*(\.\d{0,2})?$"
								onChange={this.cambioValor}
								value={gastos.VALOR}
								type="number"
								name="VALOR"
								id="VALOR"
								className={
									(this.verificarError("error_valor")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Valor"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Valor del Registro
							</small>
						</div>
						<div
							className="btn-group"
							role="group"
							aria-label="Basic example"
						>
							<button type="submit" className="btn btn-success">
								Guardar Registro
							</button>
							<Link
								to={"/listarGasto"}
								className="btn btn-primary"
							>
								Cancelar
							</Link>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
