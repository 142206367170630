import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import { format } from "date-fns";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class ListarConvenio extends React.Component {
	constructor(props) {
		super(props);

this.state = {
			datosCargados: false,
			convenio: [],
		};
	}

	cargarDatos() {
		console.log("id" + cookies.get("id"));
		fetch(
			Api +
				"convenios.php" +
				"?listar=" +
				cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					convenio: datosRespuesta,
				});
			})
			.catch(console.log);
	}

	componentDidMount() {
		this.cargarDatos();
	}

	borrarRegistro = (ID_CONVENIO) => {
		fetch(Api + "convenios.php" + "?borrar=" + ID_CONVENIO)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(ID_CONVENIO);
				console.log(datosRespuesta);
				this.cargarDatos();
				window.location.href = "/ListarConvenio";
			})
			.catch(console.log);
	};
	
	render() {		
		
		const { datosCargados, convenio } = this.state;
		return (
			<Fragment>
				<div className="card text-primary">
					<div className={(cookies.get("perfil") == 'DIRECTIVOS' ? "d-none" : "") +" card-header"}>
						<Link
							to={"/registrarconvenio"}
							className="btn btn-success"
						>
							Agregar Nuevo Registro
						</Link>
					</div>
					<div className="card-body">
						<h4>Lista de Convenios</h4>
						<table className="table table-hover">
							<caption>Lista de Convenios</caption>
							<thead className="thead-dark|thead-light">
								<tr>
									<th scope="col">Id</th>
									<th scope="col" colSpan="3">
										Condomino
									</th>
									<th scope="col">Descripción</th>
									<th scope="col">Fecha de Convenio</th>
									<th scope="col">Valor</th>
									<th scope="col">Acciones</th>
								</tr>
							</thead>
							<tbody>
								{convenio.map((convenios) => (
									<tr key={convenios.ID_CONVENIO}>
										<th scope="row">
											{convenios.ID_CONVENIO}
										</th>
										<td>{convenios.IDENTIFICACION}</td>
										<td>{convenios.NOMBRE}</td>
										<td>{convenios.APELLIDO}</td>
										<td>{convenios.DESCRIPCION}</td>
										<td>{convenios.FECHA_CONVENIO}</td>
										<td>{convenios.VALOR_TOTAL}</td>
										
										<td>
											{" "}
											<div
												className={(cookies.get("perfil") == 'DIRECTIVOS' ? "d-none" : "") + " btn-group"}
												role="group"
												aria-label="Basic example"
											>
												<Link
													to={
														"/editarConvenio/" +
														convenios.ID_CONVENIO
													}
													className="btn btn-warning"
												>
													Editar
												</Link>

												<Link
													to={"/"}
													className="btn btn-danger"
													onClick={() =>
														this.borrarRegistro(
															convenios.ID_CONVENIO
														)
													}
												>
													Borrar
												</Link>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</Fragment>
		);
	}
}
