import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import { format } from "date-fns";
import Cookies from "universal-cookie";
import { Bar } from "react-chartjs-2";
import logo from "./baner.png";
import logoSol2 from "./logoSoL2.png";
import dateFormat from "dateformat";
import LibretinSol from "./Libretin-Sol-2.png";
import SolDosRecibo from "../imagenesSvg/SolDosRecibo";
import SinaiRecibo from "../imagenesSvg/SinaiRecibo";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	PDFViewer,
	PDFDownloadLink,
	Image,
	ReactPDF,
} from "@react-pdf/renderer";
const cookies = new Cookies();

export default class ListarBalance extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			ingresos: [],
			datosRespuesta: [],
			respuestaAlicuotas: [],
			alicuotass: [],
			otros: [],
			respuestaOtros: [],
			convenios: [],
			respuestaConvenios: [],
			Quixote: "",
			pendientes: [],
		};
	}

	cargarImpresion() {
		var data = this.props.match.params.id;
		const answer_array = data.split(",");
		console.log(answer_array);
		fetch(Api + "registroAlicuotas.php" + "?impresion=" + answer_array[0])
			.then((respuesta) => respuesta.json())
			.then((respuestaAlicuotas) => {
				console.log(respuestaAlicuotas);
				cookies.set("id_condomino", respuestaAlicuotas[0].ID_CONDOMINO);
				this.cargarPendiente(cookies.get("id_condomino"));
				console.log(cookies.get("id_condomino"));
				this.setState({
					datosCargados: true,
					alicuotass: respuestaAlicuotas,
				});
			})
			.catch(console.log);
	}

	cargarPendiente(id_condomino) {
		console.log(id_condomino);
		fetch(Api + "registroAlicuotas.php" + "?pendiente=" + id_condomino)
			.then((respuesta) => respuesta.json())
			.then((respuestaOtros) => {
				console.log(respuestaOtros);
				this.setState({
					datosCargados: true,
					pendientes: respuestaOtros,
				});
			})
			.catch(console.log);
	}

	componentDidMount() {
		this.cargarImpresion();
	}

	render() {
		var Quixote;
		const {
			datosCargados,
			ingresos,
			alicuotass,
			otros,
			convenios,
			pendientes,
		} = this.state;

		let found = alicuotass.map((ali) => ali.APELLIDO);
		cookies.set("id_condomino", found[0], { path: "/" });
		console.log(cookies.get("id_condomino"));

		let alip = ingresos
			.filter(
				(ing) =>
					(ing.ESTADO_PAGO === "PAGADO") &
					(ing.CONCEPTO === "ALICUOTAS")
			)
			.map((ing) => parseInt(ing.SUMA));
		let alicuotasp = parseInt(alip);
		let alicuotas;
		let ali = ingresos
			.filter(
				(ing) =>
					(ing.ESTADO_PAGO === "PENDIENTE") &
					(ing.CONCEPTO === "ALICUOTAS")
			)
			.map((ing) => ing.SUMA);
		if (ali.length == 0) {
			alicuotas = 0;
		} else {
			alicuotas = parseInt(ali);
		}

		let cobp = ingresos
			.filter(
				(ing) =>
					(ing.ESTADO_PAGO === "PENDIENTE") &
					(ing.CONCEPTO === "COBROS")
			)
			.map((ing) => ing.SUMA);
		let cobrosp = parseInt(cobp);
		let cob = ingresos
			.filter(
				(ing) =>
					(ing.ESTADO_PAGO === "PAGADO") & (ing.CONCEPTO === "COBROS")
			)
			.map((ing) => ing.SUMA);
		let cobros = parseInt(cob);
		let vari = ingresos
			.filter((ing) => ing.CONCEPTO === "INGRESOS VARIOS")
			.map((ing) => ing.SUMA);
		let varios = parseInt(vari);
		let saldo = parseInt(alicuotas) + parseInt(cobrosp);
		const numero2 = ingresos.map((ing) => ing.ingresos);
		let sumar = parseInt(alicuotasp) + parseInt(cobros) + parseInt(varios);

		const data = {
			labels: [
				"Alícuotas Pagadas",
				"Alicuotas Pendientes",
				"Otros por Cobrar Pagos",
				"Otros por Cobrar Pendiente",
				"Otros Pagos",
				"Saldo por Pagar",
			],
			datasets: [
				{
					label: "VALORES",
					backgroundColor: "#cfe2ff",
					borderColor: "#fff3cd",
					borderWidth: 1,
					hoverBackgroundColor: "",
					hoverBorderColor: "#Ff0000",
					data: [
						alicuotasp,
						alicuotas,
						cobros,
						cobrosp,
						varios,
						saldo,
					],
				},
			],
		};
		const opciones = {
			maintainAspectRatio: false,
			responsive: true,
		};
		const BORDER_COLOR = "#bfbfbf";
		const BORDER_STYLE = "solid";
		const COL1_WIDTH = 100;
		const COL2_WIDTH = 60;
		const COLN_WIDTH = (100 - COL1_WIDTH) / 3;
		const COL3_WIDTH = 100 / 2;
		const styles = StyleSheet.create({
			body: {
				padding: 20,
				backgroundImage: { LibretinSol },
			},
			iframe: {
				width: "400px !important",
				height: "350px !important",
			},
			table: {
				display: "table",
				width: "auto",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 0,
				borderRightWidth: 0,
				borderBottomWidth: 0,
				backgroundImage: { logoSol2 },
			},
			table2: {
				display: "table",
				width: "auto",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 0,
				borderRightWidth: 0,
				borderBottomWidth: 0,
			},
			tableRow: {
				margin: "auto",
				flexDirection: "row",
			},
			tableRow2: {
				marginLeft: "30px",
				flexDirection: "row",
			},
			tableCol1Header: {
				width: COL1_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderBottomColor: "#000",
				borderWidth: 0,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},

			tableCol3Header: {
				width: COL3_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderBottomColor: "#000",
				borderWidth: 0,
				borderLeftWidth: 1,
				borderTopWidth: 0,
				borderRightWidth: 1,
			},
			tableColHeader: {
				width: COLN_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderBottomColor: "#000",
				borderWidth: 0,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableCol1: {
				width: COL1_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 0,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableCol: {
				width: COLN_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 0,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableCellHeader: {
				margin: 3,
				fontSize: 10,
				fontWeight: 500,
			},
			tableCell: {
				margin: 5,
				fontSize: 10,
			},
			section: {
				margin: 2,
				padding: 1,
			},
			titulo: {
				fontSize: 20,
				fontWeight: 500,
			},
			saldo: {
				color: "#a85960",
			},
			compro: {
				color: "#a85960",
				fontSize: 15,
			},
			descripcion: {
				margin: 3,
				fontSize: 8,
			},
			tableCol2: {
				width: COL2_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 0,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableCol5: {
				width: 40 + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 0,
				borderLeftWidth: 0,
				borderTopWidth: 0,
				align: "left",
			},
			tableCol2Header: {
				width: COL2_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderBottomColor: "#000",
				borderWidth: 0,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableColImagen: {
				width: COL1_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderBottomColor: "#000",
				borderWidth: 0,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},

			tableCol5Header: {
				width: COL3_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderBottomColor: "#000",
				borderWidth: 0,
				borderLeftWidth: 0,
				borderTopWidth: 0,
				borderRightWidth: 0,
			},
			containerbackground: {
				margin: "3rem",
				position: "absolute",
				top: 0,
				left: 0,
				bottom: 0,
				transform: "rotate(0deg)",
				color: "#24292f",
				fontSize: 30,
				opacity: ".0",
			},
			pageBackground: {
				position: "absolute",
				minWidth: "100%",
				minHeight: "100%",
				display: "block",
				height: "100%",
				width: "100%",
			},
			containerbackground2: {
				minWidth: "100%",
				minHeight: "100%",
				position: "absolute",
				transform: "rotate(0deg)",
				color: "#c6afaf",
				fontSize: 30,
				opacity: ".0",
			},
			tableCol6: {
				width: 15 + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 0,
				borderLeftWidth: 0,
				borderTopWidth: 0,
				align: "left",
			},
			tableCol7: {
				width: 6 + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 0,
				borderLeftWidth: 0,
				borderTopWidth: 0,
				align: "left",
			},
			tableCelHeader2: {
				margin: 1,
				fontSize: 10,
				fontWeight: 500,
			},
		});
		var nombre = ingresos.map((ing) => ing.NOMBRE);
		var apellido = ingresos.map((ing) => ing.APELLIDO);
		var identificacion = ingresos.map((ing) => ing.IDENTIFICACION);
		var casa_ofi_depa = ingresos.map((ing) => ing.CASA_OFI_DEP);
		var empresa = ingresos.map((ing) => ing.EMPRESA);
		var cop_arre = alicuotass.map((ali) => ali.COPRO_ARREN);
		if ((cop_arre = "C")) {
			cop_arre = "COPROPIETARIO";
		} else {
			cop_arre = "ARRENDATARIO";
		}
		let imagen ="";
		if(cookies.get('empresa')==="CIUDAD DEL SOL DOS"){
			imagen=SolDosRecibo;
		}else{
			imagen=SinaiRecibo;
		}
		Quixote = () => (
			<Document>
				<Page size="A4" style={styles.body}>
					{imagen}
						
					<View style={styles.table1}>
						<View style={styles.tableRow}>
							<Text style={styles.titulo}>{""}</Text>
						</View>
					</View>
					<View style={styles.table1}>
						<View style={styles.tableRow}>
							<Text style={styles.titulo}>{""}</Text>
						</View>
					</View>
					<View style={styles.table2}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol5Header}>
								<Text style={styles.titulo}>{""}</Text>
							</View>
							<View style={styles.tableCol5Header}>
								<Text style={styles.titulo}>{"  "}</Text>
							</View>
						</View>
					</View>

					<View style={styles.table2}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol5}>
								<Text style={styles.tableCellHeader}> </Text>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
							<View style={styles.tableCol5}></View>
							<View style={styles.tableCol5}>
								<Text style={styles.compro}>
									{alicuotass.map(
										(ali) => ali.NUMERO_COMPROBANTE
									)}
								</Text>
							</View>
						</View>
					</View>
					<View style={styles.table2}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol6}></View>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									{alicuotass.map((ali) => ali.tipoPago)}
								</Text>
							</View>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									{alicuotass.map((ali) => ali.concepto)}
								</Text>
							</View>
						</View>
					</View>

					<View style={styles.table2}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol5}>
								<Text style={styles.tableCellHeader}> </Text>
							</View>
							<View style={styles.tableCol5}></View>
							<View style={styles.tableCol5}>
								<Text style={styles.compro}> </Text>
							</View>
						</View>
					</View>

					<View style={styles.table2}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol6}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
							<View style={styles.tableCol5}>
								<Text style={styles.tableCellHeader}>
									{alicuotass.map(
										(ali) => ali.FECHA_REGISTRO
									)}
								</Text>
							</View>
							<View style={styles.tableCol6}>
								<Text style={styles.descripcion}>
									{alicuotass.map((ali) => ali.CASA_OFI_DEP)}
								</Text>
							</View>
							
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
							<View style={styles.tableCol6}>
								<Text style={styles.tableCellHeader}>
									{alicuotass.map((ali) => ali.VALOR)}
								</Text>
							</View>
						</View>
					</View>

					<View style={styles.table2}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol5}>
								<Text style={styles.tableCellHeader}></Text>
							</View>
							<View style={styles.tableCol5}></View>
						</View>
					</View>
					<View style={styles.table2}>
						<View style={styles.tableRow}></View>
						<View style={styles.tableRow}>
							<View style={styles.tableCol3Header}>
								<Text style={styles.tableCellHeader}>
									{"                          "}
									{alicuotass.map((ali) => ali.NOMBRE)}{" "}
									{alicuotass.map((ali) => ali.APELLIDO)}
								</Text>

								<Text style={styles.tableCellHeader}>
									{alicuotass.map((ali) => ali.concepto)}{" "}
									{alicuotass.map((ali) => ali.MES)}
								</Text>

								<Text style={styles.tableCellHeader}>
									{" "}
									{alicuotass.map((ali) => ali.DESCRIPCION)}
								</Text>
							</View>
						</View>
					</View>

					<View style={styles.table}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol1Header}></View>
						</View>
						<View style={styles.tableRow}>
							<View style={styles.tableCol1Header}>
								<View style={styles.section}>
									<Text style={styles.tableCellHeader}>
										<Text>
											{
												"                                         "
											}
										</Text>
										<Text>
											{
												"                                         "
											}
										</Text>
										<Text>
											{
												"                                         "
											}
										</Text>

										{alicuotass.map(
											(ali) => ali.NUM_CHEQUE_DEPOSITO
										)}
									</Text>
								</View>
							</View>
						</View>
					</View>

					<View style={styles.table1}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									{"                  "}
								</Text>
							</View>
						</View>
					</View>
					<View style={styles.table1}>
						<View style={styles.tableRow2}>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                   "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                   "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                          "}
									</Text>{" "}
								</Text>
								<Text style={styles.tableCellHeader}> </Text>
							</View>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									<Text style={styles.tableCellHeader}>
										{"            "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"            "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>

									<Text style={styles.tableCellHeader}>
										{"                     "}
									</Text>
									{alicuotass.map((ali) => ali.VALOR)}
								</Text>
								<Text style={styles.tableCellHeader}>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{alicuotass.map(
											(ali) => ali.COPRO_ARREN
										)}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"    "}
									</Text>
									{pendientes.map((pen) => pen.SUMA)}
								</Text>
							</View>
						</View>

						<View style={styles.tableRow2}>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                   "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                   "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                          "}
									</Text>{" "}
								</Text>
								<Text style={styles.tableCellHeader}> </Text>
							</View>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>

									<Text style={styles.tableCellHeader}>
										{"                          "}
									</Text>
									{"   "}
								</Text>
							</View>
						</View>
					</View>
					<View style={styles.table1}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
						</View>
					</View>

					<View style={styles.table2}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol5}>
								<Text style={styles.tableCellHeader}> </Text>
							</View>
							<View style={styles.tableCol5}></View>
							<View style={styles.tableCol5}>
								<Text style={styles.compro}> </Text>
							</View>
						</View>
						<View style={styles.tableRow}>
							<View style={styles.tableCol5}>
								<Text style={styles.tableCellHeader}> </Text>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
							<View style={styles.tableCol5}></View>
							<View style={styles.tableCol5}>
								<Text style={styles.compro}>
									{alicuotass.map(
										(ali) => ali.NUMERO_COMPROBANTE
									)}
								</Text>
							</View>
						</View>
					</View>
					<View style={styles.table2}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol6}></View>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}> </Text>
							</View>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}> </Text>
							</View>
						</View>
					</View>

					<View style={styles.table2}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol7}></View>
							<View style={styles.tableCol5}>
								<Text style={styles.tableCellHeader}>
									{alicuotass.map((ali) => ali.tipoPago)}
								</Text>
							</View>
							<View style={styles.tableCol7}></View>
							<View style={styles.tableCol7}></View>
							<View style={styles.tableCol5}>
								<Text style={styles.tableCellHeader}>
									{alicuotass.map((ali) => ali.concepto)}
								</Text>
							</View>
						</View>
					</View>

					<View style={styles.table2}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol6}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
							<View style={styles.tableCol5}>
								<Text style={styles.tableCellHeader}>
									{alicuotass.map(
										(ali) => ali.FECHA_REGISTRO
									)}
								</Text>
							</View>
								<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
							<View style={styles.tableCol6}>
								<Text style={styles.descripcion}>
									{alicuotass.map((ali) => ali.CASA_OFI_DEP)}
								</Text>
							</View>
						
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
							<View style={styles.tableCol6}>
								<Text style={styles.tableCellHeader}>
									{alicuotass.map((ali) => ali.VALOR)}
								</Text>
							</View>
						</View>
					</View>

					<View style={styles.table2}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol5}>
								<Text style={styles.tableCellHeader}></Text>
							</View>
							<View style={styles.tableCol5}></View>
						</View>
					</View>
					<View style={styles.table2}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol3Header}>
								<Text style={styles.tableCellHeader}>
									{"                          "}
									{alicuotass.map((ali) => ali.NOMBRE)}{" "}
									{alicuotass.map((ali) => ali.APELLIDO)}
								</Text>

								<Text style={styles.tableCellHeader}>
									{alicuotass.map((ali) => ali.concepto)}{" "}
									{alicuotass.map((ali) => ali.MES)}
								</Text>

								<Text style={styles.tableCellHeader}>
									{" "}
									{alicuotass.map((ali) => ali.DESCRIPCION)}
								</Text>
							</View>
						</View>
					</View>

					<View style={styles.table}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol1Header}></View>
						</View>
						<View style={styles.tableRow}>
							<View style={styles.tableCol1Header}>
								<View style={styles.section}>
									<Text style={styles.tableCellHeader}>
										<Text>
											{
												"                                         "
											}
										</Text>
										<Text>
											{
												"                                         "
											}
										</Text>
										<Text>
											{
												"                                         "
											}
										</Text>

										{alicuotass.map(
											(ali) => ali.NUM_CHEQUE_DEPOSITO
										)}
									</Text>
								</View>
							</View>
						</View>
					</View>

					<View style={styles.table1}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									{"                  "}
								</Text>
							</View>
						</View>
					</View>
					<View style={styles.table1}>
						<View style={styles.tableRow2}>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{" "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                   "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                          "}
									</Text>{" "}
								</Text>
							</View>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>

									<Text style={styles.tableCellHeader}>
										{"       "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                   "}
									</Text>

									<Text style={styles.tableCellHeader}>
										{"           "}
									</Text>
									{alicuotass.map((ali) => ali.VALOR)}
								</Text>
							</View>
						</View>
						<View style={styles.tableRow2}>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"         "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                   "}
									</Text>

									<Text style={styles.tableCellHeader}>
										{"                          "}
									</Text>
									{"     "}
								</Text>
							</View>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									<Text style={styles.tableCellHeader}>
										{"            "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"            "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                     "}
									</Text>{" "}
								</Text>
								<Text style={styles.tableCellHeader}>
									<Text style={styles.tableCellHeader}>
										{"         "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{alicuotass.map(
											(ali) => ali.COPRO_ARREN
										)}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"        "}
									</Text>
									{pendientes.map((pen) => pen.SUMA)}
								</Text>
							</View>
						</View>

						<View style={styles.tableRow2}>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									<Text style={styles.tableCellHeader}>
										{"            "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"            "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>

									<Text style={styles.tableCellHeader}>
										{"                     "}
									</Text>
									{"    "}
								</Text>
								<Text style={styles.tableCellHeader}>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"   "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                       "}
									</Text>
									{"                       "}
								</Text>
							</View>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>

									<Text style={styles.tableCellHeader}>
										{"                          "}
									</Text>
									{"   "}
								</Text>
							</View>
						</View>
					</View>

					<View style={styles.table2}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol5}>
								<Text style={styles.tableCellHeader}> </Text>
							</View>
							<View style={styles.tableCol5}></View>
							<View style={styles.tableCol5}>
								<Text style={styles.compro}> </Text>
							</View>
						</View>
					</View>

					<View style={styles.table2}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol5}></View>
							<View style={styles.tableCol5}></View>
							<View style={styles.tableCol5}>
								<Text style={styles.compro}>
									<Text style={styles.compro}></Text>

									{"         "}
									{alicuotass.map(
										(ali) => ali.NUMERO_COMPROBANTE
									)}
								</Text>
							</View>
						</View>
					</View>

					<View style={styles.table}>
						<View style={styles.tableRow2}>
							<View style={styles.tableCol6}>
								<Text style={styles.compro}>{"        "}</Text>
							</View>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									{"    "}
								</Text>
							</View>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									{"   "}
								</Text>
							</View>
						</View>
					</View>

					<View style={styles.table2}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol6}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
							<View style={styles.tableCol5}>
								<Text style={styles.tableCellHeader}>
									{alicuotass.map((ali) => ali.tipoPago)}
								</Text>
							</View>
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}> </Text>
							</View>
							<View style={styles.tableCol5}>
								<Text style={styles.tableCellHeader}>
									{alicuotass.map((ali) => ali.concepto)}
								</Text>
							</View>
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}> </Text>
							</View>
						</View>
					</View>

					<View style={styles.table2}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
							<View style={styles.tableCol5}>
								<Text style={styles.tableCellHeader}>
									{alicuotass.map(
										(ali) => ali.FECHA_REGISTRO
									)}
								</Text>
							</View>
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
							<View style={styles.tableCol6}>
								<Text style={styles.descripcion}>
									{alicuotass.map((ali) => ali.CASA_OFI_DEP)}
								</Text>
							</View>
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>

							<View style={styles.tableCol6}>
								<Text style={styles.tableCellHeader}>
									{alicuotass.map((ali) => ali.VALOR)}
								</Text>
							</View>
						</View>
						<View style={styles.tableRow}>
							<View style={styles.tableCol3Header}>
								<Text style={styles.tableCellHeader}>
									{"                          "}
									{alicuotass.map((ali) => ali.NOMBRE)}{" "}
									{alicuotass.map((ali) => ali.APELLIDO)}
								</Text>

								<Text style={styles.tableCellHeader}>
									{alicuotass.map((ali) => ali.concepto)}{" "}
									{alicuotass.map((ali) => ali.MES)}
								</Text>

								<Text style={styles.tableCellHeader}>
									{" "}
									{alicuotass.map((ali) => ali.DESCRIPCION)}
								</Text>
							</View>
						</View>
					</View>

					<View style={styles.table}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol1Header}></View>
						</View>
						<View style={styles.tableRow}>
							<View style={styles.tableCol1Header}>
								<View style={styles.section}>
									<Text style={styles.tableCellHeader}>
										<Text>
											{
												"                                         "
											}
										</Text>
										<Text>
											{
												"                                         "
											}
										</Text>
										<Text>
											{
												"                                         "
											}
										</Text>

										{alicuotass.map(
											(ali) => ali.NUM_CHEQUE_DEPOSITO
										)}
									</Text>
								</View>
							</View>
						</View>
					</View>

					<View style={styles.table1}>
						<View style={styles.tableRow2}>
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
						</View>
						<View style={styles.tableRow2}>
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
						</View>
						<View style={styles.tableRow2}>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}></Text>
							</View>
						</View>
						<View style={styles.tableRow2}>
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
						</View>
						<View style={styles.tableRow2}>
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}>{""}</Text>
							</View>
						</View>
						<View style={styles.tableRow2}>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                   "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                   "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                          "}
									</Text>{" "}
								</Text>
								<Text style={styles.tableCellHeader}> </Text>
							</View>
							<View style={styles.tableCol5Header}>
								<Text style={styles.tableCellHeader}>
									<Text style={styles.tableCellHeader}>
										{"            "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"            "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>

									<Text style={styles.tableCellHeader}>
										{"                     "}
									</Text>
									{alicuotass.map((ali) => ali.VALOR)}
								</Text>
								<Text style={styles.tableCellHeader}>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{alicuotass.map(
											(ali) => ali.COPRO_ARREN
										)}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"                  "}
									</Text>
									<Text style={styles.tableCellHeader}>
										{"    "}
									</Text>
									{pendientes.map((pen) => pen.SUMA)}
								</Text>
							</View>
						</View>
					</View>
				</Page>
			</Document>
		);

		return (
			<Fragment>
				<div className="row d-flex justify-content-center align-items-start h-60">
					<div className="col-md-4 col-lg-6 col-xl-4">
						<PDFViewer className="ClassName">
							<Quixote />
						</PDFViewer>
						<br />
						<PDFDownloadLink
							document={<Quixote />}
							fileName="estadoFinaciero.pdf"
						>
							{({ loading }) =>
								loading ? "Loading" : "Descargar"
							}
						</PDFDownloadLink>
						<Link
							to={"/"}
							className="btn btn-danger"
							onClick={() => this.guardarArchivo()}
						>
							Guardar
						</Link>
						<Link
							to={"/listarAlicuota"}
							className="btn btn-primary"
						>
							Salir
						</Link>
					</div>
				</div>
			</Fragment>
		);
	}
}
