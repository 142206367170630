import React from "react";
import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import ListaCop from "../copropietario/ListaCop";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class CrearCop extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			identificacion: "",
			nombre: "",
			apellido: "",
			correo: "",
			direccion: "",
			telefono: "",
			celular: "",
			casa_ofi_deo: "",
			id:"",
			errores: [],
			id_copArr:"",
			
		};
	}
	cambioValor = (e) => {
		const state = this.state;
		state[e.target.name] = e.target.value;
		this.setState({ state, errores: [] });
	};
	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}
	enviarDatos = (e) => {
		e.preventDefault();
		console.log("Formulario enviado.....");
		const {
			identificacion,
			nombre,
			apellido,
			correo,
			direccion,
			telefono,
			celular,
			casa_ofi_dep,
			id,
			id_copArr,
		} = this.state;
		console.log(nombre);

		var datosEnviar = {
			identificacion: identificacion,
			nombre: nombre,
			apellido: apellido,
			correo: correo,
			direccion: direccion,
			telefono: telefono,
			celular: celular,
			casa_ofi_dep: casa_ofi_dep,
			id:cookies.get('id_empresa'),
			id_copArr:cookies.get('id_copArr'),
		};
		var errores = [];
		if (!identificacion) errores.push("error_identificacion");
		if (!nombre) errores.push("error_nombre");
		if (!apellido) errores.push("error_apellido");
		if (!correo) errores.push("error_correo");
		if (!casa_ofi_dep) errores.push("error_casa_ofi_dep");
		this.setState({ errores: errores });

		if (errores.length > 0) return false;

		fetch(Api + "copropietarios.php" + "?insertar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				window.location.href = "/listaCop";
			})
			.catch(console.log);
	};

	render() {
		let selectedOptionId = 0;
		const {
			identificacion,
			nombre,
			apellido,
			correo,
			direccion,
			telefono,
			celular,
			casa_ofi_dep,
			id,
			id_copArr,
		} = this.state;
		const changeCopArr = (event: any) => {
			const state = this.state;
			state[id_copArr] = event.target.value;
			cookies.set("id_copArr", state[id_copArr], { path: "/" });
			console.log(cookies.get("id_copArr"));
		};
		return (
			<div className="card text-primary">
				<div className="card-header">
					<h4> Crear Condóminos</h4>
				</div>
				<div className="card-body">
					<form onSubmit={this.enviarDatos}>
						<div className="form-group">
							<label htmlFor="">Identificación:</label>
							<input
								onChange={this.cambioValor}
								value={identificacion}
								type="number"
								name="identificacion"
								id="identificacion"
								className={
									(this.verificarError("error_identificacion") ? "is-invalid" : "") +
									" form-control"
								}
								placeholder="Identificación"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe la Identificación del Condómino
							</small>
							<br />
							<label htmlFor="">Nombre:</label>
							<input
								onChange={this.cambioValor}
								value={nombre}
								type="text"
								name="nombre"
								id="nombre"
								className={
									(this.verificarError("error_nombre") ? "is-invalid" : "") +
									" form-control"
								}
								placeholder="Nombre"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el nombre del Condómino
							</small>
							<br />
							<label htmlFor="">Apellido:</label>
							<input
								onChange={this.cambioValor}
								value={apellido}
								type="text"
								name="apellido"
								id="apellido"
								className={
									(this.verificarError("error_apellido") ? "is-invalid" : "") +
									" form-control"
								}
								placeholder="Apellido"
							/>
							<small id="helpApellido" className="invalid-feeback">
								Escribe el apellido del Condómino
							</small>
							<br />
							<label htmlFor="">Correo:</label>
							<input
								onChange={this.cambioValor}
								value={correo}
								type="text"
								name="correo"
								id="correo"
								className={
									(this.verificarError("error_correo") ? "is-invalid" : "") +
									" form-control"
								}
								placeholder="Correo Electrónico"
							/>
							<small id="helpCorreo" className="invalid-feeback">
								Escribe el correo del Condómino
							</small>
							<br/>
							<label htmlFor="">Dirección:</label>
							<input
								onChange={this.cambioValor}
								value={direccion}
								type="text"
								name="direccion"
								id="direccion"
								className=" form-control"
								placeholder="Dirección"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe la Dirección del Condómino
							</small>
							<br />
							<label htmlFor="">Teléfono:</label>
							<input
								onChange={this.cambioValor}
								value={telefono}
								type="number"
								name="telefono"
								id="telefono"
								className=" form-control"
								placeholder="Teléfono"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Teléfono del Condómino
							</small>
							<br />
							<label htmlFor="">Celular:</label>
							<input
								onChange={this.cambioValor}
								value={celular}
								type="text"
								name="celular"
								id="celular"
								className=" form-control"
								placeholder="Celular"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Celular del Condómino
							</small>
							<br />
							<label htmlFor="">Casa-Departamento-Oficina:</label>
							<input
								onChange={this.cambioValor}
								value={casa_ofi_dep}
								type="text"
								name="casa_ofi_dep"
								id="casa_ofi_dep"
								className={
									(this.verificarError("error_casa_ofi_dep") ? "is-invalid" : "") +
									" form-control"
								}
								placeholder="Casa-Departamento-Oficina"
							/>
							<small id="helpCorreo" className="invalid-feeback">
								Escribe la Casa-Departamento-Oficina del Condómino
							</small>
							<br/>
							<label htmlFor="">Propietario / Arrendatario:</label>
							<br/>
							<select
								defaultValue={selectedOptionId}
								class="custom-select custom-select-lg|custom-select-sm"
								onChange={(event) => {
									changeCopArr(event);
								}}
							>
								<option selected>Seleccionar</option>
								<option value="P">COPROPIETARIO</option>
								<option value="A">ARRENDATARIO</option>
							</select>
							<br />
						</div>
						<br/>
						<div className="btn-group" role="group" aria-label="Basic example">
							<button type="submit" className="btn btn-success">
								Guardar Condómino
							</button>
							<Link to={"/listaCop"} className="btn btn-primary">
								Cancelar
							</Link>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
