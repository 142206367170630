import React from "react";
import "./Cabecera.css";
import logo from "./logo.svg";
import { Link } from "react-router-dom";
import {Route,BrowserRouter as Router } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class Cabecera extends React.Component {

borrarCookies() {
     cookies.remove("id", { path: "/" });
    cookies.remove("id_empresa", { path: "/" });    
    window.location.href = "/";
  }
	
	render() {
	return (
		 <Router>
		<div className="jumbotron text-center">
			<div className="row">
				<div className="col col-md-2">
					<img
						src={logo}
						className="App-logo"
						alt="logo"
						style={{ width: "200px" }}
					/>
					<div className={
                  (cookies.get("id") == null ? "d-none" : "")}>
					<h4>{cookies.get("empresa")}</h4>
					</div>
				</div>
				<div className="col col-md-7">
					<h1>CONDOMINO'S</h1>
					<br />
					<h2>Gestión de Propiedad Horizontal Online.</h2>
				</div>
				<div className={
                  (cookies.get("id") == null ? "d-none" : "") +" col col-md-2 align-self-end"}>
				<center>
				{cookies.get("nombre")}
                  <Link
                    onClick={() => this.borrarCookies()}
                    className="btn btn active btn-danger"
                    to={"/"}
                  >
                    <span style={{ color: "white", fontSize: 20 }}>
                      <i className="fas fa-lock"></i>Cerrar Sesión
                    </span>
                  </Link>
                </center>
				</div>
			</div>
		</div>
		</Router>
	);
}
}
