import React from "react";
import Api from "../servicios/Api";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import {
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
} from "mdbreact";
import {
	DatePickerComponent,
	RenderDayCellEventArgs,
	ChangedEventArgs,
	CalendarComponent,
} from "@syncfusion/ej2-react-calendars";
const cookies = new Cookies();
export default class EditarSaldo extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			saldos: [],
			errores: [],
			fecha: "",
		};
		this.cambioValor = this.cambioValor.bind(this);
	}

	
	cambioValor = (e) => {
		const state = this.state.saldos;
		state[e.target.name] = e.target.value;
		this.setState({ saldos: state, errores: [] });
	};
	enviarDatos = (e) => {
		e.preventDefault();
		// console.log("Formulario enviado.....");
		const {
			id_saldo,
			valor,			
			usuario_modificacion,
			fecha_registro,
		} = this.state.saldos;

		var datosEnviar = {
			ID_SALDO: id_saldo,
			VALOR: valor,
			USUARIO_MODIFICACION: cookies.get("id"),
			FECHA_REGISTRO: fecha_registro,
		};
		
		if(cookies.get("fecha")!=null){
			
			// console.log(datosEnviar);
			datosEnviar.FECHA_REGISTRO=cookies.get("fecha")
		}
		var errores = [];
		if (!valor) errores.push("error_valor");
		this.setState({ errores: errores });
		// console.log(errores);
		if (errores.length > 0) return false;
		// console.log(datosEnviar);
		fetch(Api + "saldoAnterior.php" + "?actualizar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
			
		})
		
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				// console.log(datosRespuesta);
				window.location.href = "/ListarSaldo";
			})
			.catch(console.log);
	};
	componentDidMount() {
		// console.log(this.props.match.params.id);
		fetch(Api + "saldoAnterior.php" + "?consultar=" + this.props.match.params.id)
			.then((respuesta) => respuesta.json())
			.then((datosIngreso) => {
				// console.log(datosIngreso);
				this.setState({
					datosCargados: true,
					saldos: datosIngreso[0],
				});
			})
			.catch(console.log);
	}

	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}
	render() {
	const dateValue: Date = new Date(
			new Date().getFullYear(),
			new Date().getMonth,
			20
		);
		const changefecha = (event: any) => {
			const state = this.state;
			state[fecha] = event.target.value;
			cookies.set("fecha", state[fecha], { path: "/" });
			var d = new Date(state[fecha]);
			const options = {
				day: "numeric",
				month: "numeric",
				year: "numeric",
			};
			// console.log(cookies.get("fecha"));
		};
		const {
			datosCargados,
			saldos,
			fecha,
		} = this.state;
	
		let selectedOptionId = 0;
		return (
			<div className="card text-primary|secondary|success|danger|warning|info|light|dark bg-primary|secondary|success|danger|warning|info|light|dark">
				<div className="card-header">Featured</div>
				<div className="card-body">
					<form onSubmit={this.enviarDatos}>
						<div class="col-md-6">
							<label htmlFor="">Fecha:</label>
							<div className="datepicker-control-section">
								<DatePickerComponent
									onChange={(event) => {
										changefecha(event);
									}}
									dateFormat="YYYY/MM/DD"
									ref={(calendar) =>
										(this.datepickerInstance = calendar)
									}
									value={dateValue}
								></DatePickerComponent>
							</div>
							<br />
						</div>

						<div className="form-group">
							
							
							<label htmlFor="">Valor:</label>
							<input
								pattern="^\d*(\.\d{0,2})?$"
								onChange={this.cambioValor}
								value={saldos.valor}
								type="number"
								name="valor"
								id="valor"
								className={
									(this.verificarError("error_valor")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Valor"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Valor del Registro
							</small>
						</div>
						<div
							className="btn-group"
							role="group"
							aria-label="Basic example"
						>
							<button type="submit" className="btn btn-success">
								Guardar Registro
							</button>
							<Link
								to={"/listarSaldo"}
								className="btn btn-primary"
							>
								Cancelar
							</Link>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
