import React, { Component } from "react";

import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import ListarControlV from "../controlVehicular/ListarControlV";
import Cookies from "universal-cookie";
import { format } from "date-fns";
import { loadCldr } from "@syncfusion/ej2-base";
import {
	DatePickerComponent,
	RenderDayCellEventArgs,
	ChangedEventArgs,
	CalendarComponent,
} from "@syncfusion/ej2-react-calendars";
const cookies = new Cookies();

export default class RegistrarCostos extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			descripcion: "",
			valor: "",
			usuario_modificacion: "",
			errores: [],
			datosCargados: false,
		};
	}

	enviarDatos = (e) => {
		e.preventDefault();
		console.log("Formulario enviado.....");
		const changeLanguage = (event: any) => {
			const state = this.state;
		};
		const { descripcion, valor, id, usuario_modificacion, fecha_registro } =
			this.state;
		var datosEnviar = {
			descripcion: descripcion,
			valor: valor,
			id: cookies.get("id_empresa"),
			usuario_modificacion: cookies.get("id"),
			fecha_registro: cookies.get("fecha"),
		};

		var errores = [];
		if (!valor) errores.push("error_valor");
		this.setState({ errores: errores });
		if (errores.length > 0) return false;
		console.log(datosEnviar);
		fetch(Api + "costosBancarios.php" + "?insertar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				window.location.href = "/ListarCostos";
			})
			.catch(console.log);
	};

	cambioValor = (e) => {
		const state = this.state;
		state[e.target.name] = e.target.value;

		this.setState({ state, errores: [] });
	};
	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}

	render() {
		const dateValue: Date = new Date(
			new Date().getFullYear(),
			new Date().getMonth,
			20
		);
		const changefecha = (event: any) => {
			const state = this.state;
			state[fecha] = event.target.value;
			cookies.set("fecha", state[fecha], { path: "/" });
			var d = new Date(state[fecha]);
			const options = {
				day: "numeric",
				month: "numeric",
				year: "numeric",
			};
		};
		const { descripcion, valor, usuario_modificacion, fecha } = this.state;

		let selectedOptionId = 0;
		return (
			<div className="card text-primary">
				<div className="card-header">
					<h4> Registrar Costos Bancarios</h4>
				</div>
				<div className="card-body">
					<form onSubmit={this.enviarDatos}>
						<div className="form-group">
							<div class="col-md-6">
								<label htmlFor="">Fecha:</label>
								<div className="datepicker-control-section">
									<DatePickerComponent
										onChange={(event) => {
											changefecha(event);
										}}
										dateFormat="YYYY/MM/DD"
										ref={(calendar) =>
											(this.datepickerInstance = calendar)
										}
										value={dateValue}
									></DatePickerComponent>
								</div>
								<br />
							</div>
							<small id="helpNombre" className="invalid-feeback">
								Seleccione Fecha del Registro
							</small>

							<br />
							<label htmlFor="">Descripción:</label>
							<input
								onChange={this.cambioValor}
								value={descripcion}
								type="text"
								name="descripcion"
								id="descripcion"
								maxlength="50"
								className={
									(this.verificarError("error_descripcion")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Descripción"
							/>
							<br />
							<small id="helpNombre" className="invalid-feeback">
								Escribe la descripción del Registro
							</small>
							<br />
							<br />
							<label htmlFor="">Valor:</label>
							<input
								pattern="^\d*(\.\d{0,2})?$"
								onChange={this.cambioValor}
								value={valor}
								type="number"
								name="valor"
								id="valor"
								className={
									(this.verificarError("error_valor")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Valor"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Valor del Registro
							</small>
						</div>
						<div
							className="btn-group"
							role="group"
							aria-label="Basic example"
						>
							<button type="submit" className="btn btn-success">
								Guardar Registro
							</button>
							<Link
								to={"/listarCostos"}
								className="btn btn-primary"
							>
								Cancelar
							</Link>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
