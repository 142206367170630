import React, { Component } from "react";

import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import ListarConvenio from "../convenioPago/ListarConvenio";
import Cookies from "universal-cookie";
import { format } from "date-fns";
import {
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
} from "mdbreact";
import { loadCldr} from '@syncfusion/ej2-base';
import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
const cookies = new Cookies();
export default class RegistrarConvenio extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			descripcion: "",
			fecha_convenio: "",
			valor_total: "",
			id_condomino: "",
			usuario_modificacion: "",
			errores: [],
			condominos: [],
			datosCargados: false,
		};
	}


	cargarDatos() {
		console.log("id" + cookies.get("id"));
		fetch(
			Api + "copropietarios.php" + "?listar=" + cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					condominos: datosRespuesta,
				});
			})
			.catch(console.log);
	}

	componentDidMount() {
		this.cargarDatos();
	}

	enviarDatos = (e) => {
		e.preventDefault();
		console.log("Formulario enviado.....");
		
		const {
			descripcion,
			fecha_convenio,
			valor_total,
			id_condomino,
			usuario_modificacion,
		} = this.state;
		var datosEnviar = {
			descripcion: descripcion,
			fecha_convenio: cookies.get("fecha_convenio"),
			valor_total: valor_total,
			id_condomino: cookies.get("id_condomino"),
			usuario_modificacion: cookies.get("id"),
		};
		
		var errores = [];
		if (!descripcion) errores.push("error_descripcion");
		if (!valor_total) errores.push("error_valor_total");
		this.setState({ errores: errores });
		if (errores.length > 0) return false;
		console.log(datosEnviar);
		fetch(Api + "convenios.php" + "?insertar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				window.location.href = "/ListarConvenio";
			})
			.catch(console.log);
	};


	cambioValor_total = (e) => {
		const state = this.state;
		state[e.target.name] = e.target.value;

		this.setState({ state, errores: [] });
	};
	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}

	render() {
		
		const dateValue :Date = new Date(new Date().getFullYear(), new Date().getMonth,20);
		const {
			descripcion,
			fecha_convenio,
			valor_total,
			id_condomino,
			condominos,
			usuario_modificacion,
		} = this.state;
		const changeLanguage = (event: any) => {
			const state = this.state;
			state[id_condomino] = event.target.value;
			cookies.set("id_condomino", state[id_condomino], { path: "/" });
		};
		const changefecha_convenio = (event: any) => {
			const state = this.state;
			state[fecha_convenio] = event.target.value;
			cookies.set("fecha_convenio", state[fecha_convenio], { path: "/" });
			console.log(cookies.get('fecha_convenio'));
		};
		let selectedOptionId = 0;
		return (
			<div className="card text-primary">
				<div className="card-header">
					<h4> Registrar Alicuota</h4>
				</div>
				<div className="card-body">
					<form onSubmit={this.enviarDatos}>
						<div className="form-group">
							<label htmlFor="">Condomino:</label>
							<br />
							<select defaultValue={selectedOptionId}
								class="custom-select custom-select-lg|custom-select-sm"
								onChange={(event) => {
									changeLanguage(event);
								}}
							>
								<option selected>Seleccionar</option>
								{condominos.map((condomino) => (
									<option value={condomino.ID_CONDOMINO}>
										{condomino.NOMBRE} {condomino.APELLIDO}
									</option>
								))}
							</select>
							<br />
							<small id="helpNombre" className="invalid-feeback">
								Seleccione Condomino
							</small>
							<br />
							<label htmlFor="">Descripción:</label>
							<input
								onChange={this.cambioValor_total}
								value={descripcion}
								type="text"
								name="descripcion"
								id="descripcion"
								className={
									(this.verificarError(
										"error_descripcion"
									)
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Descripción"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Descripción
							</small>
							<br />
							<label htmlFor="">Fecha Convenio:</label>
							<CalendarComponent  onChange={(event) => {
									changefecha_convenio(event);
								}} value={dateValue}  depth="Year" id="calendar" />
							<br />
							
							<small id="helpNombre" className="invalid-feeback">
								Escoja la fecha de convenio del Registro
							</small>
							<br />
							<label htmlFor="">Valor_total:</label>
							<input pattern="^\d*(\.\d{0,2})?$"
								onChange={this.cambioValor_total}
								value={valor_total}
								type="number"
								name="valor_total"
								id="valor_total"
								className={
									(this.verificarError("error_valor_total")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Valor Total"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Valor Total del Registro
							</small>
						</div>
						<div
							className="btn-group"
							role="group"
							aria-label="Basic example"
						>
							<button type="submit" className="btn btn-success">
								Guardar Registro
							</button>
							<Link
								to={"/listarConvenio"}
								className="btn btn-primary"
							>
								Cancelar
							</Link>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
