import React from "react";
import Api from "../servicios/Api";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class EditarProveedor extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			proveedor: [],
			errores: [],
		};
		this.cambioValor=this.cambioValor.bind(this);
	}
	cambioValor = (e) => {
		const state = this.state.proveedor;
		state[e.target.name] = e.target.value;
		this.setState({proveedor:state, errores: [] });		
	};

	enviarDatos = (e) => {
		e.preventDefault();
		console.log("Formulario enviado.....");
		const{ID_PROV,ID,IDENTIFICACION,NOMBRE,CORREO,APELLIDO,DIRECCION,TELEFONO,CELULAR,DESCRIPCION,USUARIO_MODIFICACION}=this.state.proveedor;
		var datosEnviar = {
			ID_PROV:ID_PROV,
			ID:ID,
			IDENTIFICACION: IDENTIFICACION,
			NOMBRE: NOMBRE,
			APELLIDO: APELLIDO,
			CORREO: CORREO,
			DIRECCION: DIRECCION,
			TELEFONO: TELEFONO,
			CELULAR: CELULAR,
			DESCRIPCION: DESCRIPCION,	
			USUARIO_MODIFICACION:cookies.get("id"),		
		};
		var errores = [];
		if (!IDENTIFICACION) errores.push("error_identificacion");
		if (!NOMBRE) errores.push("error_nombre");
		if (!APELLIDO) errores.push("error_apellido");
		if (!CORREO) errores.push("error_correo");
		if (!DESCRIPCION) errores.push("error_descripcion");
		this.setState({ errores: errores });

		if (errores.length > 0) return false;
		fetch(Api + "proveedor.php" + "?actualizar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				window.location.href = "/listarProveedor";
			})
			.catch(console.log);
}
	componentDidMount() {
		console.log(this.props.match.params.id);
		fetch(
			Api +
				"proveedor.php" +
				"?consultar=" +
				this.props.match.params.id
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					proveedor: datosRespuesta[0],
				});
			})
			.catch(console.log);
	}

	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}

	render() {
		const { datosCargados, proveedor } = this.state;
		if (!datosCargados) {
			return <div>Cargando....</div>;
		} else {
		return (
			<div className="card">
				<div className="card-body">
					<form onSubmit={this.enviarDatos}>						
						<div className="form-group ">
						  <label htmlFor="">Id</label>
						  <input onChange={this.cambioValor} readOnly type="text" className="form-control" name="ID_PROV" id="ID_PROV" value={proveedor.ID_PROV}/>
						  <small id="helpNombre" className="invalid-feeback">
								Id
							</small>
						</div>
						<div className="form-group">
						<label htmlFor="">Id Empresa:</label>
							<input readOnly
								onChange={this.cambioValor}
								value={proveedor.EMPRESA}
								type="text"
								name="ID"
								id="ID"
								className={
									(this.verificarError("error_identificacion")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Id Empresa"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Id Empresa
							</small>
							<br />
							<label htmlFor="">Identificación:</label>
							<input
								onChange={this.cambioValor}
								value={proveedor.IDENTIFICACION}
								type="number"
								name="IDENTIFICACION"
								id="IDENTIFICACION"
								className={
									(this.verificarError("error_identificacion")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Identificación"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe la Identificación del Proveedor
							</small>
							<br />
							<label htmlFor="">Nombre:</label>
							<input
								onChange={this.cambioValor}
								value={proveedor.NOMBRE}
								type="text"
								name="NOMBRE"
								id="NOMBRE"
								className={
									(this.verificarError("error_nombre")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Nombre"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el nombre del Proveedor
							</small>
							<br />
							<label htmlFor="">Apellido:</label>
							<input
								onChange={this.cambioValor}
								value={proveedor.APELLIDO}
								type="text"
								name="APELLIDO"
								id="APELLIDO"
								className={
									(this.verificarError("error_apellido")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Apellido"
							/>
							<small
								id="helpApellido"
								className="invalid-feeback"
							>
								Escribe el apellido del Proveedor
							</small>
							<br />
							<label htmlFor="">Correo:</label>
							<input
								onChange={this.cambioValor}
								value={proveedor.CORREO}
								type="text"
								name="CORREO"
								id="CORREO"
								className={
									(this.verificarError("error_correo")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Correo Electrónico"
							/>
							<small id="helpCorreo" className="invalid-feeback">
								Escribe el correo del Proveedor
							</small>
							<br />
							<label htmlFor="">Dirección:</label>
							<input
								onChange={this.cambioValor}
								value={proveedor.DIRECCION}
								type="text"
								name="DIRECCION"
								id="DIRECCION"
								className=" form-control"
								placeholder="Dirección"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe la Dirección del Proveedor
							</small>
							<br />
							<label htmlFor="">Teléfono:</label>
							<input
								onChange={this.cambioValor}
								value={proveedor.TELEFONO}
								type="number"
								name="TELEFONO"
								id="TELEFONO"
								className=" form-control"
								placeholder="Teléfono"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Teléfono del Proveedor
							</small>
							<br />
							<label htmlFor="">Celular:</label>
							<input
								onChange={this.cambioValor}
								value={proveedor.CELULAR}
								type="text"
								name="CELULAR"
								id="CELULAR"
								className=" form-control"
								placeholder="Celular"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Celular del Proveedor
							</small>
							<br />
							<label htmlFor="">Casa-Departamento-Oficina:</label>
							<input
								onChange={this.cambioValor}
								value={proveedor.DESCRIPCION}
								type="text"
								name="DESCRIPCION"
								id="DESCRIPCION"
								className={
									(this.verificarError("error_descripcion")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Casa-Departamento-Oficina"
							/>
							<small id="helpCorreo" className="invalid-feeback">
								Escribe la Descripción del
								Proveedor
							</small>
						</div>
						<div
							className="btn-group"
							role="group"
							aria-label="Basic example"
						>
							<button type="submit" className="btn btn-success">
								Actualizar Proveedor
							</button>
							<Link to={"/listarProveedor"} className="btn btn-primary">
								Cancelar
							</Link>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
}
