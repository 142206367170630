import React , { Fragment } from "react";
import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class ListaComprobante extends React.Component {
	constructor(props) {
		super(props);

		this.state = {

			datosCargados: false,
			comprobante: [],
		};
	}

	cargarDatos() {
		
		fetch(Api+"comprobante.php"+"?listar="+cookies.get("id_empresa"))
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				
				this.setState({
					datosCargados: true,
					comprobante: datosRespuesta,
				});
			})
			.catch(console.log);
	}

	componentDidMount() {
		this.cargarDatos();
	}

borrarRegistro = (ID_C_VEHICULAR) => {
		fetch(Api +"comprobante.php" +"?borrar=" + ID_C_VEHICULAR)
	.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				
				
				this.cargarDatos();
				window.location.href = "/ListaControlV";
			})
			.catch(console.log);
	};
	render() {
		const { datosCargados, comprobante} = this.state;
		return (
			<Fragment>
					<div className="card text-primary">
						
						<div className="card-body">
							<h4>Lista de Comprobante</h4>
							<table className="table table-hover">
								<caption>Lista de Comprobante</caption>
								<thead className="thead-dark|thead-light">
									<tr>
										<th scope="col">Id</th>
										<th scope="col">NUMERO COMPROBANTE</th>
										</tr>
								</thead>
								<tbody>
									{comprobante.map((com) => (
										<tr key={com.ID_COMPROBANTE}>
											<th scope="row">{com.ID_COMPROBANTE}</th>
											<td>{com.NUMERO_COMPROBANTE}</td>
												<td>
												{" "}
												<div
													className={(cookies.get("perfil") == 'DIRECTIVOS' ? "d-none" : "") +" btn-group"}
													role="group"
													aria-label="Basic example"
												>
													<Link
														to={"/editarComprobante/" + com.ID_COMPROBANTE}
														className="btn btn-warning"
													>
														Editar
													</Link>
													
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						<div className="alert alert-danger">
									<strong>
										El número de comprobante corresponde al secuencial, de generación
										de los recibos, la edición se deberá realizar luego de eliminar
										un registro de alicuota en estado pagado. 
										<br/>
										!Bajo su propia responsabilidad!										
									</strong>
								</div>
					</div>
				</Fragment>
		);
	}
}
