import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import { format } from "date-fns";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class ListarServicio extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			solicitud: [],
		};
	}

	cargarDatos() {
		console.log("id" + cookies.get("id"));
		fetch(
			Api +
				"servicio.php" +
				"?listar=" +
				cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					solicitud: datosRespuesta,
				});
			})
			.catch(console.log);
	}

	componentDidMount() {
		this.cargarDatos();
	}

	borrarRegistro = (ID_SOLICITUD) => {
		fetch(Api + "servicio.php" + "?borrar=" + ID_SOLICITUD)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(ID_SOLICITUD);
				console.log(datosRespuesta);
				this.cargarDatos();
				window.location.href = "/ListarServicio";
			})
			.catch(console.log);
	};
	formatear(date){
		const formattedDate =format(date, "MMMM , yyyy");
		console.log(formattedDate);
	}
	render() {
		const date = new Date("2016-01-04 10:34:23");

		const formattedDate = format(date, "MMMM , yyyy");

		console.log(formattedDate);
		const { datosCargados, solicitud } = this.state;
		return (
			<Fragment>
				<div className="card text-primary">
					<div className="card-header">
						<Link
							to={"/registrarServicio"}
							className="btn btn-success"
						>
							Agregar Nuevo Registro
						</Link>
					</div>
					<div className="card-body">
						<h4>Lista de Solicitudes</h4>
						<table className="table table-hover">
							<caption>Lista de Solicitudes</caption>
							<thead className="thead-dark|thead-light">
								<tr>
									<th scope="col">Id</th>
									<th scope="col" colSpan="2">
										Condomino
									</th>
									<th scope="col">Descripción</th>
									<th scope="col">Días</th>
									<th scope="col">Bien</th>		
									<th scope="col">Acciones</th>										
								</tr>
							</thead>
							<tbody>
								{solicitud.map((solicituds) => (
									<tr key={solicituds.ID_SOLICITUD}>
										<th scope="row">
											{solicituds.ID_SOLICITUD}
										</th>
										<td>{solicituds.NOMBRE} </td>
										<td>{solicituds.APELLIDO}</td>
										<td>{solicituds.DESCRIPCION}</td>
										<td>{solicituds.DIAS}</td>
										<td>{solicituds.BIEN}</td>
										
										<td>
											{" "}
											<div
												className="btn-group"
												role="group"
												aria-label="Basic example"
											>
												<Link
													to={
														"/editarServicio/" +
														solicituds.ID_SOLICITUD
													}
													className="btn btn-warning"
												>
													Editar
												</Link>

												<Link
													to={"/"}
													className="btn btn-danger"
													onClick={() =>
														this.borrarRegistro(
															solicituds.ID_SOLICITUD
														)
													}
												>
													Borrar
												</Link>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</Fragment>
		);
	}
}
