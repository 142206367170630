import React, { Component } from "react";

import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import ListarControlV from "../controlVehicular/ListarControlV";
import Cookies from "universal-cookie";
import { format } from "date-fns";
import {
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
} from "mdbreact";
import { loadCldr } from "@syncfusion/ej2-base";
import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import PagoConsolidado from "./PagoConsolidado";
const cookies = new Cookies();
export default class RegistrarAlicuotaR extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			numero_comprobante: "",
			mes: "",
			valor: "",
			id_condomino: "",
			usuario_modificacion: "",
			errores: [],
			condominos: [],
			datosCargados: false,
			enero: false,
			febrero: false,
			marzo: false,
			abril: false,
			mayo: false,
			junio: false,
			julio: false,
			agosto: false,
			septiembre: false,
			octubre: false,
			noviembre: false,
			diciembre: false,
			anios: [],
			id_anio: "",
			numero_comprobantes: [],
			formaPagos: [],
			id_tipo_pago: "",
			numero_cheq_dep: "",
			conceptos: [],
			id_concepto: "",
			id_copArr: "",
			descripcion: "",
			contador: 1,
		};
	}

	handleInputChange(event) {
		const target = event.target;
		const value =
			target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		console.log(value);
		this.setState({
			[name]: value,
		});
	}

	cargarDatos() {
		console.log("id" + cookies.get("id"));
		fetch(
			Api +
				"copropietarios.php" +
				"?listarPropietarios=" +
				cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					condominos: datosRespuesta,
					anios: datosRespuesta,
				});
			})
			.catch(console.log);
		this.cargarAnios();
		this.cargarFormasPago();
		this.cargarConcepto();
	}
	pagoConsolidado = (e) => {
		e.preventDefault();
		console.log("Formulario enviado.....");
		PagoConsolidado.enviarDatos(e);
	};

	cargarAnios() {
		fetch(Api + "copropietarios.php" + "?listarAnios")
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					anios: datosRespuesta,
				});
			})
			.catch(console.log);
	}
	cargarFormasPago() {
		fetch(Api + "registroAlicuotas.php" + "?listarFormaPago")
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					formaPagos: datosRespuesta,
				});
			})
			.catch(console.log);
	}
	cargarConcepto() {
		fetch(Api + "registroAlicuotas.php" + "?listarConcepto")
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					conceptos: datosRespuesta,
				});
			})
			.catch(console.log);
	}
	componentDidMount() {
		this.cargarDatos();
		this.cargarComprobante();
	}

	cargarPagoConsolidado() {
		console.log(Api + "pagoConsolidado.php" + "?consultarUltimo");
		fetch(Api + "pagoConsolidado.php" + "?consultarUltimo")
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				cookies.set(
					"id_consolidado",
					parseInt(datosRespuesta[0].ID_PAGO_CONSOLIDADO) + 1
				);
				console.log(cookies.get("id_consolidado"));
				//
			})
			.catch(console.log);
	}

	cargarComprobante() {
		fetch(
			Api +
				"numeroComprobante.php" +
				"?consultar=" +
				cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					numero_comprobantes: datosRespuesta,
				});
			})
			.catch(console.log);
	}

	enviarDatos = (e) => {
		e.preventDefault();
		console.log("Formulario enviado.....");

		const changeLanguage = (event: any) => {
			const state = this.state;
			state[id_condomino] = event.target.selectedIndex;
		};

		const {
			numero_comprobante,
			mes,
			valor,
			id_condomino,
			usuario_modificacion,
			estado_pago,
			enero,
			febrero,
			marzo,
			abril,
			mayo,
			junio,
			julio,
			agosto,
			septiembre,
			octubre,
			noviembre,
			diciembre,
			id_tipo_pago,
			numero_cheq_dep,
			id_concepto,
			id_copArr,
			descripcion,
		} = this.state;
		console.log(this.state);
		if (cookies.get("estado_pago") == null) {
			estado_pago = cookies.get("estado_pago");
		}
		if (cookies.get("id_tipo_pago") == null) {
			cookies.set("id_tipo_pago", 0);
		}
		if (cookies.get("id_concepto") == null) {
			cookies.set("id_concepto", 0);
		}
		if (cookies.get("id_copArr") == null) {
			cookies.set("id_copArr", 0);
		}

		var datosConsolidado = {
			numero_comprobante: numero_comprobante,
			valor: valor,
			id_condomino: cookies.get("id_condomino"),
			descripcion: descripcion,
			estado_pago: cookies.get("estado_pago"),
		};
		if (
			datosConsolidado.estado_pago == "PAGADO" &&
			this.state.contador > 1
		) {
			this.cargarPagoConsolidado();
			let aux1 = parseInt(cookies.get("numero_comprobante"));
			datosConsolidado.numero_comprobante = aux1;
			datosConsolidado.valor = valor * (this.state.contador - 1);
			var errores = [];
			if (!valor) errores.push("error_valor");
			this.setState({ errores: errores });
			console.log(errores.length);
			if (errores.length > 0) return false;
			console.log(datosConsolidado);
			fetch(Api + "pagoConsolidado.php" + "?insertar=1", {
				method: "POST",
				body: JSON.stringify(datosConsolidado),
			});
		}

		if (enero) {
			const fecha = "Enero" + "/" + cookies.get("id_anio");

			let aux1 = parseInt(cookies.get("numero_comprobante"));

			var datosEnviar = {
				numero_comprobante: aux1,
				mes: fecha,
				valor: valor,
				id_condomino: cookies.get("id_condomino"),
				usuario_modificacion: cookies.get("id"),
				estado_pago: cookies.get("estado_pago"),
				id_tipo_pago: cookies.get("id_tipo_pago"),
				numero_cheq_dep: numero_cheq_dep,
				id_concepto: cookies.get("id_concepto"),
				id_copArr: cookies.get("id_copArr"),
				descripcion: descripcion,
				id_pago_consolidado: cookies.get("id_consolidado"),
			};

			var errores = [];
			if (!valor) errores.push("error_valor");
			this.setState({ errores: errores });
			console.log(errores.length);
			if (errores.length > 0) return false;
			console.log(datosEnviar);
			if (
				datosEnviar.estado_pago == "PENDIENTE" ||
				datosEnviar.id_condomino == 402 ||
				datosEnviar.id_condomino == 403
			) {
				datosEnviar.numero_comprobante = "00000";
			}
			fetch(Api + "registroAlicuotas.php" + "?insertar=1", {
				method: "POST",
				body: JSON.stringify(datosEnviar),
			})
				.then((respuesta) => respuesta.json())
				.then((datosRespuesta) => {
					console.log(datosRespuesta);
					window.location.href = "/listarAlicuota";
				})
				.catch(console.log);

			console.log(cookies.get("numero_comprobante"));
		}
		if (febrero) {
			const fecha = "Febrero" + "/" + cookies.get("id_anio");
			let aux1 = parseInt(cookies.get("numero_comprobante"));
			var datosEnviar = {
				numero_comprobante: aux1,
				mes: fecha,
				valor: valor,
				id_condomino: cookies.get("id_condomino"),
				usuario_modificacion: cookies.get("id"),
				estado_pago: cookies.get("estado_pago"),
				id_tipo_pago: cookies.get("id_tipo_pago"),
				numero_cheq_dep: numero_cheq_dep,
				id_concepto: cookies.get("id_concepto"),
				id_copArr: cookies.get("id_copArr"),
				descripcion: descripcion,
				id_pago_consolidado: cookies.get("id_consolidado"),
			};

			var errores = [];
			if (!valor) errores.push("error_valor");
			this.setState({ errores: errores });
			if (errores.length > 0) return false;
			if (
				datosEnviar.estado_pago == "PENDIENTE" ||
				datosEnviar.id_condomino == 402 ||
				datosEnviar.id_condomino == 403
			) {
				datosEnviar.numero_comprobante = "00000";
			}
			fetch(Api + "registroAlicuotas.php" + "?insertar=1", {
				method: "POST",
				body: JSON.stringify(datosEnviar),
			})
				.then((respuesta) => respuesta.json())
				.then((datosRespuesta) => {
					console.log(datosRespuesta);
					window.location.href = "/ListarAlicuota";
				})
				.catch(console.log);

			console.log(cookies.get("numero_comprobante"));
		}
		if (marzo) {
			const fecha = "Marzo" + "/" + cookies.get("id_anio");
			let aux1 = parseInt(cookies.get("numero_comprobante"));
			var datosEnviar = {
				numero_comprobante: aux1,
				mes: fecha,
				valor: valor,
				id_condomino: cookies.get("id_condomino"),
				usuario_modificacion: cookies.get("id"),
				estado_pago: cookies.get("estado_pago"),
				id_tipo_pago: cookies.get("id_tipo_pago"),
				numero_cheq_dep: numero_cheq_dep,
				id_concepto: cookies.get("id_concepto"),
				id_copArr: cookies.get("id_copArr"),
				descripcion: descripcion,
				id_pago_consolidado: cookies.get("id_consolidado"),
			};

			var errores = [];
			if (!valor) errores.push("error_valor");
			this.setState({ errores: errores });
			if (errores.length > 0) return false;
			if (
				datosEnviar.estado_pago == "PENDIENTE" ||
				datosEnviar.id_condomino == 402 ||
				datosEnviar.id_condomino == 403
			) {
				datosEnviar.numero_comprobante = "00000";
			}
			fetch(Api + "registroAlicuotas.php" + "?insertar=1", {
				method: "POST",
				body: JSON.stringify(datosEnviar),
			})
				.then((respuesta) => respuesta.json())
				.then((datosRespuesta) => {
					console.log(datosRespuesta);
					window.location.href = "/ListarAlicuota";
				})
				.catch(console.log);
			console.log(cookies.get("numero_comprobante"));
		}
		if (abril) {
			const fecha = "Abril" + "/" + cookies.get("id_anio");
			let aux1 = parseInt(cookies.get("numero_comprobante"));
			var datosEnviar = {
				numero_comprobante: aux1,
				mes: fecha,
				valor: valor,
				id_condomino: cookies.get("id_condomino"),
				usuario_modificacion: cookies.get("id"),
				estado_pago: cookies.get("estado_pago"),
				id_tipo_pago: cookies.get("id_tipo_pago"),
				numero_cheq_dep: numero_cheq_dep,
				id_concepto: cookies.get("id_concepto"),
				id_copArr: cookies.get("id_copArr"),
				descripcion: descripcion,
				id_pago_consolidado: cookies.get("id_consolidado"),
			};

			var errores = [];
			if (!valor) errores.push("error_valor");
			this.setState({ errores: errores });
			if (errores.length > 0) return false;
			if (
				datosEnviar.estado_pago == "PENDIENTE" ||
				datosEnviar.id_condomino == 402 ||
				datosEnviar.id_condomino == 403
			) {
				datosEnviar.numero_comprobante = "00000";
			}
			fetch(Api + "registroAlicuotas.php" + "?insertar=1", {
				method: "POST",
				body: JSON.stringify(datosEnviar),
			})
				.then((respuesta) => respuesta.json())
				.then((datosRespuesta) => {
					console.log(datosRespuesta);
					window.location.href = "/ListarAlicuota";
				})
				.catch(console.log);

			console.log(cookies.get("numero_comprobante"));
		}
		if (mayo) {
			const fecha = "Mayo" + "/" + cookies.get("id_anio");
			let aux1 = parseInt(cookies.get("numero_comprobante"));
			var datosEnviar = {
				numero_comprobante: aux1,
				mes: fecha,
				valor: valor,
				id_condomino: cookies.get("id_condomino"),
				usuario_modificacion: cookies.get("id"),
				estado_pago: cookies.get("estado_pago"),
				id_tipo_pago: cookies.get("id_tipo_pago"),
				numero_cheq_dep: numero_cheq_dep,
				id_concepto: cookies.get("id_concepto"),
				id_copArr: cookies.get("id_copArr"),
				descripcion: descripcion,
				id_pago_consolidado: cookies.get("id_consolidado"),
			};

			var errores = [];
			if (!valor) errores.push("error_valor");
			this.setState({ errores: errores });
			if (errores.length > 0) return false;
			if (
				datosEnviar.estado_pago == "PENDIENTE" ||
				datosEnviar.id_condomino == 402 ||
				datosEnviar.id_condomino == 403
			) {
				datosEnviar.numero_comprobante = "00000";
			}
			fetch(Api + "registroAlicuotas.php" + "?insertar=1", {
				method: "POST",
				body: JSON.stringify(datosEnviar),
			})
				.then((respuesta) => respuesta.json())
				.then((datosRespuesta) => {
					console.log(datosRespuesta);
					window.location.href = "/ListarAlicuota";
				})
				.catch(console.log);

			console.log(cookies.get("numero_comprobante"));
		}
		if (junio) {
			const fecha = "Junio" + "/" + cookies.get("id_anio");
			let aux1 = parseInt(cookies.get("numero_comprobante"));
			var datosEnviar = {
				numero_comprobante: aux1,
				mes: fecha,
				valor: valor,
				id_condomino: cookies.get("id_condomino"),
				usuario_modificacion: cookies.get("id"),
				estado_pago: cookies.get("estado_pago"),
				id_tipo_pago: cookies.get("id_tipo_pago"),
				numero_cheq_dep: numero_cheq_dep,
				id_concepto: cookies.get("id_concepto"),
				id_copArr: cookies.get("id_copArr"),
				descripcion: descripcion,
				id_pago_consolidado: cookies.get("id_consolidado"),
			};

			var errores = [];
			if (!valor) errores.push("error_valor");
			this.setState({ errores: errores });
			if (errores.length > 0) return false;
			if (
				datosEnviar.estado_pago == "PENDIENTE" ||
				datosEnviar.id_condomino == 402 ||
				datosEnviar.id_condomino == 403
			) {
				datosEnviar.numero_comprobante = "00000";
			}
			fetch(Api + "registroAlicuotas.php" + "?insertar=1", {
				method: "POST",
				body: JSON.stringify(datosEnviar),
			})
				.then((respuesta) => respuesta.json())
				.then((datosRespuesta) => {
					console.log(datosRespuesta);
					window.location.href = "/ListarAlicuota";
				})
				.catch(console.log);

			console.log(cookies.get("numero_comprobante"));
		}
		if (julio) {
			const fecha = "Julio" + "/" + cookies.get("id_anio");
			let aux1 = parseInt(cookies.get("numero_comprobante"));
			var datosEnviar = {
				numero_comprobante: aux1,
				mes: fecha,
				valor: valor,
				id_condomino: cookies.get("id_condomino"),
				usuario_modificacion: cookies.get("id"),
				estado_pago: cookies.get("estado_pago"),
				id_tipo_pago: cookies.get("id_tipo_pago"),
				numero_cheq_dep: numero_cheq_dep,
				id_concepto: cookies.get("id_concepto"),
				id_copArr: cookies.get("id_copArr"),
				descripcion: descripcion,
				id_pago_consolidado: cookies.get("id_consolidado"),
			};

			var errores = [];
			if (!valor) errores.push("error_valor");
			this.setState({ errores: errores });
			if (errores.length > 0) return false;
			if (
				datosEnviar.estado_pago == "PENDIENTE" ||
				datosEnviar.id_condomino == 402 ||
				datosEnviar.id_condomino == 403
			) {
				datosEnviar.numero_comprobante = "00000";
			}
			fetch(Api + "registroAlicuotas.php" + "?insertar=1", {
				method: "POST",
				body: JSON.stringify(datosEnviar),
			})
				.then((respuesta) => respuesta.json())
				.then((datosRespuesta) => {
					console.log(datosRespuesta);
					window.location.href = "/ListarAlicuota";
				})
				.catch(console.log);

			console.log(cookies.get("numero_comprobante"));
		}
		if (agosto) {
			const fecha = "Agosto" + "/" + cookies.get("id_anio");
			let aux1 = parseInt(cookies.get("numero_comprobante"));
			var datosEnviar = {
				numero_comprobante: aux1,
				mes: fecha,
				valor: valor,
				id_condomino: cookies.get("id_condomino"),
				usuario_modificacion: cookies.get("id"),
				estado_pago: cookies.get("estado_pago"),
				id_tipo_pago: cookies.get("id_tipo_pago"),
				numero_cheq_dep: numero_cheq_dep,
				id_concepto: cookies.get("id_concepto"),
				id_copArr: cookies.get("id_copArr"),
				descripcion: descripcion,
				id_pago_consolidado: cookies.get("id_consolidado"),
			};

			var errores = [];
			if (!valor) errores.push("error_valor");
			this.setState({ errores: errores });
			if (errores.length > 0) return false;
			if (
				datosEnviar.estado_pago == "PENDIENTE" ||
				datosEnviar.id_condomino == 402 ||
				datosEnviar.id_condomino == 403
			) {
				datosEnviar.numero_comprobante = "00000";
			}
			fetch(Api + "registroAlicuotas.php" + "?insertar=1", {
				method: "POST",
				body: JSON.stringify(datosEnviar),
			})
				.then((respuesta) => respuesta.json())
				.then((datosRespuesta) => {
					console.log(datosRespuesta);
					window.location.href = "/ListarAlicuota";
				})
				.catch(console.log);
			console.log(datosEnviar);

			console.log(cookies.get("numero_comprobante"));
		}
		if (septiembre) {
			const fecha = "Septiembre" + "/" + cookies.get("id_anio");
			let aux1 = parseInt(cookies.get("numero_comprobante"));
			var datosEnviar = {
				numero_comprobante: aux1,
				mes: fecha,
				valor: valor,
				id_condomino: cookies.get("id_condomino"),
				usuario_modificacion: cookies.get("id"),
				estado_pago: cookies.get("estado_pago"),
				id_tipo_pago: cookies.get("id_tipo_pago"),
				numero_cheq_dep: numero_cheq_dep,
				id_concepto: cookies.get("id_concepto"),
				id_copArr: cookies.get("id_copArr"),
				descripcion: descripcion,
				id_pago_consolidado: cookies.get("id_consolidado"),
			};

			var errores = [];
			if (!valor) errores.push("error_valor");
			this.setState({ errores: errores });
			if (errores.length > 0) return false;
			if (
				datosEnviar.estado_pago == "PENDIENTE" ||
				datosEnviar.id_condomino == 402 ||
				datosEnviar.id_condomino == 403
			) {
				datosEnviar.numero_comprobante = "00000";
			}
			fetch(Api + "registroAlicuotas.php" + "?insertar=1", {
				method: "POST",
				body: JSON.stringify(datosEnviar),
			})
				.then((respuesta) => respuesta.json())
				.then((datosRespuesta) => {
					console.log(datosRespuesta);
					window.location.href = "/ListarAlicuota";
				})
				.catch(console.log);

			console.log(datosEnviar);
		}
		if (octubre) {
			const fecha = "Octubre" + "/" + cookies.get("id_anio");
			let aux1 = parseInt(cookies.get("numero_comprobante"));
			var datosEnviar = {
				numero_comprobante: aux1,
				mes: fecha,
				valor: valor,
				id_condomino: cookies.get("id_condomino"),
				usuario_modificacion: cookies.get("id"),
				estado_pago: cookies.get("estado_pago"),
				id_tipo_pago: cookies.get("id_tipo_pago"),
				numero_cheq_dep: numero_cheq_dep,
				id_concepto: cookies.get("id_concepto"),
				id_copArr: cookies.get("id_copArr"),
				descripcion: descripcion,
				id_pago_consolidado: cookies.get("id_consolidado"),
			};

			var errores = [];
			if (!valor) errores.push("error_valor");
			this.setState({ errores: errores });
			if (errores.length > 0) return false;
			if (
				datosEnviar.estado_pago == "PENDIENTE" ||
				datosEnviar.id_condomino == 402 ||
				datosEnviar.id_condomino == 403
			) {
				datosEnviar.numero_comprobante = "00000";
			}
			fetch(Api + "registroAlicuotas.php" + "?insertar=1", {
				method: "POST",
				body: JSON.stringify(datosEnviar),
			})
				.then((respuesta) => respuesta.json())
				.then((datosRespuesta) => {
					console.log(datosRespuesta);
					window.location.href = "/ListarAlicuota";
				})
				.catch(console.log);

			console.log(cookies.get("numero_comprobante"));
		}
		if (noviembre) {
			const fecha = "Noviembre" + "/" + cookies.get("id_anio");
			let aux1 = parseInt(cookies.get("numero_comprobante"));
			var datosEnviar = {
				numero_comprobante: aux1,
				mes: fecha,
				valor: valor,
				id_condomino: cookies.get("id_condomino"),
				usuario_modificacion: cookies.get("id"),
				estado_pago: cookies.get("estado_pago"),
				id_tipo_pago: cookies.get("id_tipo_pago"),
				numero_cheq_dep: numero_cheq_dep,
				id_concepto: cookies.get("id_concepto"),
				id_copArr: cookies.get("id_copArr"),
				descripcion: descripcion,
				id_pago_consolidado: cookies.get("id_consolidado"),
			};

			var errores = [];
			if (!valor) errores.push("error_valor");
			this.setState({ errores: errores });
			if (errores.length > 0) return false;
			if (
				datosEnviar.estado_pago == "PENDIENTE" ||
				datosEnviar.id_condomino == 402 ||
				datosEnviar.id_condomino == 403
			) {
				datosEnviar.numero_comprobante = "00000";
			}
			fetch(Api + "registroAlicuotas.php" + "?insertar=1", {
				method: "POST",
				body: JSON.stringify(datosEnviar),
			})
				.then((respuesta) => respuesta.json())
				.then((datosRespuesta) => {
					console.log(datosRespuesta);
					window.location.href = "/ListarAlicuota";
				})
				.catch(console.log);

			console.log(cookies.get("numero_comprobante"));
		}
		if (diciembre) {
			const fecha = "Diciembre" + "/" + cookies.get("id_anio");
			let aux1 = parseInt(cookies.get("numero_comprobante"));
			var datosEnviar = {
				numero_comprobante: aux1,
				mes: fecha,
				valor: valor,
				id_condomino: cookies.get("id_condomino"),
				usuario_modificacion: cookies.get("id"),
				estado_pago: cookies.get("estado_pago"),
				id_tipo_pago: cookies.get("id_tipo_pago"),
				numero_cheq_dep: numero_cheq_dep,
				id_concepto: cookies.get("id_concepto"),
				id_copArr: cookies.get("id_copArr"),
				descripcion: descripcion,
				id_pago_consolidado: cookies.get("id_consolidado"),
			};

			var errores = [];
			if (!valor) errores.push("error_valor");
			this.setState({ errores: errores });
			if (errores.length > 0) return false;
			if (
				datosEnviar.estado_pago == "PENDIENTE" ||
				datosEnviar.id_condomino == 402 ||
				datosEnviar.id_condomino == 403
			) {
				datosEnviar.numero_comprobante = "00000";
			}
			fetch(Api + "registroAlicuotas.php" + "?insertar=1", {
				method: "POST",
				body: JSON.stringify(datosEnviar),
			})
				.then((respuesta) => respuesta.json())
				.then((datosRespuesta) => {
					console.log(datosRespuesta);
					window.location.href = "/ListarAlicuota";
				})
				.catch(console.log);

			console.log(cookies.get("numero_comprobante"));
		}
		if (datosEnviar.estado_pago == "PAGADO") {
			this.actualizarComprobante();
		}
	};

	actualizarComprobante() {
		var datosEnviar = {
			id_comprobante: cookies.get("id_comprobante"),
			numero_comprobante: parseInt(cookies.get("numero_comprobante")) + 1,
		};
		console.log(datosEnviar);
		fetch(Api + "numeroComprobante.php" + "?actualizar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		}).catch(console.log);
	}

	cambioValor = (e) => {
		const state = this.state;
		state[e.target.name] = e.target.value;

		this.setState({ state, errores: [] });
	};
	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}

	render() {
		const fields: object = { text: "APELLIDO", value: "ID_CONDOMINO" };
		const dateValue: Date = new Date(
			new Date().getFullYear(),
			new Date().getMonth,
			20
		);
		const {
			numero_comprobante,
			mes,
			valor,
			id_condomino,
			condominos,
			usuario_modificacion,
			estado_pago,
			anios,
			id_anio,
			numero_comprobantes,
			enero,
			febrero,
			marzo,
			abril,
			mayo,
			junio,
			julio,
			agosto,
			septiembre,
			octubre,
			noviembre,
			diciembre,
			formaPagos,
			id_tipo_pago,
			numero_cheq_dep,
			conceptos,
			id_concepto,
			id_copArr,
			descripcion,
			contador,
		} = this.state;

		const changeLanguage = (event: any) => {
			const state = this.state;
			state[id_condomino] = event.target.value;
			cookies.set("id_condomino", state[id_condomino], { path: "/" });
		};
		const changeMes = (event: any) => {
			const state = this.state;
			state[mes] = event.target.value;
			cookies.set("mes", state[mes], { path: "/" });
			console.log(cookies.get("mes"));
		};
		const changePago = (event: any) => {
			const state = this.state;
			state[estado_pago] = event.target.value;
			cookies.set("estado_pago", state[estado_pago], { path: "/" });
		};
		const changeAnio = (event: any) => {
			const state = this.state;
			state[id_anio] = event.target.value;
			cookies.set("id_anio", state[id_anio], { path: "/" });
		};
		let selectedOptionId = 0;
		const cambiaMes = (event: any) => {
			const target = event.target;
			const value =
				target.type === "checkbox" ? target.checked : target.value;
			const name = target.name;
			console.log(name, " ", value);

			this.setState({ contador: this.state.contador + 1 });

			console.log(this.state.contador);
			this.setState({
				[name]: value,
			});
		};
		const changeFormaPago = (event: any) => {
			const state = this.state;
			state[id_tipo_pago] = event.target.value;
			cookies.set("id_tipo_pago", state[id_tipo_pago], { path: "/" });
			console.log(cookies.get("id_tipo_pago"));
		};
		const changeConcepto = (event: any) => {
			const state = this.state;
			state[id_concepto] = event.target.value;
			cookies.set("id_concepto", state[id_concepto], { path: "/" });
			console.log(cookies.get("id_concepto"));
		};
		const changeCopArr = (event: any) => {
			const state = this.state;
			state[id_copArr] = event.target.value;
			cookies.set("id_copArr", state[id_copArr], { path: "/" });
			console.log(cookies.get("id_copArr"));
		};
		var num = numero_comprobantes.map((n) => n.numero_comprobante);

		cookies.set("numero_comprobante", num[0]);
		var id_num = numero_comprobantes.map((n) => n.id_comprobante);
		cookies.set("id_comprobante", id_num[0]);
		console.log(cookies.get("id_comprobante"));
		return (
			<div className="card text-primary">
				<div className="card-header">
					<h4> Registrar Alicuota</h4>
				</div>
				<div className="card-body">
					<form onSubmit={this.enviarDatos}>
						<div className="form-group">
							<label htmlFor="">Condomino:</label>
							<br />
							<select
								defaultValue={selectedOptionId}
								class="custom-select custom-select-lg|custom-select-sm"
								onChange={(event) => {
									changeLanguage(event);
								}}
							>
								<option selected>Seleccionar</option>
								{condominos.map((condomino) => (
									<option value={condomino.ID_CONDOMINO}>
										{condomino.CASA_OFI_DEP}
									</option>
								))}
							</select>
							<br />
							<small id="helpNombre" className="invalid-feeback">
								Seleccione Condomino
							</small>
							<br />
							<label htmlFor="">Año:</label>
							<select
								defaultValue={selectedOptionId}
								class="custom-select custom-select-lg|custom-select-sm"
								onChange={(event) => {
									changeAnio(event);
								}}
							>
								<option selected>Seleccionar</option>
								{anios.map((anio) => (
									<option value={anio.ANIO}>
										{anio.ANIO}
									</option>
								))}
							</select>
							<br />

							<br />
							<label htmlFor="">Mes:</label>
							<div>
								<label>
									Enero:
									<input
										name="enero"
										type="checkbox"
										checked={this.state.enero}
										onChange={(event) => {
											cambiaMes(event);
										}}
									/>
								</label>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<label>
									Febrero:
									<input
										name="febrero"
										type="checkbox"
										value={this.state.frebrero}
										onChange={(event) => {
											cambiaMes(event);
										}}
									/>
								</label>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<label>
									Marzo:
									<input
										name="marzo"
										type="checkbox"
										value={this.state.marzo}
										onChange={(event) => {
											cambiaMes(event);
										}}
									/>
								</label>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<label>
									Abril:
									<input
										name="abril"
										type="checkbox"
										value={this.state.abril}
										onChange={(event) => {
											cambiaMes(event);
										}}
									/>
								</label>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<label>
									Mayo:
									<input
										name="mayo"
										type="checkbox"
										value={this.state.mayo}
										onChange={(event) => {
											cambiaMes(event);
										}}
									/>
								</label>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<label>
									Junio:
									<input
										name="junio"
										type="checkbox"
										value={this.state.junio}
										onChange={(event) => {
											cambiaMes(event);
										}}
									/>
								</label>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<label>
									Julio:
									<input
										name="julio"
										type="checkbox"
										value={this.state.julio}
										onChange={(event) => {
											cambiaMes(event);
										}}
									/>
								</label>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<label>
									Agosto:
									<input
										name="agosto"
										type="checkbox"
										value={this.state.agosto}
										onChange={(event) => {
											cambiaMes(event);
										}}
									/>
								</label>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<label>
									Septiembre:
									<input
										name="septiembre"
										type="checkbox"
										value={this.state.septiembre}
										onChange={(event) => {
											cambiaMes(event);
										}}
									/>
								</label>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<label>
									Octubre:
									<input
										name="octubre"
										type="checkbox"
										value={this.state.octubre}
										onChange={(event) => {
											cambiaMes(event);
										}}
									/>
								</label>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<label>
									Noviembre:
									<input
										name="noviembre"
										type="checkbox"
										value={this.state.noviembre}
										onChange={(event) => {
											cambiaMes(event);
										}}
									/>
								</label>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<label>
									Diciembre:
									<input
										name="diciembre"
										type="checkbox"
										value={this.state.diciembre}
										onChange={(event) => {
											cambiaMes(event);
										}}
									/>
								</label>
								&nbsp;&nbsp;&nbsp;&nbsp;
							</div>

							<small id="helpNombre" className="invalid-feeback">
								Escoja el Año y Mes del Registro
							</small>
							<br />
							<br />
							<label htmlFor="">Tipo Forma Pago:</label>
							<select
								defaultValue={selectedOptionId}
								class="custom-select custom-select-lg|custom-select-sm"
								onChange={(event) => {
									changeFormaPago(event);
								}}
							>
								<option selected>Seleccionar</option>
								{formaPagos.map((forma) => (
									<option value={forma.ID_TIPO_PAGO}>
										{forma.DESCRIPCION}
									</option>
								))}
							</select>
							<br />
							<br />
							<label htmlFor="">N° Cheque/Deposito:</label>
							<input
								onChange={this.cambioValor}
								value={numero_cheq_dep}
								type="text"
								name="numero_cheq_dep"
								id="numero_cheq_dep"
								placeholder="N° Cheque/Deposito"
							/>
							<br />
							<br />
							<label htmlFor="">Concepto:</label>
							<select
								defaultValue={selectedOptionId}
								class="custom-select custom-select-lg|custom-select-sm"
								onChange={(event) => {
									changeConcepto(event);
								}}
							>
								<option selected>Seleccionar</option>
								{conceptos.map((con) => (
									<option value={con.ID_TIPO_PAGO}>
										{con.DESCRIPCION}
									</option>
								))}
							</select>
							<br />
							<br />
							<label htmlFor="">Valor:</label>
							<input
								pattern="^\d*(\.\d{0,2})?$"
								onChange={this.cambioValor}
								value={valor}
								type="number"
								name="valor"
								id="valor"
								className={
									(this.verificarError("error_valor")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Valor"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Valor del Registro
							</small>
							<br />
							<label htmlFor="">Estado Pago:</label>
							<br />
							<select
								defaultValue={selectedOptionId}
								class="custom-select custom-select-lg|custom-select-sm"
								onChange={(event) => {
									changePago(event);
								}}
							>
								<option selected>Seleccionar</option>
								<option value="PENDIENTE">PENDIENTE</option>
								<option value="PAGADO">PAGADO</option>
							</select>
							<br />
							<br />
							<small id="helpNombre" className="invalid-feeback">
								Escoja el Estado de Pago del Registro
							</small>
							<br />
							<label htmlFor="">
								Copropietario / Arrendatario:
							</label>
							<br />
							<select
								defaultValue={selectedOptionId}
								class="custom-select custom-select-lg|custom-select-sm"
								onChange={(event) => {
									changeCopArr(event);
								}}
							>
								<option selected>Seleccionar</option>
								<option value="C">COPROPIETARIO</option>
								<option value="A">ARRENDATARIO</option>
							</select>
							<br />
							<small id="helpNombre" className="invalid-feeback">
								Escoja Copropietario o Arrendatario
							</small>
							<br />
							<label htmlFor="">Descripción:</label>
							<br />
							<textarea
								className="md-textarea form-control"
								maxlength="60"
								cols="60"
								rows="2"
								onChange={this.cambioValor}
								value={descripcion}
								type="text"
								name="descripcion"
								id="descripcion"
								placeholder="Descripción"
							/>
							<br />
							<small id="helpNombre" className="invalid-feeback">
								Escribe una Descripción (opcional)
							</small>
							<br />
						</div>
						<div
							className="btn-group"
							role="group"
							aria-label="Basic example"
						>
							<button type="submit" className="btn btn-success">
								Guardar Registro
							</button>
							<Link
								to={"/listarAlicuota"}
								className="btn btn-primary"
							>
								Cancelar
							</Link>
							
						</div>
					</form>
				</div>
			</div>
		);
	}
}
