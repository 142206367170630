import React from "react";
import Api from "../servicios/Api";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import logoSol2 from "./logoSoL2.png";
import LibretinSol from "./Libretin-Sol-2.png";
import {
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
} from "mdbreact";
import {
	DatePickerComponent,
	RenderDayCellEventArgs,
	ChangedEventArgs,
	CalendarComponent,
} from "@syncfusion/ej2-react-calendars";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	PDFViewer,
	PDFDownloadLink,
	Image,
	ReactPDF,
} from "@react-pdf/renderer";
const cookies = new Cookies();
export default class EditarAlicuota extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			alicuota: [],
			errores: [],
			condominos: [],
			id_condomino: "",
			mes: "",
			formaPagos: [],
			id_tipo_pago: "",
			numero_cheq_dep: "",
			conceptos: [],
			id_concepto: "",
			id_copArr: "",
			descripcion: "",
			respuestaAlicuotas: [],
			alicuotass: [],
			otros: [],
			respuestaOtros: [],
			convenios: [],
			respuestaConvenios: [],
			Quixote: "",
			pendientes: [],
			ingresos: [],
			numero_comprobantes: [],
			datepickerInstance: DatePickerComponent,
			fecha_registro: "",
			actualizar:false,
		};
		this.cambioValor = this.cambioValor.bind(this);
	}

	cargarDatos() {
		fetch(
			Api +
				"registroAlicuotas.php" +
				"?consultar=" +
				this.props.match.params.id
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				// console.log(datosRespuesta);
				// console.log(parseInt(datosRespuesta[0].NUMERO_COMPROBANTE));
					if(parseInt(datosRespuesta[0].NUMERO_COMPROBANTE)<1){						
				this.cargarComprobante();
			}else{
				cookies.set("numero_comprobante",datosRespuesta[0].NUMERO_COMPROBANTE);
			}
				this.setState({
					datosCargados: true,
					alicuota: datosRespuesta[0],

				});
			})
			.catch(console.log);

		fetch(
			Api + "copropietarios.php" + "?listar=" + cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				// console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					condominos: datosRespuesta,
				});
			})
			.catch(console.log);
		
			
	}
	cambioValor = (e) => {
		const state = this.state.alicuota;
		state[e.target.name] = e.target.value;
		this.setState({ alicuota: state, errores: [] });
	};
	enviarDatos() {
		// console.log("Formulario enviado.....");
		const {
			ID_ALICUOTA,
			NUMERO_COMPROBANTE,
			MES,
			VALOR,
			ID_CONDOMINO,
			USUARIO_MODIFICACION,
			ESTADO_PAGO,
			ID_TIPO_PAGO,
			NUM_CHEQUE_DEPOSITO,
			ID_CONCEPTO,
			COPRO_ARREN,
			DESCRIPCION,
			FECHA_REGISTRO,
		} = this.state.alicuota;

		var datosEnviar = {
			ID_ALICUOTA: ID_ALICUOTA,
			NUMERO_COMPROBANTE: cookies.get("numero_comprobante"),
			MES: MES,
			VALOR: VALOR,
			ID_CONDOMINO: ID_CONDOMINO,
			USUARIO_MODIFICACION: cookies.get("id"),
			id_tipo_pago: ID_TIPO_PAGO,
			numero_cheq_dep: NUM_CHEQUE_DEPOSITO,
			id_concepto: ID_CONCEPTO,
			id_copArr: COPRO_ARREN,
			descripcion: DESCRIPCION,
			FECHA_REGISTRO: FECHA_REGISTRO,
			ESTADO_PAGO:cookies.get("estado_pago"),
		};
		
		// console.log(cookies.get("estado_pago"));
		if (cookies.get("mes") != null) {
			datosEnviar.MES = cookies.get("mes");
		}
		if (cookies.get("id_tipo_pago") != null) {
			datosEnviar.id_tipo_pago = cookies.get("id_tipo_pago");
		}
		if (cookies.get("id_concepto") != null) {
			datosEnviar.id_concepto = cookies.get("id_concepto");
		}
		if (cookies.get("id_copArr") != null) {
			datosEnviar.id_copArr = cookies.get("id_copArr");
		}
		if (cookies.get("fecha_registro") != null) {
			datosEnviar.FECHA_REGISTRO = cookies.get("fecha_registro");
		}
		if(cookies.get("estado_pago")!=null){
				datosEnviar.ESTADO_PAGO=cookies.get("estado_pago");
		}
		var errores = [];
		if (!NUMERO_COMPROBANTE) errores.push("error_codigo");
		if (!MES) errores.push("error_descripcion");
		this.setState({ errores: errores });

		if (errores.length > 0) return false;
		// console.log(datosEnviar);
		// console.log(datosEnviar.ESTADO_PAGO);
		fetch(Api + "registroAlicuotas.php" + "?actualizar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		}).then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				// console.log(datosRespuesta);
			window.location.href = "/listarAlicuota";
			})
			.catch(console.log);
		// console.log(datosEnviar.ESTADO_PAGO);
		if ((datosEnviar.ESTADO_PAGO = "PAGADO")) {
				if(this.state.actualizar){
				this.actualizarComprobante();
			}
			
		}
		this.cargarImpresion();
		this.cargarDatos();
		
	}

	actualizarComprobante() {
		var datosEnviar = {
			id_comprobante: cookies.get("id_comprobante"),
			numero_comprobante: parseInt(cookies.get("numero_comprobante")) + 1,
		};
		// console.log(datosEnviar);
		fetch(Api + "numeroComprobante.php" + "?actualizar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		}).catch(console.log);
	}

	componentDidMount() {
		this.cargarDatos();
		this.cargarFormasPago();
		this.cargarConcepto();
		this.cargarImpresion();
		
	}

	cargarComprobante() {
		this.setState({actualizar:true});
		fetch(
			Api +
				"numeroComprobante.php" +
				"?consultar=" +
				cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				// console.log(datosRespuesta);
				cookies.set("numero_comprobante",datosRespuesta[0].numero_comprobante);
				this.setState({
					numero_comprobantes: datosRespuesta,

				});
			})
			.catch(console.log);
	}
	cargarFormasPago() {
		fetch(Api + "registroAlicuotas.php" + "?listarFormaPago")
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				// console.log(datosRespuesta);
				this.setState({
					formaPagos: datosRespuesta,
				});
			})
			.catch(console.log);
	}
	cargarConcepto() {
		fetch(Api + "registroAlicuotas.php" + "?listarConcepto")
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				// console.log(datosRespuesta);
				this.setState({
					conceptos: datosRespuesta,
				});
			})
			.catch(console.log);
	}

	cargarImpresion() {
		// console.log(this.props.match.params.id);
		fetch(
			Api +
				"registroAlicuotas.php" +
				"?impresion=" +
				this.props.match.params.id
		)
			.then((respuesta) => respuesta.json())
			.then((respuestaAlicuotas) => {
				// console.log(respuestaAlicuotas);
				this.setState({
					datosCargados: true,
					alicuotass: respuestaAlicuotas,
				});
			})
			.catch(console.log);
		this.cargarPendiente();
	}

	cargarPendiente() {
		var data = this.props.match.params.id;
		const answer_array = data.split(",");
		fetch(Api + "registroAlicuotas.php" + "?pendiente=" + answer_array[1])
			.then((respuesta) => respuesta.json())
			.then((respuestaOtros) => {
				// console.log(respuestaOtros);
				this.setState({
					datosCargados: true,
					pendientes: respuestaOtros,
				});
			})
			.catch(console.log);
	}
	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}
	render() {
		var Quixote;
		const dateValue: Date = new Date(
			new Date().getFullYear(),
			new Date().getMonth,
			20
		);
		const changeMes = (event: any) => {
			const state = this.state;
			state[mes] = event.target.value;
			cookies.set("mes", state[mes], { path: "/" });
			// console.log(cookies.get("mes"));
		};
		const {
			datosCargados,
			alicuota,
			condominos,
			id_condomino,
			mes,
			estado_pago,
			formaPagos,
			id_tipo_pago,
			numero_cheq_dep,
			conceptos,
			id_concepto,
			id_copArr,
			descripcion,
			ingresos,
			alicuotass,
			otros,
			convenios,
			pendientes,
			numero_comprobantes,
			fecha_registro,
		} = this.state;
		const changeLanguage = (event: any) => {
			const state = this.state;
			state[id_condomino] = event.target.selectedIndex;
			cookies.set("id_condomino", state[id_condomino], { path: "/" });
		};
		const changePago = (event: any) => {
			const state = this.state;
			state[estado_pago] = event.target.value;
			cookies.set("estado_pago", state[estado_pago], { path: "/" });
			// console.log(cookies.get("estado_pago"));
		};
		let selectedOptionId = 0;
		const changeFormaPago = (event: any) => {
			const state = this.state;
			state[id_tipo_pago] = event.target.value;
			cookies.set("id_tipo_pago", state[id_tipo_pago], { path: "/" });
			// console.log(cookies.get("id_tipo_pago"));
		};
		const changeConcepto = (event: any) => {
			const state = this.state;
			state[id_concepto] = event.target.value;
			cookies.set("id_concepto", state[id_concepto], { path: "/" });
			// console.log(cookies.get("id_concepto"));
		};
		const changeCopArr = (event: any) => {
			const state = this.state;
			state[id_copArr] = event.target.value;
			cookies.set("id_copArr", state[id_copArr], { path: "/" });
			// console.log(cookies.get("id_copArr"));
		};
		let found = alicuotass.map((ali) => ali.APELLIDO);
		cookies.set("id_condomino", found[0], { path: "/" });
		// console.log(cookies.get("id_condomino"));

		let alip = ingresos
			.filter(
				(ing) =>
					(ing.ESTADO_PAGO === "PAGADO") &
					(ing.CONCEPTO === "ALICUOTAS")
			)
			.map((ing) => parseInt(ing.SUMA));
		let alicuotasp = parseInt(alip);
		let alicuotas;
		let ali = ingresos
			.filter(
				(ing) =>
					(ing.ESTADO_PAGO === "PENDIENTE") &
					(ing.CONCEPTO === "ALICUOTAS")
			)
			.map((ing) => ing.SUMA);
		if (ali.length == 0) {
			alicuotas = 0;
		} else {
			alicuotas = parseInt(ali);
		}

		let cobp = ingresos
			.filter(
				(ing) =>
					(ing.ESTADO_PAGO === "PENDIENTE") &
					(ing.CONCEPTO === "COBROS")
			)
			.map((ing) => ing.SUMA);
		let cobrosp = parseInt(cobp);
		let cob = ingresos
			.filter(
				(ing) =>
					(ing.ESTADO_PAGO === "PAGADO") & (ing.CONCEPTO === "COBROS")
			)
			.map((ing) => ing.SUMA);
		let cobros = parseInt(cob);
		let vari = ingresos
			.filter((ing) => ing.CONCEPTO === "INGRESOS VARIOS")
			.map((ing) => ing.SUMA);
		let varios = parseInt(vari);
		let saldo = parseInt(alicuotas) + parseInt(cobrosp);
		const numero2 = ingresos.map((ing) => ing.ingresos);
		let sumar = parseInt(alicuotasp) + parseInt(cobros) + parseInt(varios);
		const data = {
			labels: [
				"Alícuotas Pagadas",
				"Alicuotas Pendientes",
				"Otros por Cobrar Pagos",
				"Otros por Cobrar Pendiente",
				"Otros Pagos",
				"Saldo por Pagar",
			],
			datasets: [
				{
					label: "VALORES",
					backgroundColor: "#cfe2ff",
					borderColor: "#fff3cd",
					borderWidth: 1,
					hoverBackgroundColor: "",
					hoverBorderColor: "#Ff0000",
					data: [
						alicuotasp,
						alicuotas,
						cobros,
						cobrosp,
						varios,
						saldo,
					],
				},
			],
		};
		const opciones = {
			maintainAspectRatio: false,
			responsive: true,
		};
		
		const changefecha = (event: any) => {
			const state = this.state;
			state[fecha_registro] = event.target.value;
			cookies.set("fecha_registro", state[fecha_registro], { path: "/" });
		};
		return (
			<div className="card text-primary|secondary|success|danger|warning|info|light|dark bg-primary|secondary|success|danger|warning|info|light|dark">
				<div className="card-header">Featured</div>
				<div className="card-body">
				<form onSubmit={this.enviarDatos}>
					<div className="form-group ">
						<label htmlFor="">Id</label>
						<input
							onChange={this.cambioValor}
							readOnly
							type="text"
							className="form-control"
							name="ID_ALICUOTA"
							id="ID_ALICUOTA"
							value={alicuota.ID_ALICUOTA}
						/>
					</div>

					<div className="form-group">
						<label htmlFor="">Condomino:</label>
						<br />
						<select
							className="custom-select custom-select-sm"
							onChange={(event) => {
								changeLanguage(event);
							}}
						>
							<option selected>Seleccionar</option>
							{condominos.map((condomino) => (
								<option value={condomino.ID_CONDOMINO}>
									{" "}
									{condomino.CASA_OFI_DEP}
								</option>
							))}
						</select>
						<br />
						<small id="helpNombre" className="invalid-feeback">
							Seleccione Condomino
						</small>
						<br />
						<div className="form-group ">
							<label htmlFor="">
								Nombres Condomino Registrado
							</label>
							<div>
								<div className="alert alert-success">
									<strong>
										{alicuota.NOMBRE} {alicuota.APELLIDO}
									</strong>
								</div>
							</div>
						</div>
						<label htmlFor="">Número Comprobante:</label>
						<div>
							<div className="alert alert-success">
								<strong>{cookies.get("numero_comprobante")}</strong>
							</div>
						</div>
						<br />
						<div class="col-md-6">
							<label htmlFor="">Fecha de Pago:</label>
							<div className="datepicker-control-section">
								<DatePickerComponent
									onChange={(event) => {
										changefecha(event);
									}}
									dateFormat="YYYY/MM/DD"
									ref={(calendar) =>
										(this.datepickerInstance = calendar)
									}
									value={dateValue}
								></DatePickerComponent>
							</div>
							<br />
						</div>

						<div className="alert alert-success">
							<br />
							<label className="lbl lbl-success" htmlFor="">
								<strong>{alicuota.MES}</strong>
							</label>
						</div>
						<label htmlFor="">Tipo Forma Pago:</label>
						<select
							defaultValue={selectedOptionId}
							class="custom-select custom-select-lg|custom-select-sm"
							onChange={(event) => {
								changeFormaPago(event);
							}}
						>
							<option selected>Seleccionar</option>
							{formaPagos.map((forma) => (
								<option value={forma.ID_TIPO_PAGO}>
									{forma.DESCRIPCION}
								</option>
							))}
						</select>
						<br />
						<br />
						<label htmlFor="">N° Cheque/Deposito:</label>
						<input
							onChange={this.cambioValor}
							value={alicuota.NUM_CHEQUE_DEPOSITO}
							type="text"
							name="NUM_CHEQUE_DEPOSITO"
							id="NUM_CHEQUE_DEPOSITO"
							placeholder="N° Cheque/Deposito"
						/>
						<br />
						<br />
						<label htmlFor="">Concepto:</label>
						<select
							defaultValue={selectedOptionId}
							class="custom-select custom-select-lg|custom-select-sm"
							onChange={(event) => {
								changeConcepto(event);
							}}
						>
							<option selected>Seleccionar</option>
							{conceptos.map((con) => (
								<option value={con.ID_TIPO_PAGO}>
									{con.DESCRIPCION}
								</option>
							))}
						</select>
						<br />
						<br />
						<br />
						<label htmlFor="">Copropietario / Arrendatario:</label>
						<br />
						<select
							defaultValue={selectedOptionId}
							class="custom-select custom-select-lg|custom-select-sm"
							onChange={(event) => {
								changeCopArr(event);
							}}
						>
							<option selected>Seleccionar</option>
							<option value="C">COPROPIETARIO</option>
							<option value="A">ARRENDATARIO</option>
						</select>
						<br />
						<small id="helpNombre" className="invalid-feeback">
							Escoja Copropietario o Arrendatario
						</small>
						<br />
						<label htmlFor="">Descripción:</label>
						<br />
						<textarea class="md-textarea form-control"
							maxlength="100"
							cols="60"
							rows="2"
							onChange={this.cambioValor}
							value={alicuota.DESCRIPCION}
							type="text"
							name="DESCRIPCION"
							id="DESCRIPCION"
							placeholder="Descripción"
						/>
						<br />
						<small id="helpNombre" className="invalid-feeback">
							Escribe una Descripción (opcional)
						</small>
						<br />
						<label htmlFor="">Valor:</label>
						<input
							pattern="^\d*(\.\d{0,2})?$"
							onChange={this.cambioValor}
							value={alicuota.VALOR}
							type="number"
							name="VALOR"
							id="VALOR"
							className={
								(this.verificarError("error_valor")
									? "is-invalid"
									: "") + " form-control"
							}
							placeholder="Valor"
						/>
						<small id="helpNombre" className="invalid-feeback">
							Escribe el Valor del Registro
						</small>
						<br />
						<label htmlFor="">Estado Pago:</label>
						<br />
						<select
							defaultValue={selectedOptionId}
							className="custom-select custom-select-lg|custom-select-sm"
							onChange={(event) => {
								changePago(event);
							}}
						>
							<option selected>Seleccionar</option>
							<option value="PENDIENTE">PENDIENTE</option>
							<option value="PAGADO">PAGADO</option>
						</select>
						<br />

						<small id="helpNombre" className="invalid-feeback">
							Escribe el Estado de Pago del Registro
						</small>
						<br />
						<div className="form-group ">
							<label htmlFor="">Estado de Pago Registrado</label>
							<div>
								<div className="alert alert-success">
									<strong>{alicuota.ESTADO_PAGO}</strong>
								</div>
							</div>
						</div>
					</div>
					<div
						className="btn-group"
						role="group"
						aria-label="Basic example"
					>
						<Link
							className="btn btn-success"
							onClick={() => this.enviarDatos()}							
						>
							Guardar Registro
						</Link>
						<Link
							to={"/listarAlicuota"}
							className="btn btn-primary"
						>
							Cancelar
						</Link>
					</div>
					</form>
				</div>
				
			</div>
		);
	}
}
