import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import { format } from "date-fns";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class ListarGastos extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			gastos: [],
		};
	}

	cargarDatos() {
		console.log("id" + cookies.get("id"));
		fetch(
			Api +
				"gastos.php" +
				"?listar=" +
				cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					gastos: datosRespuesta,
				});
			})
			.catch(console.log);
	}

	componentDidMount() {
		this.cargarDatos();
	}

	borrarRegistro = (ID_GASTOS) => {
		fetch(Api + "gastos.php" + "?borrar=" + ID_GASTOS)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(ID_GASTOS);
				console.log(datosRespuesta);
				this.cargarDatos();
				window.location.href = "/listarGasto";
			})
			.catch(console.log);
	};
	
	render() {
		
		const { datosCargados, gastos } = this.state;
		return (
			<Fragment>
				<div className="card text-primary">
					<div className={(cookies.get("perfil") == 'DIRECTIVOS' ? "d-none" : "") +" card-header"}>
						<Link
							to={"/registrarGasto"}
							className="btn btn-success"
						>
							Agregar Nuevo Registro
						</Link>
					</div>
					<div className="card-body">
						<h4>Lista de Gastos</h4>
						<table className="table table-hover">
							<caption>Lista de Gastos</caption>
							<thead className="thead-dark|thead-light">
								<tr>
									<th scope="col">Id</th>
									<th scope="col">Comprobantes</th>
									<th scope="col">Descripción</th>
									<th scope="col">Valor</th>
									<th scope="col">Acciones</th>
									</tr>
							</thead>
							<tbody>
								{gastos.map((gastoss) => (
									<tr key={gastoss.ID_GASTOS}>
										<th scope="row">
											{gastoss.ID_GASTOS}
										</th>
										<td>{gastoss.COMPROBANTES}</td>
										<td>{gastoss.DESCRIPCION}</td>
										<td>{gastoss.VALOR}</td>
										
										<td>
											{" "}
											<div
												className={(cookies.get("perfil") == 'DIRECTIVOS' ? "d-none" : "") +" btn-group"}
												role="group"
												aria-label="Basic example"
											>
												<Link
													to={
														"/editarGasto/" +
														gastoss.ID_GASTOS
													}
													className="btn btn-warning"
												>
													Editar
												</Link>

												<Link
													to={"/"}
													className="btn btn-danger"
													onClick={() =>
														this.borrarRegistro(
															gastoss.ID_GASTOS
														)
													}
												>
													Borrar
												</Link>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</Fragment>
		);
	}
}
