import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import { format } from "date-fns";
import Cookies from "universal-cookie";
import { Bar } from "react-chartjs-2";
import logo from "./baner.png";
import dateFormat from "dateformat";
import logoSol2 from "./logoSoL2.png";
import moment from "moment";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	PDFViewer,
	PDFDownloadLink,
	Image,
	ReactPDF,
} from "@react-pdf/renderer";
import {
	DatePickerComponent,
	RenderDayCellEventArgs,
	ChangedEventArgs,
	CalendarComponent,
} from "@syncfusion/ej2-react-calendars";
const cookies = new Cookies();

export default class ListarBalance extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			ingresos: [],
			datosRespuesta: [],
			respuestaAlicuotas: [],
			alicuotass: [],
			otros: [],
			respuestaOtros: [],
			convenios: [],
			respuestaConvenios: [],
			Quixote: "",
			egresos: [],
			fecha_desde: "",
			fecha_hasta: "",
			ingresosDet: [],
			egresosDet: [],
			datepickerInstance: DatePickerComponent,
			saldoAnterior: [],
			costos:[],
		};
	}

	cargarIngresos() {
		// console.log(cookies.get("id_empresa"));
		fetch(
			Api + "balance.php" + "?listarIngresos=" + cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				// console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					ingresos: datosRespuesta,
				});
			})
			.catch(console.log);
	}
	cargarEgresos() {
		fetch(
			Api + "balance.php" + "?listarEgresos=" + cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((respuestaEgresos) => {
				// console.log(respuestaEgresos);
				this.setState({
					datosCargados: true,
					egresos: respuestaEgresos,
				});
			})
			.catch(console.log);
	}
	cargarSaldo() {
		var datosEnviar = {
			fecha_desde: cookies.get("fecha_desde"),
			fecha_hasta: cookies.get("fecha_hasta"),
			id: cookies.get("id_empresa"),
			usuario_modificacion: cookies.get("id"),
		};
		fetch(Api + "balance.php" + "?saldo", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((respuestaSaldo) => {
				// console.log(respuestaSaldo);
				this.setState({
					datosCargados: true,
					saldoAnterior: respuestaSaldo,
				});
			})
			.catch(console.log);
	}

	cargarCostos() {
		var datosEnviar = {
			fecha_desde: cookies.get("fecha_desde"),
			fecha_hasta: cookies.get("fecha_hasta"),
			id: cookies.get("id_empresa"),
			usuario_modificacion: cookies.get("id"),
		};
		console.log(datosEnviar);
		fetch(Api + "balance.php" + "?costos" , {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((respuestaCostos) => {
			 console.log(respuestaCostos);
				this.setState({
					datosCargados: true,
					costos: respuestaCostos,
				});
			})
			.catch(console.log);
	}
	filtrarRangoFechas() {
		// console.log("Formulario enviado.....");
		const { fecha_desde, fecha_hasta } = this.state;
		var datosEnviar = {
			fecha_desde: cookies.get("fecha_desde"),
			fecha_hasta: cookies.get("fecha_hasta"),
			id: cookies.get("id_empresa"),
			usuario_modificacion: cookies.get("id"),
		};

		var errores = [];
		// console.log(datosEnviar);
		fetch(Api + "balance.php" + "?listarPorFechas=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((respuestaIngresos) => {
				// console.log(respuestaIngresos);
				this.setState({
					datosCargados: true,
					ingresos: respuestaIngresos,
				});
			})
			.catch(console.log);
		fetch(Api + "balance.php" + "?listarEgresosPorFechas=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((respuestaEgresos) => {
				// console.log(respuestaEgresos);
				this.setState({
					datosCargados: true,
					egresos: respuestaEgresos,
				});
			})
			.catch(console.log);
		this.cargarRangoFechas();
	}

	cargarRangoFechas() {
		// console.log("Cargando por fechas");
		const { fecha_desde, fecha_hasta } = this.state;
		var datosEnviar = {
			fecha_desde: cookies.get("fecha_desde"),
			fecha_hasta: cookies.get("fecha_hasta"),
			id: cookies.get("id_empresa"),
		};

		var errores = [];
		// console.log(Api + "balance.php" + "?listarIngresoPorFechasDet=1");
		fetch(Api + "balance.php" + "?listarIngresoPorFechasDet=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((respuestaIngresos) => {
				// console.log(respuestaIngresos);
				this.setState({
					datosCargados: true,
					ingresosDet: respuestaIngresos,
				});
			})
			.catch(console.log);
		fetch(Api + "balance.php" + "?listarEgresosPorFechasDet=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((respuestaEgresos) => {
				// console.log(respuestaEgresos);
				this.setState({
					datosCargados: true,
					egresosDet: respuestaEgresos,
				});
			})
			.catch(console.log);
	}

	componentDidMount() {
		this.cargarIngresos();
		this.cargarEgresos();
		this.cargarSaldo();
		this.cargarCostos();
	}

	onChange(): void {
		let format: any = this.listObj.value;
		this.datepickerInstance.format = format;
		// console.log(format);
	}
	render() {
		var Quixote;
		const {
			datosCargados,
			ingresos,
			alicuotass,
			otros,
			convenios,
			egresos,
			fecha_desde,
			fecha_hasta,
			ingresosDet,
			egresosDet,
			saldoAnterior,
			costos,
		} = this.state;
		let alip = ingresos
			.filter(
				(ing) =>
					(ing.ESTADO_PAGO === "PAGADO") &
					(ing.CONCEPTO === "ALICUOTAS")
			)
			.map((ing) => parseInt(ing.SUMA));
		// console.log(ingresos);
		let alicuotasp = parseInt(alip);
		let alicuotas;
		let ali = ingresos
			.filter(
				(ing) =>
					(ing.ESTADO_PAGO === "PENDIENTE") &
					(ing.CONCEPTO === "ALICUOTAS")
			)
			.map((ing) => ing.SUMA);
		if (ali.length == 0) {
			alicuotas = 0;
		} else {
			alicuotas = parseInt(ali);
		}

		let cobp = ingresos
			.filter(
				(ing) =>
					(ing.ESTADO_PAGO === "PENDIENTE") &
					(ing.CONCEPTO === "COBROS")
			)
			.map((ing) => ing.SUMA);
		let cobrosp = parseInt(cobp);
		let cob = ingresos
			.filter(
				(ing) =>
					(ing.ESTADO_PAGO === "PAGADO") & (ing.CONCEPTO === "COBROS")
			)
			.map((ing) => ing.SUMA);
		let cobros = parseInt(cob);
		let vari = ingresos
			.filter((ing) => ing.CONCEPTO === "INGRESOS VARIOS")
			.map((ing) => ing.SUMA);
		let varios = parseInt(vari);
		let saldo = parseInt(alicuotas) + parseInt(cobrosp);
		const numero2 = ingresos.map((ing) => ing.ingresos);
		let sumar = parseInt(alicuotasp) + parseInt(cobros) + parseInt(varios);

		const opciones = {
			maintainAspectRatio: false,
			responsive: true,
		};
		const BORDER_COLOR = "#bfbfbf";
		const BORDER_STYLE = "solid";
		const COL1_WIDTH = 40;
		const COL2_WIDTH = 60;
		const COLN_WIDTH = (100 - COL1_WIDTH) / 3;
		const styles = StyleSheet.create({
			body: {
				padding: 20,
			},
			iframe: {
				width: "400px !important",
				height: "350px !important",
			},
			table: {
				display: "table",
				width: "auto",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 1,
				borderRightWidth: 0,
				borderBottomWidth: 0,
			},
			tableRow: {
				margin: "auto",
				flexDirection: "row",
			},
			tableCol1Header: {
				width: COL1_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderBottomColor: "#000",
				borderWidth: 1,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableColHeader: {
				width: COLN_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderBottomColor: "#000",
				borderWidth: 1,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableCol1: {
				width: COL1_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 1,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableCol: {
				width: COLN_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 1,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableCellHeader: {
				margin: 5,
				fontSize: 8,
				fontWeight: 200,
			},
			tableCell: {
				margin: 5,
				fontSize: 10,
			},
			section: {
				margin: 5,
				padding: 5,
			},
			titulo: {
				fontSize: 30,
				fontWeight: 500,
			},
			saldo: {
				color: "#a85960",
			},
			descripcion: {
				margin: 3,
				fontSize: 8,
			},
			tableCol2: {
				width: COL2_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 1,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableCol2Header: {
				width: COL2_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderBottomColor: "#000",
				borderWidth: 1,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			ingresos: {
				color: "#0d6efd",
				margin: 5,
				fontSize: 12,
				fontWeight: 500,
			},
			egresos: {
				color: "#6c757d",
				margin: 5,
				fontSize: 12,
				fontWeight: 500,
			},
			total_ingreso: {
				color: "#377144",
				margin: 5,
				fontSize: 12,
				fontWeight: 500,
			},
			table2: {
				display: "table",
				width: "auto",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 1,
				borderRightWidth: 0,
				borderBottomWidth: 0,
			},
			tableCol4Header: {
				width: 35 + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderBottomColor: "#000",
				borderWidth: 0,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			table3: {
				display: "table",
				width: "auto",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 0,
				borderRightWidth: 0,
				borderBottomWidth: 0,
			},
			tableCol6: {
				width: 15 + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 1,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableCol7: {
				width: 30 + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 1,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableCol8: {
				width: 15 + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 1,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableRowEgreso: {
				leftMargin: 10,
				topMargin: 2,
				rightMargin: 10,
				bottomMargin: 2,
			},
			tableCellEgreso: {
				margin: 5,
				fontSize: 8,
				fontWeight: 200,
			},
		});
		var nombre = ingresos.map((ing) => ing.NOMBRE);
		var apellido = ingresos.map((ing) => ing.APELLIDO);
		var identificacion = ingresos.map((ing) => ing.IDENTIFICACION);
		var casa_ofi_depa = ingresos.map((ing) => ing.CASA_OFI_DEP);
		var empresa = ingresos.map((ing) => ing.EMPRESA);
		var imagen = "";
		let alicuota_efectivo = ingresos.map((ing) =>
			parseFloat(ing.alicuota_efectivo).toFixed(2)
		);
		var alquiler_efectivo = ingresos.map((ing) =>
			parseFloat(ing.alquiler_efectivo).toFixed(2)
		);
		var otros_efectivo = ingresos.map((ing) =>
			parseFloat(ing.otros_efectivo).toFixed(2)
		);

		let alicuota_cheque = ingresos.map((ing) =>
			parseFloat(ing.alicuota_cheque).toFixed(2)
		);
		var alquiler_cheque = ingresos.map((ing) =>
			parseFloat(ing.alquiler_cheque).toFixed(2)
		);
		var otros_cheque = ingresos.map((ing) =>
			parseFloat(ing.otros_cheque).toFixed(2)
		);

		let alicuota_deposito = ingresos.map((ing) =>
			parseFloat(ing.alicuota_deposito).toFixed(2)
		);
		var alquiler_deposito = ingresos.map((ing) =>
			parseFloat(ing.alquiler_deposito).toFixed(2)
		);
		var otros_deposito = ingresos.map((ing) =>
			parseFloat(ing.otros_deposito).toFixed(2)
		);
		if (alicuota_cheque == "NaN" || alicuota_cheque == null) {
			alicuota_cheque = "0";
		}
		if (alicuota_deposito == "NaN" || alicuota_deposito == null) {
			alicuota_deposito = "0";
		}
		if (alicuota_efectivo == "NaN" || alicuota_efectivo == null) {
			alicuota_efectivo = "0";
		}
		if (alquiler_efectivo == "NaN" || alquiler_efectivo == null) {
			alquiler_efectivo = "0";
		}
		if (alquiler_deposito == "NaN" || alquiler_deposito == null) {
			alquiler_deposito = "0";
		}
		if (alquiler_cheque == "NaN" || alquiler_cheque == null) {
			alquiler_cheque = "0";
		}
		if (otros_deposito == "NaN") {
			otros_deposito = "0";
		}
		if (otros_cheque == "NaN") {
			otros_cheque = "0";
		}
		if (otros_efectivo == "NaN") {
			otros_efectivo = "0";
		}
		var ing_deposito = (
			parseFloat(alicuota_deposito) +
			parseFloat(alquiler_deposito) +
			parseFloat(otros_deposito)
		).toFixed(2);
		var ing_cheque = (
			parseFloat(alicuota_cheque) +
			parseFloat(alquiler_cheque) +
			parseFloat(otros_cheque)
		).toFixed(2);
		var ing_efectivo = (
			parseFloat(alicuota_efectivo) +
			parseFloat(alquiler_efectivo) +
			parseFloat(otros_efectivo)
		).toFixed(2);
		// console.log(alicuota_efectivo);
		// console.log(alquiler_efectivo);
		// console.log(otros_efectivo);
		let total_alicuotas = (
			parseFloat(alicuota_efectivo) +
			parseFloat(alicuota_deposito) +
			parseFloat(alicuota_cheque)
		).toFixed(2);
		var total_alquiler = (
			parseFloat(alquiler_efectivo) +
			parseFloat(alquiler_deposito) +
			parseFloat(alquiler_cheque)
		).toFixed(2);
		var total_otros = (
			parseFloat(otros_deposito) +
			parseFloat(otros_cheque) +
			parseFloat(otros_efectivo)
		).toFixed(2);
		if (total_alicuotas == "NaN") {
			// console.log("entra");
			total_alicuotas = 0;
		}
		if (total_alquiler == "NaN") {
			// console.log("entra");
			total_alquiler = 0;
		}
		if (total_otros == "NaN") {
			// console.log("entra");
			total_otros = 0;
		}
		let total_ingresos = (
			parseFloat(total_alicuotas) +
			parseFloat(total_otros) +
			parseFloat(total_alquiler)
		).toFixed(2);
		// console.log(parseFloat(total_alicuotas));
		// console.log(parseFloat(total_otros));
		// console.log(parseFloat(total_alquiler));
		let egreso = parseFloat(egresos.map((egre) => egre.GASTOS)).toFixed(2);
		let saldos = parseFloat(total_ingresos);
		let saldoActual = (
			parseFloat(saldoAnterior.map((s) => s.valor)) +
			parseFloat(total_ingresos)
		).toFixed(2);
		let costoB = parseFloat(costos.map((cos)=>cos.VALOR)).toFixed(2);
		// console.log(costoB);
		let resta=(parseFloat(egreso)+parseFloat(costoB)).toFixed(2);
		// console.log(resta);
		let saldoMenosEgresos = (parseFloat(saldoActual) - resta).toFixed(2);
		const data = {
			labels: [
				"Ingresos Efectivo",
				"Ingresos Cheque",
				"Ingresos Depósitos",
				"Egresos",
				"Saldo",
			],
			datasets: [
				{
					label: "VALORES",
					backgroundColor: "#cfe2ff",
					borderColor: "#fff3cd",
					borderWidth: 1,
					hoverBackgroundColor: "",
					hoverBorderColor: "#Ff0000",
					data: [
						ing_efectivo,
						ing_cheque,
						ing_deposito,
						egreso,
						saldos,
					],
				},
			],
		};
		Quixote = () => (
			<Document>
				<Page style={styles.body}>
					<View style={styles.section}>
						<Text style={styles.titulo}>{empresa[0]}</Text>
					</View>
					<View style={styles.section}>
						<Text style={styles.titulo}>
							{cookies.get("empresa")}
						</Text>
					</View>
					<View style={styles.section}>
						<Text>
							Fecha desde :{cookies.get("desde")} {"      "}Fecha
							hasta:{cookies.get("hasta")}
						</Text>
						<Text>Ingresos</Text>
					</View>

					<View style={styles.table}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol6}>
								<Text style={styles.tableCellHeader}>
									N° Comprobante
								</Text>
							</View>
							<View style={styles.tableCol8}>
								<Text style={styles.tableCellHeader}>
									N° Casa
								</Text>
							</View>
							<View style={styles.tableCol8}>
								<Text style={styles.tableCellHeader}>
									Mes / Año
								</Text>
							</View>
							<View style={styles.tableCol8}>
								<Text style={styles.tableCellHeader}>
									Concepto
								</Text>
							</View>

							<View style={styles.tableColHeader}>
								<Text style={styles.tableCellHeader}>
									Forma de Pago
								</Text>
							</View>
							<View style={styles.tableCol6}>
								<Text style={styles.tableCellHeader}>
									Valor
								</Text>
							</View>
						</View>
						<View style={styles.tableRow}>
							<View style={styles.tableCol6}>
								{ingresosDet.map((ingreso) => (
									<Text style={styles.tableCellHeader}>
										{ingreso.NUMERO_COMPROBANTE}
									</Text>
								))}
							</View>
							<View style={styles.tableCol8}>
								{ingresosDet.map((ingreso) => (
									<Text style={styles.tableCellHeader}>
										{ingreso.CASA_OFI_DEP}
									</Text>
								))}
							</View>
							<View style={styles.tableCol8}>
								{ingresosDet.map((ingreso) => (
									<Text style={styles.tableCellHeader}>
										{ingreso.MES}
									</Text>
								))}
							</View>
							<View style={styles.tableCol8}>
								{ingresosDet.map((ingreso) => (
									<Text style={styles.tableCellHeader}>
										{ingreso.CONCEPTO}
									</Text>
								))}
							</View>

							<View style={styles.tableCol}>
								{ingresosDet.map((ingreso) => (
									<Text style={styles.tableCellHeader}>
										{ingreso.tipoPago}
									</Text>
								))}
							</View>
							<View style={styles.tableCol8}>
								{ingresosDet.map((ingreso) => (
									<Text style={styles.tableCellHeader}>
										{ingreso.VALOR}
									</Text>
								))}
							</View>
						</View>
					</View>

					<View style={styles.section}>
						<Text>Egresos</Text>
					</View>

					<View style={styles.table}>
						<View style={styles.tableRow}>
							<View style={styles.tableColHeader}>
								<Text style={styles.tableCellHeader}>
									N° Comprobante
								</Text>
							</View>
							<View style={styles.tableColHeader}>
								<Text style={styles.tableCellHeader}>
									Fecha de Registro
								</Text>
							</View>
							<View style={styles.tableCol1Header}>
								<Text style={styles.tableCellHeader}>
									Detalle
								</Text>
							</View>
							<View style={styles.tableColHeader}>
								<Text style={styles.tableCellHeader}>
									Valor
								</Text>
							</View>
						</View>
						<View style={styles.tableRow}>
							<View style={styles.tableCol}>
								{egresosDet.map((egreso) => (
									<Text style={styles.tableCellHeader}>
										{egreso.COMPROBANTES}
									</Text>
								))}
							</View>
							<View style={styles.tableCol}>
								{egresosDet.map((egreso) => (
									<Text style={styles.tableCellHeader}>
										{egreso.FECHA_REGISTRO}
									</Text>
								))}
							</View>
							<View style={styles.tableCol1}>
								{egresosDet.map((egreso) => (
									<Text style={styles.tableCellHeader}>
										{egreso.DESCRIPCION}
									</Text>
								))}
							</View>
							<View style={styles.tableCol}>
								{egresosDet.map((egreso) => (
									<Text style={styles.tableCellHeader}>
										{egreso.VALOR}
									</Text>
								))}
							</View>
						</View>
						<br />
					</View>

					<View style={styles.table3}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol4Header}>
								<View style={styles.section}>
									<Text style={styles.ingresos}>
										Total Pagos Efectivo :
									</Text>
									<Text style={styles.ingresos}>
										${ing_efectivo}
									</Text>
								</View>
							</View>
							<View style={styles.tableCol4Header}>
								<View style={styles.section}>
									<Text style={styles.ingresos}>
										Total Pagos Cheque :
									</Text>
									<Text style={styles.ingresos}>
										${ing_cheque}
									</Text>
								</View>
							</View>
							<View style={styles.tableCol4Header}>
								<View style={styles.section}>
									<Text style={styles.ingresos}>
										Total Pagos Deposito :
									</Text>
									<Text style={styles.ingresos}>
										${ing_deposito}
									</Text>
								</View>
							</View>
						</View>
					</View>

					<View style={styles.table3}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol4Header}>
								<View style={styles.section}>
									<Text style={styles.total_ingreso}>
										Total Ingresos
									</Text>
									<Text style={styles.total_ingreso}>
										${total_ingresos}
									</Text>
								</View>
							</View>
						</View>
					</View>

					<View style={styles.table3}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol4Header}>
								<View style={styles.section}>
									<Text style={styles.total_ingreso}>
										Saldo Anterior
									</Text>
									<Text style={styles.total_ingreso}>
										${saldoAnterior.map((sal) => sal.valor)}
									</Text>
								</View>
							</View>
							<View style={styles.tableCol4Header}>
								<View style={styles.section}>
									<Text style={styles.egresos}>
										Saldo Actual
									</Text>
									<Text style={styles.egresos}>
										${saldoActual}
									</Text>
								</View>
							</View>
						</View>
					</View>
					<View style={styles.table3}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol4Header}>
								<View style={styles.section}>
									<Text style={styles.egresos}>
										Total Egresos
									</Text>
									<Text style={styles.egresos}>
										${egresos.map((egre) => egre.GASTOS)}
									</Text>
								</View>
							</View>
							<View style={styles.tableCol4Header}>
								<View style={styles.section}>
									<Text style={styles.egresos}>
										Costos Bancarios
									</Text>
									<Text style={styles.egresos}>
										${costos.map((costo) => costo.VALOR)}
									</Text>
								</View>
							</View>
						</View>
					</View>
					<View style={styles.table3}>
						<View style={styles.tableRow}>
							
							<View style={styles.tableCol4Header}>
								<View style={styles.section}>
									<Text style={styles.saldo}>
										Saldo Menos (Egresos-Costos Bancarios)
									</Text>
									<Text style={styles.saldo}>
										${saldoMenosEgresos}
									</Text>
								</View>
							</View>
						</View>
					</View>
				</Page>
			</Document>
		);
		const dateValue: Date = new Date(
			new Date().getFullYear(),
			new Date().getMonth,
			20
		);
		const changefecha_desde = (event: any) => {
			const state = this.state;
			state[fecha_desde] = event.target.value;
			cookies.set("fecha_desde", state[fecha_desde], { path: "/" });

			var d = new Date(state[fecha_desde]);
			const options = {
				day: "numeric",
				month: "numeric",
				year: "numeric",
			};
			cookies.set("desde", d.toLocaleDateString("es-EC", options), {
				path: "/",
			});
		};
		const changefecha_hasta = (event: any) => {
			const state = this.state;
			state[fecha_hasta] = event.target.value;
			cookies.set("fecha_hasta", state[fecha_hasta], { path: "/" });
			var d = new Date(state[fecha_hasta]);
			const options = {
				day: "numeric",
				month: "numeric",
				year: "numeric",
			};
			cookies.set("hasta", d.toLocaleDateString("es-EC", options), {
				path: "/",
			});
		};
		return (
			<Fragment>
				<div className="row d-flex justify-content-center align-items-start h-60">
					<div className="col-md-8 col-lg-6 col-xl-7 offset-xl-1">
						<h4>Lista de Estado Financiero</h4>
						<div class="container">
							<div class="row">
								<div class="col-md-10">
									<div class="row">
										<div class="col-md-6">
											<label htmlFor="">
												Fecha Desde:
											</label>
											<div className="datepicker-control-section">
												<DatePickerComponent
													onChange={(event) => {
														changefecha_desde(
															event
														);
													}}
													dateFormat="YYYY/MM/DD"
													ref={(calendar) =>
														(this.datepickerInstance =
															calendar)
													}
													value={dateValue}
												></DatePickerComponent>
											</div>
											<br />
										</div>
										<div class="col-md-6">
											<label htmlFor="">
												Fecha Hasta:
											</label>
											<div className="datepicker-control-section">
												<DatePickerComponent
													onChange={(event) => {
														changefecha_hasta(
															event
														);
													}}
													dateFormat="YYYY/MM/DD"
													ref={(calendar) =>
														(this.datepickerInstance =
															calendar)
													}
													value={dateValue}
												></DatePickerComponent>
											</div>
											<br />
										</div>
									</div>
								</div>
								<div className="col-md-4 col-lg-6 col-xl-4">
									<button
										className="btn btn-primary"
										onClick={() =>
											this.filtrarRangoFechas()
										}
									>
										Consultar
									</button>
								</div>
							</div>
						</div>
						<table className="table table-hover">
							<caption>
								{" "}
								Ingresos Alicuotas {parseInt(total_alicuotas)}
							</caption>
							<thead className="thead-dark|thead-light">
								<tr>
									<th scope="col" colSpan="3">
										ALICUOTAS
									</th>
								</tr>
								<tr>
									<th scope="col">EFECTIVO</th>
									<th scope="col">CHEQUE</th>
									<th scope="col">DEPOSITO</th>
								</tr>
							</thead>
							<tbody>
								{ingresos.map((ingreso) => (
									<tr key={ingreso.CONCEPTO}>
										<th scope="row">
											${ingreso.alicuota_efectivo}
										</th>
										<th scope="row">
											${ingreso.alicuota_cheque}
										</th>
										<th scope="row">
											${ingreso.alicuota_deposito}
										</th>
									</tr>
								))}
							</tbody>
						</table>
						<table className="table table-hover">
							<caption>
								{" "}
								Ingresos Alquiler {parseInt(total_alquiler)}
							</caption>
							<thead className="thead-dark|thead-light">
								<tr>
									<th scope="col" colSpan="3">
										ALQUILER
									</th>
								</tr>
								<tr>
									<th scope="col">EFECTIVO</th>
									<th scope="col">CHEQUE</th>
									<th scope="col">DEPOSITO</th>
								</tr>
							</thead>
							<tbody>
								{ingresos.map((ingreso) => (
									<tr key={ingreso.CONCEPTO}>
										<th scope="row">
											${ingreso.alquiler_efectivo}
										</th>
										<th scope="row">
											${ingreso.alquiler_cheque}
										</th>
										<th scope="row">
											${ingreso.alquiler_deposito}
										</th>
									</tr>
								))}
							</tbody>
						</table>
						<table className="table table-hover">
							<caption>
								{" "}
								Ingresos Otros Pagos {parseInt(total_otros)}
							</caption>
							<thead className="thead-dark|thead-light">
								<tr>
									<th scope="col" colSpan="3">
										OTROS
									</th>
								</tr>
								<tr>
									<th scope="col">EFECTIVO</th>
									<th scope="col">CHEQUE</th>
									<th scope="col">DEPOSITO</th>
								</tr>
							</thead>
							<tbody>
								{ingresos.map((ingreso) => (
									<tr key={ingreso.CONCEPTO}>
										<th scope="row">
											${ingreso.otros_efectivo}
										</th>
										<th scope="row">
											${ingreso.otros_cheque}
										</th>
										<th scope="row">
											${ingreso.otros_deposito}
										</th>
									</tr>
								))}
							</tbody>
						</table>
						<div className="alert alert-primary">
							<strong>
								<h4>
									Total de Pagos Efectivo ${" "}
									{parseFloat(ing_efectivo).toFixed(2)}
								</h4>
							</strong>
						</div>
						<div className="alert alert-primary">
							<strong>
								<h4>
									Total de Pagos Cheque ${" "}
									{parseFloat(ing_cheque).toFixed(2)}
								</h4>
							</strong>
						</div>
						<div className="alert alert-primary">
							<strong>
								<h4>
									Total de Pagos Deposito ${" "}
									{parseFloat(ing_deposito).toFixed(2)}
								</h4>
							</strong>
						</div>
						<div className="alert alert-success">
							<strong>
								<h4>Total de Ingresos $ {total_ingresos}</h4>
							</strong>
						</div>
						<div className="alert alert-secondary">
							<strong>
								<h4>
									Total de Egresos ${" "}
									{egresos.map((egre) => egre.GASTOS)}
								</h4>
							</strong>
						</div>
					</div>
					<div className="col-md-4 col-lg-6 col-xl-4">
						<PDFViewer className="ClassName">
							<Quixote />
						</PDFViewer>
						<br />
						<PDFDownloadLink
							document={<Quixote />}
							fileName="estadoFinaciero.pdf"
						>
							{({ loading }) =>
								loading ? "Loading" : "Descargar"
							}
						</PDFDownloadLink>
						<Link
							to={"/"}
							className="btn btn-danger"
							onClick={() => this.guardarArchivo()}
						>
							Guardar
						</Link>
					</div>
				</div>

				<div className="card text-primary">
					<div className="card-body">
						<h4>Saldo</h4>
						<div className="alert alert-danger">
							<strong>
								<h4>Saldo $ {total_ingresos}</h4>
							</strong>
						</div>
					</div>
				</div>
				<div
					className="card-body"
					style={{ width: "100%", height: "500px" }}
				>
					<h2>Grafico de Estado Financiero</h2>
					<Bar data={data} options={opciones} />
				</div>
			</Fragment>
		);
	}
}
