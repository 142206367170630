import React, { Component } from "react";

import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import ListarInventario from "../inventario/ListarInventario";
import Cookies from "universal-cookie";
import { format } from "date-fns";
import {
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
} from "mdbreact";
import { loadCldr } from "@syncfusion/ej2-base";
import {
	DatePickerComponent,
	RenderDayCellEventArgs,
	ChangedEventArgs,
	CalendarComponent,
} from "@syncfusion/ej2-react-calendars";
const cookies = new Cookies();
export default class RegistrarGasto extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			comprobantes: "",
			descripcion: "",
			valor: "",
			usuario_modificacion: "",
			errores: [],
			id_inventario: "",
			fecha:"",
		};
	}
	enviarDatos = (e) => {
		e.preventDefault();
		console.log("Formulario enviado.....");
		const { id, comprobantes, descripcion, valor, usuario_modificacion,fecha } =
			this.state;
		var datosEnviar = {
			id: cookies.get("id_empresa"),
			comprobantes: comprobantes,
			descripcion: descripcion,
			valor: valor,
			usuario_modificacion: cookies.get("id"),
			fecha:cookies.get("fecha"),
		};

		var errores = [];
		if (!comprobantes) errores.push("error_comprobantes");
		if (!descripcion) errores.push("error_descripcion");
		if (!valor) errores.push("error_valor");
		this.setState({ errores: errores });
		if (errores.length > 0) return false;
		fetch(Api + "gastos.php" + "?insertar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				window.location.href = "/listarGasto";
			})
			.catch(console.log);
	};

	cambioValor = (e) => {
		const state = this.state;
		state[e.target.name] = e.target.value;

		this.setState({ state, errores: [] });
	};
	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}

	render() {
		const dateValue: Date = new Date(
			new Date().getFullYear(),
			new Date().getMonth,
			20
		);
		const changefecha = (event: any) => {
			const state = this.state;
			state[fecha] = event.target.value;
			cookies.set("fecha", state[fecha], { path: "/" });
			var d = new Date(state[fecha]);
			const options = {
				day: "numeric",
				month: "numeric",
				year: "numeric",
			};
			
		};
		const {
			comprobantes,
			valor,
			descripcion,
			inventarios,
			usuario_modificacion,
			fecha,
		} = this.state;

		return (
			<div className="card text-primary">
				<div className="card-header">
					<h4> Registrar Registrar Gastos</h4>
				</div>
				<div className="card-body">
					<form onSubmit={this.enviarDatos}>
						<div class="col-md-6">
							<label htmlFor="">Fecha:</label>
							<div className="datepicker-control-section">
								<DatePickerComponent
									onChange={(event) => {
										changefecha(event);
									}}
									dateFormat="YYYY/MM/DD"
									ref={(calendar) =>
										(this.datepickerInstance = calendar)
									}
									value={dateValue}
								></DatePickerComponent>
							</div>
							<br />
						</div>
						<div className="form-group">
							<label htmlFor="">Comprobantes:</label>
							<input
								onChange={this.cambioValor}
								value={comprobantes}
								type="text"
								name="comprobantes"
								id="comprobantes"
								className={
									(this.verificarError("error_comprobantes")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Comprobantes"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Comprobantes
							</small>
							<br />
							<label htmlFor="">Descripción:</label>
							<input
								onChange={this.cambioValor}
								value={descripcion}
								type="text"
								name="descripcion"
								id="descripcion"
								className={
									(this.verificarError("error_descripcion")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Descripción"
							/>
							<small
								id="helpDescripción"
								className="invalid-feeback"
							>
								Escribe la Descripción
							</small>

							<label htmlFor="">Valor:</label>
							<input
								pattern="^\d*(\.\d{0,2})?$"
								onChange={this.cambioValor}
								value={valor}
								type="number"
								name="valor"
								id="valor"
								className={
									(this.verificarError("error_valor")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Valor"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Valor del Registro
							</small>
						</div>
						<div
							className="btn-group"
							role="group"
							aria-label="Basic example"
						>
							<button type="submit" className="btn btn-success">
								Guardar Registro
							</button>
							<Link
								to={"/listarGasto"}
								className="btn btn-primary"
							>
								Cancelar
							</Link>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
