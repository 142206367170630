import React, { Component } from "react";

import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import ListarInventario from "../inventario/ListarInventario";
import Cookies from "universal-cookie";
import { format } from "date-fns";
import {
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
} from "mdbreact";
import { loadCldr} from '@syncfusion/ej2-base';
import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
const cookies = new Cookies();
export default class RegistrarInventario extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			codigo: "",
			nombre: "",
			descripcion: "",
			valor: "",
			usuario_modificacion: "",
			errores: [],
			id_inventario:"",
		};
	}
	enviarDatos = (e) => {
		e.preventDefault();
		console.log("Formulario enviado.....");		
		const {
			id,
			codigo,
			nombre,
			descripcion,
			valor,
			usuario_modificacion,
		} = this.state;
		var datosEnviar = {
			id: cookies.get("id_empresa"),
			codigo: codigo,
			nombre: nombre,
			descripcion: descripcion,
			valor: valor,
			usuario_modificacion: cookies.get("id"),
		};
		
		var errores = [];
		if (!codigo) errores.push("error_codigo");
		if (!nombre) errores.push("error_nombre");
		if (!descripcion) errores.push("error_descripcion");
		if (!valor) errores.push("error_valor");
		this.setState({ errores: errores });
		if (errores.length > 0) return false;
		console.log(datosEnviar);
		fetch(Api + "inventario.php" + "?insertar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				window.location.href = "/listarInventario";
			})
			.catch(console.log);
	};

	cambioValor = (e) => {
		const state = this.state;
		state[e.target.name] = e.target.value;

		this.setState({ state, errores: [] });
	};
	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}

	render() {
		
		const dateValue :Date = new Date(new Date().getFullYear(), new Date().getMonth,20);
		const {
			codigo,
			nombre,
			valor,
			descripcion,
			inventarios,
			usuario_modificacion,
		} = this.state;
	
		return (
			<div className="card text-primary">
				<div className="card-header">
					<h4> Registrar Inventario</h4>
				</div>
				<div className="card-body">
					<form onSubmit={this.enviarDatos}>
						<div className="form-group">
							
							<label htmlFor="">Código:</label>
							<input
								onChange={this.cambioValor}
								value={codigo}
								type="text"
								name="codigo"
								id="codigo"
								className={
									(this.verificarError(
										"error_codigo"
									)
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Código"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Código
							</small>
							<br />
							<label htmlFor="">Nombre:</label>
							<input
								onChange={this.cambioValor}
								value={nombre}
								type="text"
								name="nombre"
								id="nombre"
								className={
									(this.verificarError(
										"error_nombre"
									)
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Nombre"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Nombre
							</small>
							<br/>
							<label htmlFor="">Descripción:</label>
							<input
								onChange={this.cambioValor}
								value={descripcion}
								type="text"
								name="descripcion"
								id="descripcion"
								className={
									(this.verificarError(
										"error_descripcion"
									)
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Descripción"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Descripción
							</small>
							<br/>
							<label htmlFor="">Valor:</label>
							<input pattern="^\d*(\.\d{0,2})?$"
								onChange={this.cambioValor}
								value={valor}
								type="number"
								name="valor"
								id="valor"
								className={
									(this.verificarError("error_valor")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Valor"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Valor del Registro
							</small>
						</div>
						<div
							className="btn-group"
							role="group"
							aria-label="Basic example"
						>
							<button type="submit" className="btn btn-success">
								Guardar Registro
							</button>
							<Link
								to={"/listarInventario"}
								className="btn btn-primary"
							>
								Cancelar
							</Link>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
