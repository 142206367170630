import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import { format } from "date-fns";
import axios from "axios";
import Cookies from "universal-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Ripple } from "primereact/ripple";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { InputNumber } from "primereact/inputnumber";
import { ProgressBar } from "primereact/progressbar";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { Slider } from "primereact/slider";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	PDFViewer,
	PDFDownloadLink,
	Image,
} from "@react-pdf/renderer";
import {
	DatePickerComponent,
	RenderDayCellEventArgs,
	ChangedEventArgs,
	CalendarComponent,
} from "@syncfusion/ej2-react-calendars";

import { ConfirmDialog } from "primereact/confirmdialog"; // To use <ConfirmDialog> tag
import { confirmDialog } from "primereact/confirmdialog"; // To use confirmDialog method

const cookies = new Cookies();
export default class ListarAlicuota extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			id: "",
			datosCargados: true,
			alicuota: [],
			currentPage: 0,
			busqueda: "",
			first1: 0,
			rows1: 5,
			first2: 0,
			rows2: 10,
			currentPage: 1,
			pageInputTooltip: "Press 'Enter' key to go to this page.",
			filters1: null,
			filters2: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
				name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
				ID_ALICUOTA: {
					value: null,
					matchMode: FilterMatchMode.STARTS_WITH,
				},
				CASA_OFI_DEP: {
					value: null,
					matchMode: FilterMatchMode.STARTS_WITH,
				},
				FECHA_REGISTRO: {
					value: null,
					matchMode: FilterMatchMode.STARTS_WITH,
				},
				VALOR: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
				MES: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
				ESTADO_PAGO: {
					value: null,
					matchMode: FilterMatchMode.STARTS_WITH,
				},
				NUMERO_COMPROBANTE: {
					value: null,
					matchMode: FilterMatchMode.STARTS_WITH,
				},
				representative: { value: null, matchMode: FilterMatchMode.IN },
				status: { value: null, matchMode: FilterMatchMode.EQUALS },
				verified: { value: null, matchMode: FilterMatchMode.EQUALS },
			},
			globalFilterValue1: "",
			globalFilterValue2: "",
			loading1: true,
			loading2: true,
			fecha_desde: "",
			fecha_hasta: "",
			datepickerInstance: DatePickerComponent,
			visible:false,
		};
		this.clearFilter1 = this.clearFilter1.bind(this);
		this.onGlobalFilterChange1 = this.onGlobalFilterChange1.bind(this);
		this.onGlobalFilterChange2 = this.onGlobalFilterChange2.bind(this);
		this.filterClearTemplate = this.filterClearTemplate.bind(this);
		this.filterApplyTemplate = this.filterApplyTemplate.bind(this);
		this.filterFooterTemplate = this.filterFooterTemplate.bind(this);
		this.representativeFilterTemplate =
			this.representativeFilterTemplate.bind(this);
		this.dateBodyTemplate = this.dateBodyTemplate.bind(this);
		this.dateFilterTemplate = this.dateFilterTemplate.bind(this);
		this.balanceBodyTemplate = this.balanceBodyTemplate.bind(this);
		this.balanceFilterTemplate = this.balanceFilterTemplate.bind(this);
		this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
		this.statusFilterTemplate = this.statusFilterTemplate.bind(this);
		this.statusItemTemplate = this.statusItemTemplate.bind(this);
		this.activityBodyTemplate = this.activityBodyTemplate.bind(this);
		this.activityFilterTemplate = this.activityFilterTemplate.bind(this);
		this.verifiedBodyTemplate = this.verifiedBodyTemplate.bind(this);
		this.verifiedFilterTemplate = this.verifiedFilterTemplate.bind(this);
		this.representativeRowFilterTemplate =
			this.representativeRowFilterTemplate.bind(this);
		this.statusRowFilterTemplate = this.statusRowFilterTemplate.bind(this);
		this.verifiedRowFilterTemplate =
			this.verifiedRowFilterTemplate.bind(this);
	}

	cargarDatos() {
		const { id, fecha_desde, fecha_hasta } = this.state;
		var datosEnviar = {
			id: cookies.get("id_empresa"),
			fecha_desde: cookies.get("fecha_desde"),
			fecha_hasta: cookies.get("fecha_hasta"),
		};
		// console.log(datosEnviar);
		fetch(Api + "registroAlicuotas.php" + "?listar", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				// console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					alicuota: datosRespuesta,
				});
			})
			.catch(console.log);
	}

	borrarRegistro = (ID_ALICUOTA) => {
		fetch(Api + "registroAlicuotas.php" + "?borrar=" + cookies.get("alicuota"))
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				// console.log(datosRespuesta);
				this.cargarDatos();
				window.location.href = "/ListarAlicuota";
			})
			.catch(console.log);
	};

confirmar = (ID_ALICUOTA,NUMEROC,VALOR) => {
this.setState({visible: !this.state.visible});
cookies.set("alicuota",ID_ALICUOTA);
cookies.set("numeroC",NUMEROC);
cookies.set("valorA",VALOR);
}
cancelar=(v)=>{
this.setState({visible: !this.state.visible});
}

	formatear(date) {
		const formattedDate = format(date, "MMMM , yyyy");
		// console.log(formattedDate);
	}
	jsonEncoder(alicuotas) {
		alicuotas = JSON.stringify(alicuotas);
	}

	formatCurrency(value) {
		return value.toLocaleString("en-US", {
			style: "currency",
			currency: "USD",
		});
	}

	clearFilter1() {
		this.initFilters1();
	}

	onGlobalFilterChange1(e) {
		const value = e.target.value;
		let filters1 = { ...this.state.filters1 };
		filters1["global"].value = value;

		this.setState({ filters1, globalFilterValue1: value });
	}

	onGlobalFilterChange2(e) {
		const value = e.target.value;
		let filters2 = { ...this.state.filters2 };
		filters2["global"].value = value;

		this.setState({ filters2, globalFilterValue2: value });
	}

	initFilters1() {
		this.setState({
			filters1: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
				name: {
					operator: FilterOperator.AND,
					constraints: [
						{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
					],
				},
				ID_ALICUOTA: {
					operator: FilterOperator.AND,
					constraints: [
						{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
					],
				},
				CASA_OFI_DEP: {
					operator: FilterOperator.AND,
					constraints: [
						{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
					],
				},
				fecha_registro: {
					operator: FilterOperator.AND,
					constraints: [
						{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
					],
				},
				VALOR: {
					operator: FilterOperator.AND,
					constraints: [
						{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
					],
				},

				date: {
					operator: FilterOperator.AND,
					constraints: [
						{ value: null, matchMode: FilterMatchMode.DATE_IS },
					],
				},
				balance: {
					operator: FilterOperator.AND,
					constraints: [
						{ value: null, matchMode: FilterMatchMode.EQUALS },
					],
				},
				status: {
					operator: FilterOperator.OR,
					constraints: [
						{ value: null, matchMode: FilterMatchMode.EQUALS },
					],
				},
				activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
				verified: { value: null, matchMode: FilterMatchMode.EQUALS },
			},
			globalFilterValue1: "",
		});
	}

	renderHeader1() {
		return (
			<div className="p-d-flex p-jc-between">
				<Button
					type="button"
					icon="pi pi-filter-slash"
					label="Clear"
					className="p-button-outlined"
					onClick={this.clearFilter1}
				/>
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText
						value={this.state.globalFilterValue1}
						onChange={this.onGlobalFilterChange1}
						placeholder="Busqueda por palabra"
					/>
				</span>
			</div>
		);
	}

	renderHeader2() {
		return (
			<div className="p-d-flex p-jc-end">
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText
						value={this.state.globalFilterValue2}
						onChange={this.onGlobalFilterChange2}
						placeholder="Busqueda por palabra"
					/>
				</span>
			</div>
		);
	}

	countryBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="image-text">{rowData.ID_ALICUOTA}</span>
			</React.Fragment>
		);
	}

	filterClearTemplate(options) {
		return (
			<Button
				type="button"
				icon="pi pi-times"
				onClick={options.filterClearCallback}
				className="p-button-secondary"
			></Button>
		);
	}

	filterApplyTemplate(options) {
		return (
			<Button
				type="button"
				icon="pi pi-check"
				onClick={options.filterApplyCallback}
				className="p-button-success"
			></Button>
		);
	}

	filterFooterTemplate() {
		return (
			<div className="p-px-3 p-pt-0 p-pb-3 p-text-center p-text-bold">
				Customized Buttons
			</div>
		);
	}

	representativeBodyTemplate(rowData) {
		const representative = rowData.representative;
		return (
			<React.Fragment>
				<img
					alt={representative.name}
					src={`showcase/demo/images/avatar/${representative.image}`}
					onError={(e) =>
						(e.target.src =
							"https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
					}
					width={32}
					style={{ verticalAlign: "middle" }}
				/>
				<span className="image-text">{representative.name}</span>
			</React.Fragment>
		);
	}

	representativeFilterTemplate(options) {
		return (
			<MultiSelect
				value={options.value}
				options={this.representatives}
				itemTemplate={this.representativesItemTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				optionLabel="name"
				placeholder="Any"
				className="p-column-filter"
			/>
		);
	}

	representativesItemTemplate(option) {
		return (
			<div className="p-multiselect-representative-option">
				<img
					alt={option.name}
					src={`showcase/demo/images/avatar/${option.image}`}
					onError={(e) =>
						(e.target.src =
							"https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
					}
					width={32}
					style={{ verticalAlign: "middle" }}
				/>
				<span className="image-text">{option.name}</span>
			</div>
		);
	}

	dateBodyTemplate(rowData) {
		return this.formatDate(rowData.date);
	}

	dateFilterTemplate(options) {
		return (
			<Calendar
				value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				dateFormat="mm/dd/yy"
				placeholder="mm/dd/yyyy"
				mask="99/99/9999"
			/>
		);
	}

	balanceBodyTemplate(rowData) {
		return this.formatCurrency(rowData.balance);
	}

	balanceFilterTemplate(options) {
		return (
			<InputNumber
				value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				mode="currency"
				currency="USD"
				locale="en-US"
			/>
		);
	}

	statusBodyTemplate(rowData) {
		return (
			<span className={`customer-badge status-${rowData.status}`}>
				{rowData.status}
			</span>
		);
	}

	statusFilterTemplate(options) {
		return (
			<Dropdown
				value={options.value}
				options={this.statuses}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				itemTemplate={this.statusItemTemplate}
				placeholder="Select a Status"
				className="p-column-filter"
				showClear
			/>
		);
	}

	statusItemTemplate(option) {
		return (
			<span className={`customer-badge status-${option}`}>{option}</span>
		);
	}

	activityBodyTemplate(rowData) {
		return (
			<ProgressBar
				value={rowData.activity}
				showValue={false}
			></ProgressBar>
		);
	}

	activityFilterTemplate(options) {
		return (
			<React.Fragment>
				<Slider
					value={options.value}
					onChange={(e) => options.filterCallback(e.value)}
					range
					className="p-m-3"
				></Slider>
				<div className="p-d-flex p-ai-center p-jc-between p-px-2">
					<span>{options.value ? options.value[0] : 0}</span>
					<span>{options.value ? options.value[1] : 100}</span>
				</div>
			</React.Fragment>
		);
	}

	verifiedBodyTemplate(rowData) {
		return (
			<i
				className={classNames("pi", {
					"true-icon pi-check-circle": rowData.verified,
					"false-icon pi-times-circle": !rowData.verified,
				})}
			></i>
		);
	}

	verifiedFilterTemplate(options) {
		return (
			<TriStateCheckbox
				value={options.value}
				onChange={(e) => options.filterCallback(e.value)}
			/>
		);
	}

	representativeRowFilterTemplate(options) {
		return (
			<MultiSelect
				value={options.value}
				options={this.representatives}
				itemTemplate={this.representativesItemTemplate}
				onChange={(e) => options.filterApplyCallback(e.value)}
				optionLabel="name"
				placeholder="Any"
				className="p-column-filter"
				maxSelectedLabels={1}
			/>
		);
	}

	statusRowFilterTemplate(options) {
		return (
			<Dropdown
				value={options.value}
				options={this.statuses}
				onChange={(e) => options.filterApplyCallback(e.value)}
				itemTemplate={this.statusItemTemplate}
				placeholder="Select a Status"
				className="p-column-filter"
				showClear
			/>
		);
	}

	verifiedRowFilterTemplate(options) {
		return (
			<TriStateCheckbox
				value={options.value}
				onChange={(e) => options.filterApplyCallback(e.value)}
			/>
		);
	}

	actionTemplate(rowData, column) {
		// console.log("column", column);
		// console.log("rowData", rowData);
		return (
			<div
				className={
					(cookies.get("perfil") == "DIRECTIVOS" ? "d-none" : "") +
					" btn-group"
				}
				role="group"
				aria-label="Basic example"
			>
				<Link
					to={"/editarAlicuota/" + rowData.ID_ALICUOTA}
					className="btn btn-warning"
				>
					Editar
				</Link>
				
				<Button color="danger" onClick={() =>this.confirmar(rowData.ID_ALICUOTA,rowData.NUMERO_COMPROBANTE,rowData.VALOR)}>Eliminar</Button>
				<Link
					to={"/impresion/" + rowData.ID_ALICUOTA}
					className="btn btn-success"
				>
					Imprimir
				</Link>
				{rowData.ID_PAGO_CONSOLIDADO != null ? (
					<Link
						to={"/impresionConsolidada/" + rowData.ID_ALICUOTA}
						className="btn btn-info"
					>
						Impresión Consolidada
					</Link>
				) : (
					""
				)}
			</div>
		);
	}
	
	render() {
		const modalStyles={
      position: "absolute",
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
		const confirm = (ID_ALICUOTA) => {
			confirmDialog({
				message: "Are you sure you want to proceed?",
				header: "Confirmation",
				icon: "pi pi-exclamation-triangle",
				accept: () => this.borrarRegistro(ID_ALICUOTA),				
			});
		};
		const header1 = this.renderHeader1();
		const header2 = this.renderHeader2();
		const date = new Date("2016-01-04 10:34:23");

		const formattedDate = format(date, "MMMM , yyyy");

		// console.log(formattedDate);
		const {
			datosCargados,
			alicuota,
			currentPage,
			busqueda,
			fecha_desde,
			fecha_hasta,
			visible,
		} = this.state;
		const filtro = alicuota.slice(currentPage, currentPage + 5);

		const nextPage = () => {
			this.setState({ currentPage: currentPage + 5 });
		};
		const previusPage = () => {
			if (currentPage > 0) {
				this.setState({ currentPage: currentPage - 5 });
			}
		};
		const buscar = (e) => {
			this.setState({ busqueda: e.target.value });
			// console.log("busqueda:" + e.target.value);
			filtrar(e.target.value);
		};

		const filtrar = (termino) => {
			if (termino.length === 0) {
				this.cargarDatos();
			} else {
				var resultadoBusqueda = alicuota.filter((elemento) => {
					if (
						elemento.APELLIDO.toString()
							.toUpperCase()
							.includes(termino.toUpperCase()) ||
						elemento.NOMBRE.toString()
							.toUpperCase()
							.includes(termino.toUpperCase()) ||
						elemento.CASA_OFI_DEP.toString()
							.toUpperCase()
							.includes(termino.toUpperCase())
					) {
						return elemento;
					}
				});
				this.setState({ alicuota: resultadoBusqueda });
			}
		};
		const editarRegistro = (rowData) => {
			window.location.href = "/editarAlicuota/" + rowData.ID_ALICUOTA;
		};

		const imprimir = (rowData) => {
			window.location.href = "/impresion/" + rowData.ID_ALICUOTA;
		};
		const BORDER_COLOR = "#bfbfbf";
		const BORDER_STYLE = "solid";
		const COL1_WIDTH = 40;
		const COL2_WIDTH = 85;
		const COLN_WIDTH = (100 - COL1_WIDTH) / 3;

		const columnas = [
			{
				title: "Id",
				field: "ID_ALICUOTA",
			},
			{
				title: "Condomino",
				field: "NOMBRE",
			},
			{
				title: "Casa-Departamento-Oficina",
				field: "CASA_OFI_DEP",
			},
			{
				title: "Número de Comprobante",
				field: "NUMERO_COMPROBANTE",
			},
			{
				title: "Mes",
				field: "MES",
			},
			{
				title: "Estado Pago",
				field: "ESTADO_PAGO",
			},
		];
		const paginatorLeft = <Button type="button" icon="pi pi-refresh" />;
		const paginatorRight = <Button type="button" icon="pi pi-cloud" />;
		const template1 = {
			layout: "PrevPageLink PageLinks NextPageLink",
			PrevPageLink: (options) => {
				return (
					<button
						type="button"
						className={options.className}
						onClick={options.onClick}
						disabled={options.disabled}
					>
						<span className="p-p-3">Anterior</span>
						<Ripple />
					</button>
				);
			},
			NextPageLink: (options) => {
				return (
					<button
						type="button"
						className={options.className}
						onClick={options.onClick}
						disabled={options.disabled}
					>
						<span className="p-p-3">Siguiente</span>
						<Ripple />
					</button>
				);
			},
			PageLinks: (options) => {
				if (
					(options.view.startPage === options.page &&
						options.view.startPage !== 0) ||
					(options.view.endPage === options.page &&
						options.page + 1 !== options.totalPages)
				) {
					const className = classNames(options.className, {
						"p-disabled": true,
					});

					return (
						<span
							className={className}
							style={{ userSelect: "none" }}
						>
							...
						</span>
					);
				}

				return (
					<button
						type="button"
						className={options.className}
						onClick={options.onClick}
					>
						{options.page + 1}
						<Ripple />
					</button>
				);
			},
		};

		const template2 = {
			layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
			RowsPerPageDropdown: (options) => {
				const dropdownOptions = [
					{ label: 10, value: 10 },
					{ label: 20, value: 20 },
					{ label: 50, value: 50 },
				];

				return (
					<React.Fragment>
						<span
							className="p-mx-1"
							style={{
								color: "var(--text-color)",
								userSelect: "none",
							}}
						>
							Items per page:{" "}
						</span>
						<Dropdown
							value={options.value}
							options={dropdownOptions}
							onChange={options.onChange}
							appendTo={document.body}
						/>
					</React.Fragment>
				);
			},
			CurrentPageReport: (options) => {
				return (
					<span
						style={{
							color: "var(--text-color)",
							userSelect: "none",
							width: "120px",
							textAlign: "center",
						}}
					>
						{options.first} - {options.last} of{" "}
						{options.totalRecords}
					</span>
				);
			},
		};
		const dateValue: Date = new Date(
			new Date().getFullYear(),
			new Date().getMonth,
			20
		);
		const changefecha_desde = (event: any) => {
			const state = this.state;
			state[fecha_desde] = event.target.value;
			cookies.set("fecha_desde", state[fecha_desde], { path: "/" });

			var d = new Date(state[fecha_desde]);
			const options = {
				day: "numeric",
				month: "numeric",
				year: "numeric",
			};
			cookies.set("desde", d.toLocaleDateString("es-EC", options), {
				path: "/",
			});
		};
		const changefecha_hasta = (event: any) => {
			const state = this.state;
			state[fecha_hasta] = event.target.value;
			cookies.set("fecha_hasta", state[fecha_hasta], { path: "/" });
			var d = new Date(state[fecha_hasta]);
			const options = {
				day: "numeric",
				month: "numeric",
				year: "numeric",
			};
			cookies.set("hasta", d.toLocaleDateString("es-EC", options), {
				path: "/",
			});
		};
		if (!datosCargados) {
			return (
				<div className="alert alert-info">
					<p>Cargando....</p>
				</div>
			);
		} else {
			return (
				<Fragment>
					<div className="card text-primary">
						<div
							className={
								(cookies.get("perfil") == "DIRECTIVOS"
									? "d-none"
									: "") + " card-header"
							}
						>
							<Link
								to={"/registrarAlicuota"}
								className="btn btn-success"
							>
								Agregar Nuevo Registro
							</Link>
							<Link
								to={"/pagoConsolidado"}
								className="btn btn-info"
							>
								Pago Consolidado
							</Link>
						</div>

						<div>
							<div className="card">
								<h5>Listado de Alicuotas</h5>
								<div class="container">
									<div class="row">
										<div class="col-md-10">
											<div class="row">
												<div class="col-md-6">
													<label htmlFor="">
														Fecha Desde:
													</label>
													<div className="datepicker-control-section">
														<DatePickerComponent
															onChange={(
																event
															) => {
																changefecha_desde(
																	event
																);
															}}
															dateFormat="YYYY/MM/DD"
															ref={(calendar) =>
																(this.datepickerInstance =
																	calendar)
															}
															value={dateValue}
														></DatePickerComponent>
													</div>
													<br />
												</div>
												<div class="col-md-6">
													<label htmlFor="">
														Fecha Hasta:
													</label>
													<div className="datepicker-control-section">
														<DatePickerComponent
															onChange={(
																event
															) => {
																changefecha_hasta(
																	event
																);
															}}
															dateFormat="YYYY/MM/DD"
															ref={(calendar) =>
																(this.datepickerInstance =
																	calendar)
															}
															value={dateValue}
														></DatePickerComponent>
													</div>
													<br />
												</div>
											</div>
										</div>
										<div className="col-md-4 col-lg-6 col-xl-4">
											<button
												className="btn btn-primary"
												onClick={() =>
													this.cargarDatos()
												}
											>
												Consultar
											</button>
										</div>
									</div>
								</div>
								<DataTable
									responsiveLayout="stack"
									breakpoint="800px"
									value={this.state.alicuota}
									responsiveLayout="scroll"
									paginator
									paginatorTemplate={template1}
									first={this.state.first1}
									rows={this.state.rows1}
									onPage={this.onCustomPage1}
									filters={this.state.filters2}
									filterDisplay="row"
									loading={this.state.loading2}
									globalFilterFields={[
										"ID_ALICUOTA",
										"ESTADO_PAGO",
										"CASA_OFI_DEP",
										"MES",
										"VALOR",
									]}
									header={header2}
									emptyMessage="No customers found."
								>
									<Column
										field="ID_ALICUOTA"
										header="Código"
										width="15%"
									></Column>
									<Column
										width="15%"
										filter
										filterField="CASA_OFI_DEP"
										field="CASA_OFI_DEP"
										filterPlaceholder="Buscar por CASA_OFI_DEP"
										header="Casa-Departamento-Oficina"
									></Column>
									<Column
										field="DESCRIPCION"
										header="Concepto"
									></Column>
									<Column
										width="35%"
										filterField="FECHA_REGISTRO"
										filter
										filterPlaceholder="Buscar por Fecha"
										field="FECHA_REGISTRO"
										header="Fecha Registro"
									></Column>
									<Column
										filterField="MES"
										style={{ minWidth: "8rem" }}
										filter
										filterPlaceholder="Buscar por valor"
										field="MES"
										header="Mes"
									></Column>
									<Column
										width="35%"
										filter
										filterField="NUMERO_COMPROBANTE"
										field="NUMERO_COMPROBANTE"
										filterPlaceholder="Buscar por #Comprobante"
										header="# Comprobante"
									></Column>
									<Column
										width="15%"
										filterField="VALOR"
										filter
										filterPlaceholder="Buscar por valor"
										field="VALOR"
										header="Valor"
									></Column>
									<Column
										filterField="ESTADO_PAGO"
										filter
										filterPlaceholder="Buscar por mes"
										field="ESTADO_PAGO"
										header="Estado"
									></Column>
									<Column
										body={this.actionTemplate.bind(this)}
										header="Acciones"
										style={{
											textAlign: "center",
											width: "6em",
										}}
									/>
								</DataTable>

 <Modal isOpen={this.state.visible} style={modalStyles}>
 <div className="alert alert-danger">
        <ModalHeader>
          Esta Seguro que desea eliminar el registro!
        </ModalHeader>
        </div>
        <ModalBody>
        <div className="alert alert-info">
          <FormGroup>
            <Label for="usuario"># Comprobante {cookies.get("numeroC")}</Label>
           
          </FormGroup>
          <FormGroup>
            <Label for="usuario">Valor ${cookies.get("valorA")}</Label>
           
          </FormGroup>
          </div>
          <div className="alert alert-danger">
							<strong>
								<h4>
            Si el registro ya tiene número de comprobante perdera la secuencia, edite o pregunte antes de eliminar!
           </h4>
         </strong>
         
         </div>
        </ModalBody>

        <ModalFooter>
            <Button color="primary" onClick={this.borrarRegistro}>Aceptar</Button>
            <Button color="secondary" onClick={this.cancelar}>Cerrar</Button>
        </ModalFooter>
      </Modal>
							</div>
						</div>
					</div>
				</Fragment>
			);
		}
	}
}
