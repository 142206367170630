import React , { Fragment } from "react";
import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import Cookies from "universal-cookie";
import { Accordion, AccordionTab } from 'primereact/accordion';
import 'bootstrap/dist/css/bootstrap.css';
import {
	DatePickerComponent,
	RenderDayCellEventArgs,
	ChangedEventArgs,
	CalendarComponent,
} from "@syncfusion/ej2-react-calendars";
const cookies = new Cookies();
export default class ListarCaja extends React.Component {
	constructor(props) {
		super(props);

		this.state = {

			datosCargados: false,
			ingresos: [],
		};
	}

	cargarDatos() {
		const { id, fecha_desde, fecha_hasta } = this.state;
		var datosEnviar = {
			id: cookies.get("id_empresa"),
			fecha_desde: cookies.get("fecha_desde"),
			fecha_hasta: cookies.get("fecha_hasta"),
		};
		
		fetch(Api + "cajaChica.php" + "?listarIngresos", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					ingresos: datosRespuesta,
				});
			})
			.catch(console.log);
	}

	componentDidMount() {
		this.cargarDatos();
	}

borrarRegistro = (ID_C_VEHICULAR) => {
		fetch(Api +"controlVehicular.php" +"?borrar=" + ID_C_VEHICULAR)
	.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				
				
				this.cargarDatos();
				window.location.href = "/ListaControlV";
			})
			.catch(console.log);
	};
	render() {
		const { datosCargados, ingresos,fecha_desde,fecha_hasta} = this.state;
		const dateValue: Date = new Date(
			new Date().getFullYear(),
			new Date().getMonth,
			20
		);
		const changefecha_desde = (event: any) => {
			const state = this.state;
			state[fecha_desde] = event.target.value;
			cookies.set("fecha_desde", state[fecha_desde], { path: "/" });

			var d = new Date(state[fecha_desde]);
			const options = {
				day: "numeric",
				month: "numeric",
				year: "numeric",
			};
			cookies.set("desde", d.toLocaleDateString("es-EC", options), {
				path: "/",
			});
		};
		const changefecha_hasta = (event: any) => {
			const state = this.state;
			state[fecha_hasta] = event.target.value;
			cookies.set("fecha_hasta", state[fecha_hasta], { path: "/" });
			var d = new Date(state[fecha_hasta]);
			const options = {
				day: "numeric",
				month: "numeric",
				year: "numeric",
			};
			cookies.set("hasta", d.toLocaleDateString("es-EC", options), {
				path: "/",
			});
		};
		return (
			<Fragment>
					<div className="card text-primary">
						
						<Accordion activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
    <AccordionTab header="Ingresos">

    <div className={(cookies.get("perfil") == 'DIRECTIVOS' ? "d-none" : "") +" card-header"}>
							<Link to={"/crearIngresoCaja"} className="btn btn-success">
								Agregar Nuevo Registro
							</Link>
						</div>
        <div class="container">
									<div class="row">
										<div class="col-md-10">
											<div class="row">
												<div class="col-md-6">
													<label htmlFor="">
														Fecha Desde:
													</label>
													<div className="datepicker-control-section">
														<DatePickerComponent
															onChange={(
																event
															) => {
																changefecha_desde(
																	event
																);
															}}
															dateFormat="YYYY/MM/DD"
															ref={(calendar) =>
																(this.datepickerInstance =
																	calendar)
															}
															value={dateValue}
														></DatePickerComponent>
													</div>
													<br />
												</div>
												<div class="col-md-6">
													<label htmlFor="">
														Fecha Hasta:
													</label>
													<div className="datepicker-control-section">
														<DatePickerComponent
															onChange={(
																event
															) => {
																changefecha_hasta(
																	event
																);
															}}
															dateFormat="YYYY/MM/DD"
															ref={(calendar) =>
																(this.datepickerInstance =
																	calendar)
															}
															value={dateValue}
														></DatePickerComponent>
													</div>
													<br />
												</div>
											</div>
										</div>
										<div className="col-md-4 col-lg-6 col-xl-4">
											<button
												className="btn btn-primary"
												onClick={() =>
													this.cargarDatos()
												}
											>
												Consultar
											</button>
										</div>
									</div>
								</div>
        <div className="card-body">
							<h4>Lista de Ingresos Caja Chica</h4>
							<table className="table table-hover">
								<caption>Lista de Ingresos Caja Chica</caption>
								<thead className="thead-dark|thead-light">
									<tr>
										<th scope="col">Id</th>
										<th scope="col" >Fecha</th>										
										<th scope="col">Valor</th>
										<th scope="col">Descripción</th>										
									</tr>
								</thead>
								<tbody>
									{ingresos.map((ingreso) => (
										<tr key={ingreso.id}>
											<th scope="row">{ingreso.id}</th>
											<td>{ingreso.fecha}</td>
											<td>{ingreso.valor}</td>
											<td>{ingreso.descripcion}</td>
												<td>
												{" "}
												<div
													className={(cookies.get("perfil") == 'DIRECTIVOS' ? "d-none" : "") +" btn-group"}
													role="group"
													aria-label="Basic example"
												>
													<Link
														to={"/editarControlV/" + ingreso.ID_C_VEHICULAR}
														className="btn btn-warning"
													>
														Editar
													</Link>

													<Link
														to={"/"}
														className="btn btn-danger"
														onClick={() =>
															this.borrarRegistro(ingreso.ID_C_VEHICULAR)
														}
													>
														Borrar
													</Link>
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
    </AccordionTab>
    <AccordionTab header="Egresos">
        Egresos
    </AccordionTab>
    <AccordionTab header="Saldo">
       Saldo
    </AccordionTab>
</Accordion>
						
					</div>

				</Fragment>
		);
	}
}
