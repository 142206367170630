import React from "react";
import Api from "../servicios/Api";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import {
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
} from "mdbreact";
const cookies = new Cookies();
export default class EditarComprobante extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			comprobante: [],
			errores: [],		
		};
		this.cambioValor = this.cambioValor.bind(this);
	}

	
	cambioValor = (e) => {
		const state = this.state.comprobante;
		state[e.target.name] = e.target.value;
		this.setState({ comprobante: state, errores: [] });
	};
	enviarDatos = (e) => {
		e.preventDefault();
		
		const {ID_COMPROBANTE, NUMERO_COMPROBANTE, USUARIO_MODIFICACION } =
			this.state.comprobante;
		
			var datosEnviar = {
				ID_COMPROBANTE:ID_COMPROBANTE,
				NUMERO_COMPROBANTE: NUMERO_COMPROBANTE,
				USUARIO_MODIFICACION: cookies.get("id"),
			};
		
		
		var errores = [];
		if (!NUMERO_COMPROBANTE) errores.push("error_comprobantes");
		this.setState({ errores: errores });

		if (errores.length > 0) return false;
		console.log(datosEnviar);
		fetch(Api + "comprobante.php" + "?actualizar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
			
		})
		
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				
				window.location.href = "/listarComprobante";
			})
			.catch(console.log);
	};
	componentDidMount() {
		
		fetch(
			Api +
				"comprobante.php" +
				"?consultar=" +
				this.props.match.params.id
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				
				this.setState({
					datosCargados: true,
					comprobante: datosRespuesta[0],
				});
			})
			.catch(console.log);
	}

	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}
	render() {
		const { datosCargados, comprobante, } =
			this.state;
		
		let selectedOptionId = 0;
		return (
			<div className="card text-primary|secondary|success|danger|warning|info|light|dark bg-primary|secondary|success|danger|warning|info|light|dark">
				<div className="card-header">Edición de Comprobante</div>
				<div className="card-body">
					<form onSubmit={this.enviarDatos}>
						<div className="form-group ">
							<label htmlFor="">Id</label>
							<input
								onChange={this.cambioValor}
								readOnly
								type="text"
								className="form-control"
								name="ID_COMPROBANTE"
								id="ID_COMPROBANTE"
								value={comprobante.ID_COMPROBANTE}
							/>
						</div>

						<div className="form-group ">
							<label htmlFor="">
								Número de Comprobante Actual
							</label>
							<div>
								<div className="alert alert-info">
									<strong>
										{comprobante.NUMERO_COMPROBANTE}
									
									</strong>
								</div>
							</div>
						</div>

						<div className="form-group">
							

							<label htmlFor="">Número de Comprobante:</label>
							<input
								onChange={this.cambioValor}
								value={comprobante.NUMERO_COMPROBANTE}
								type="number"
								name="NUMERO_COMPROBANTE"
								id="NUMERO_COMPROBANTE"
								className={
									(this.verificarError("error_codigo")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Número de Comprobante"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Número de Comprobante.
							</small>
							<br />
							
						</div>
						<div className="alert alert-danger">
									<strong>
										El número de comprobante corresponde al secuencial, de generación
										de los recibos, la edición se deberá realizar luego de eliminar
										un registro de alicuota en estado pagado. 
										<br/>
										!Bajo su propia responsabilidad!										
									</strong>
								</div>
						<div
							className="btn-group"
							role="group"
							aria-label="Basic example"
						>
							<button type="submit" className="btn btn-success">
								Guardar Comprobante
							</button>
							<Link
								to={"/listaComprobante"}
								className="btn btn-primary"
							>
								Cancelar
							</Link>
						</div>
					</form>
				</div>
			
			</div>
		);
	}
}
