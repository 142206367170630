import React from "react";
import Api from "../servicios/Api";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
const cookies = new Cookies();
export default class EditarInventario extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			inventario: [],
			errores: [],
		};
		this.cambioValor = this.cambioValor.bind(this);
	}

	cambioValor = (e) => {
		const state = this.state.inventario;
		state[e.target.name] = e.target.value;
		this.setState({ inventario: state, errores: [] });
	};
	enviarDatos = (e) => {
		e.preventDefault();
		console.log("Formulario enviado.....");
		const { ID_INVENTARIO, CODIGO, NOMBRE, DESCRIPCION, VALOR, USUARIO_MODIFICACION } =
			this.state.inventario;

		var datosEnviar = {
			ID_INVENTARIO: ID_INVENTARIO,
			CODIGO: CODIGO,
			NOMBRE: NOMBRE,
			DESCRIPCION: DESCRIPCION,
			VALOR: VALOR,			
			USUARIO_MODIFICACION: cookies.get("id"),
		};

		var errores = [];
		if (!CODIGO) errores.push("error_codigo");
		if (!NOMBRE) errores.push("error_nombre");
		this.setState({ errores: errores });

		if (errores.length > 0) return false;
		fetch(Api + "inventario.php" + "?actualizar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				window.location.href = "/listarInventario";
			})
			.catch(console.log);
	};
	componentDidMount() {
		console.log(this.props.match.params.id);
		fetch(
			Api +
				"inventario.php" +
				"?consultar=" +
				this.props.match.params.id
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					inventario: datosRespuesta[0],
				});
			})
			.catch(console.log);		
	}

	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}
	render() {
		
		
		const { datosCargados, inventario, mes } =
			this.state;
		
		return (
			<div className="card text-primary|secondary|success|danger|warning|info|light|dark bg-primary|secondary|success|danger|warning|info|light|dark">
				<div className="card-header">Featured</div>
				<div className="card-body">
					<form onSubmit={this.enviarDatos}>
						<div className="form-group ">
							<label htmlFor="">Id</label>
							<input
								onChange={this.cambioValor}
								readOnly
								type="text"
								className="form-control"
								name="ID_INVENTARIO"
								id="ID_INVENTARIO"
								value={inventario.ID_INVENTARIO}
							/>
						</div>

						<div className="form-group">
						
							<label htmlFor="">Código</label>
							<input
								onChange={this.cambioValor}
								value={inventario.CODIGO}
								type="text"
								name="CODIGO"
								id="CODIGO"
								className={
									(this.verificarError(
										"error_codigo"
									)
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Código"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Nombre
							</small>
							<br/>
							<label htmlFor="">Nombre</label>
							<input
								onChange={this.cambioValor}
								value={inventario.NOMBRE}
								type="text"
								name="NOMBRE"
								id="NOMBRE"
								className={
									(this.verificarError(
										"error_numero"
									)
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Nombre"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Nombre
							</small>
							<br />
							<label htmlFor="">Descripción</label>
							<input
								onChange={this.cambioValor}
								value={inventario.DESCRIPCION}
								type="text"
								name="DESCRIPCION"
								id="DESCRIPCION"
								className={
									(this.verificarError(
										"error_numero"
									)
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Descripción"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Descripción
							</small>
							<br />							
							<label htmlFor="">Valor:</label>
							<input
								pattern="^\d*(\.\d{0,2})?$"
								onChange={this.cambioValor}
								value={inventario.VALOR}
								type="number"
								name="VALOR"
								id="VALOR"
								className={
									(this.verificarError("error_valor")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Valor"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Valor del Registro
							</small>
						</div>
						<div
							className="btn-group"
							role="group"
							aria-label="Basic example"
						>
							<button type="submit" className="btn btn-success">
								Guardar Registro
							</button>
							<Link
								to={"/listarinventario"}
								className="btn btn-primary"
							>
								Cancelar
							</Link>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
