import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import ListaCop from "../copropietario/ListaCop";
import Imagen from "../login/draw2.svg";
import Session from "react-session-api";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import Login from "../login/Login"
const cookies = new Cookies();
export default class Inicio extends React.Component {
      componentDidMount() {
            const counter = (data) => {
                  this.setState({ counter: data["counter"] });
            };

            console.log(cookies.get("id"));
            console.log(cookies.get("id_empresa"));
      }

      render() {
            return (
                  <Router>
                        <Route
                              exact
                              path="/login"
                              render={() => <Login />}
                        ></Route>
                        <section className="vh-100">
                              <div className="container-fluid h-custom">
                                    <div className="row d-flex justify-content-center align-items-start h-100">
                                    
                                          <div className="col-md-4 col-lg-6 col-xl-4">
                                          
                                                <img
                                                      src={Imagen}
                                                      className="img-fluid"
                                                      alt="Sample image"
                                                />
                                          </div>
                                          <div className="col-md-8 col-lg-6 col-xl-7 offset-xl-1">
                                                <div className="text-primary col-sm-9">
                                                      <h2>
                                                            <small>
                                                                  <span
                                                                        style={{
                                                                              color: "#f4511e",
                                                                        }}
                                                                  >
                                                                        CONDOMINO'S
                                                                  </span>
                                                            </small>
                                                      </h2>
                                                      <hr />
                                                      <h3>
                                                            Te brinda todo lo
                                                            que necesitas para
                                                            tener la
                                                            administración de tu
                                                            condominio o
                                                            edificio online.
                                                      </h3>
                                                      <br />
                                                      <h5>
                                                            <span className="label label-danger">
                                                                  La gestión de
                                                                  propiedad
                                                                  horizontal
                                                                  consiste en la
                                                                  planificación,
                                                                  organización,
                                                                  dirección,
                                                                  ejecución y
                                                                  control de las
                                                                  diversas
                                                                  actividades
                                                                  relacionadas
                                                                  con la
                                                                  existencia y
                                                                  uso de un bien
                                                                  inmobiliario.
                                                            </span>{" "}
                                                      </h5>
                                                      <br />
                                                      <p></p>

                                                      <h2>
                                                            <small>
                                                                  <span
                                                                        style={{
                                                                              color: "#f4511e",
                                                                        }}
                                                                  >
                                                                        Confiable
                                                                        y Seguro
                                                                  </span>
                                                            </small>
                                                      </h2>
                                                      <hr />
                                                      <h3>
                                                            La información y la
                                                            base de datos se
                                                            encuentra
                                                            completamente
                                                            protegida. Esto,
                                                            porque se contemplan
                                                            perfiles de usuario
                                                            con diversos niveles
                                                            de autorización y
                                                            con métodos de
                                                            autenticación de
                                                            identidad. Así mismo,
                                                            están protegidos
                                                            contra ataques
                                                            cibernéticos.
                                                      </h3>
                                                      <br />
                                                      <h5>
                                                            <span className="label label-danger">
                                                                  “CONDOMINO´S
                                                                  para la
                                                                  gestión de
                                                                  propiedad
                                                                  horizontal
                                                                  ofrece la
                                                                  ventaja de ser
                                                                  accesible, por
                                                                  lo que el
                                                                  administrador
                                                                  puede trabajar
                                                                  desde
                                                                  cualquier
                                                                  lugar y
                                                                  cualquier
                                                                  dispositivo
                                                                  con acceso a
                                                                  internet.”
                                                            </span>{" "}
                                                      </h5>
                                                      <br />
                                                      <p></p>

                                                      <h2>
                                                            <small>
                                                                  <span
                                                                        style={{
                                                                              color: "#f4511e",
                                                                        }}
                                                                  >
                                                                        Herramientas
                                                                        de
                                                                        Gestión
                                                                  </span>
                                                            </small>
                                                      </h2>
                                                      <hr />
                                                      <h3>
                                                            <ul>
                                                                  <li>
                                                                        {" "}
                                                                        Registro
                                                                        de
                                                                        Gastos y
                                                                        contratos
                                                                        de
                                                                        servicio
                                                                  </li>
                                                                  <li>
                                                                        {" "}
                                                                        Registro
                                                                        y
                                                                        actualización
                                                                        de datos
                                                                        de
                                                                        Condóminos{" "}
                                                                  </li>
                                                                  <li>
                                                                        {" "}
                                                                        Registro
                                                                        de
                                                                        alicuotas
                                                                        y pago
                                                                        de
                                                                        expensas{" "}
                                                                  </li>
                                                                  <li>
                                                                        {" "}
                                                                        Registro
                                                                        de
                                                                        controles
                                                                        de
                                                                        acceso
                                                                        vehicular
                                                                        y
                                                                        peatonal
                                                                  </li>
                                                                  <li>
                                                                        {" "}
                                                                        Registro
                                                                        de
                                                                        solicitud
                                                                        de
                                                                        servicios
                                                                        comunes{" "}
                                                                  </li>
                                                                  <li>
                                                                        {" "}
                                                                        Registro
                                                                        de
                                                                        proveedores{" "}
                                                                  </li>
                                                                  <li>
                                                                        {" "}
                                                                        Registro
                                                                        de
                                                                        compromisos
                                                                        de pago{" "}
                                                                  </li>
                                                                  <li>
                                                                        {" "}
                                                                        Balance
                                                                        finaciero
                                                                  </li>
                                                            </ul>
                                                      </h3>
                                                      <br />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </section>
                  </Router>
            );
      }
}
