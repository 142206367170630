import React , { Fragment } from "react";
import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class ListaControlV extends React.Component {
	constructor(props) {
		super(props);

		this.state = {

			datosCargados: false,
			controlVehicular: [],
		};
	}

	cargarDatos() {
		
		fetch(Api+"controlVehicular.php"+"?listar="+cookies.get("id_empresa"))
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				
				this.setState({
					datosCargados: true,
					controlVehicular: datosRespuesta,
				});
			})
			.catch(console.log);
	}

	componentDidMount() {
		this.cargarDatos();
	}

borrarRegistro = (ID_C_VEHICULAR) => {
		fetch(Api +"controlVehicular.php" +"?borrar=" + ID_C_VEHICULAR)
	.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				
				
				this.cargarDatos();
				window.location.href = "/ListaControlV";
			})
			.catch(console.log);
	};
	render() {
		const { datosCargados, controlVehicular} = this.state;
		return (
			<Fragment>
					<div className="card text-primary">
						<div className={(cookies.get("perfil") == 'DIRECTIVOS' ? "d-none" : "") +" card-header"}>
							<Link to={"/crearControlV"} className="btn btn-success">
								Agregar Nuevo Control
							</Link>
						</div>
						<div className="card-body">
							<h4>Lista de Controles Vehiculares</h4>
							<table className="table table-hover">
								<caption>Lista de Controles Vehiculares</caption>
								<thead className="thead-dark|thead-light">
									<tr>
										<th scope="col">Id</th>
										<th scope="col" colSpan="3">Condomino</th>										
										<th scope="col">Código</th>
										<th scope="col">Descripción</th>										
									</tr>
								</thead>
								<tbody>
									{controlVehicular.map((controlV) => (
										<tr key={controlV.ID_C_VEHICULAR}>
											<th scope="row">{controlV.ID_C_VEHICULAR}</th>
											<td>{controlV.IDENTIFICACION}</td>
											<td>{controlV.NOMBRE}</td>
											<td>{controlV.APELLIDO}</td>
											<td>{controlV.CODIGO}</td>
											<td>{controlV.DESCRIPCION}</td>											
											<td>
												{" "}
												<div
													className={(cookies.get("perfil") == 'DIRECTIVOS' ? "d-none" : "") +" btn-group"}
													role="group"
													aria-label="Basic example"
												>
													<Link
														to={"/editarControlV/" + controlV.ID_C_VEHICULAR}
														className="btn btn-warning"
													>
														Editar
													</Link>

													<Link
														to={"/"}
														className="btn btn-danger"
														onClick={() =>
															this.borrarRegistro(controlV.ID_C_VEHICULAR)
														}
													>
														Borrar
													</Link>
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</Fragment>
		);
	}
}
