import React from "react";
import Api from "../servicios/Api";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import {
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
} from "mdbreact";
const cookies = new Cookies();
export default class EditarControlV extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			controlVehicular: [],
			errores: [],
			condominos: [],
			id_condomino: "",
		};
		this.cambioValor = this.cambioValor.bind(this);
	}

	cargarDatos() {
		
		fetch(
			Api + "copropietarios.php" + "?listar=" + cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				
				this.setState({
					datosCargados: true,
					condominos: datosRespuesta,
				});
			})
			.catch(console.log);
	}
	cambioValor = (e) => {
		const state = this.state.controlVehicular;
		state[e.target.name] = e.target.value;
		this.setState({ controlVehicular: state, errores: [] });
	};
	enviarDatos = (e) => {
		e.preventDefault();
		
		const {ID_C_VEHICULAR, CODIGO, DESCRIPCION, ID_CONDOMINO, USUARIO_MODIFICACION } =
			this.state.controlVehicular;
		if (cookies.get("id_condomino") != null) {
			var datosEnviar = {
				ID_C_VEHICULAR : ID_C_VEHICULAR,
				CODIGO: CODIGO,
				DESCRIPCION: DESCRIPCION,
				ID_CONDOMINO: cookies.get("id_condomino"),
				USUARIO_MODIFICACION: cookies.get("id"),
			};
		} else {
			var datosEnviar = {
				ID_C_VEHICULAR:ID_C_VEHICULAR,
				CODIGO: CODIGO,
				DESCRIPCION: DESCRIPCION,
				ID_CONDOMINO: ID_CONDOMINO,
				USUARIO_MODIFICACION: cookies.get("id"),
			};
		}
		
		var errores = [];
		if (!CODIGO) errores.push("error_codigo");
		if (!DESCRIPCION) errores.push("error_descripcion");
		this.setState({ errores: errores });

		if (errores.length > 0) return false;

		fetch(Api + "controlVehicular.php" + "?actualizar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
			
		})
		
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				
				window.location.href = "/listarControlV";
			})
			.catch(console.log);
	};
	componentDidMount() {
		
		fetch(
			Api +
				"controlVehicular.php" +
				"?consultar=" +
				this.props.match.params.id
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				
				this.setState({
					datosCargados: true,
					controlVehicular: datosRespuesta[0],
				});
			})
			.catch(console.log);
		this.cargarDatos();
	}

	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}
	render() {
		const { datosCargados, controlVehicular, condominos, id_condomino } =
			this.state;
		const changeLanguage = (event: any) => {
			const state = this.state;
			state[id_condomino] = event.target.value;
			cookies.set("id_condomino", state[id_condomino], { path: "/" });
		};
		let selectedOptionId = 0;
		return (
			<div className="card text-primary|secondary|success|danger|warning|info|light|dark bg-primary|secondary|success|danger|warning|info|light|dark">
				<div className="card-header">Featured</div>
				<div className="card-body">
					<form onSubmit={this.enviarDatos}>
						<div className="form-group ">
							<label htmlFor="">Id</label>
							<input
								onChange={this.cambioValor}
								readOnly
								type="text"
								className="form-control"
								name="ID_C_VEHICULAR"
								id="ID_C_VEHICULAR"
								value={controlVehicular.ID_C_VEHICULAR}
							/>
						</div>

						<div className="form-group ">
							<label htmlFor="">
								Nombres Condomino Registrado
							</label>
							<div>
								<div className="date-line">
									<strong>
										{controlVehicular.NOMBRE}{" "}
										{controlVehicular.APELLIDO}
									</strong>
								</div>
							</div>
						</div>

						<div className="form-group">
							<label htmlFor="">Condomino:</label>
							<br />

							<select  defaultValue={selectedOptionId}
								className="custom-select custom-select-lg|custom-select-sm"
								onChange={(event) => {
									changeLanguage(event);
								}}
							>
								<option   selected>Seleccionar</option>
								{condominos.map((condomino) => (
									<option key={condomino.ID_CONDOMINO}  value={condomino.ID_CONDOMINO}>
										{condomino.NOMBRE} {condomino.APELLIDO}
									</option>
								))}
							</select>
							<br />
							<small id="helpNombre" className="invalid-feeback">
								Seleccione Condomino
							</small>
							<br />

							<label htmlFor="">Código:</label>
							<input
								onChange={this.cambioValor}
								value={controlVehicular.CODIGO}
								type="number"
								name="CODIGO"
								id="CODIGO"
								className={
									(this.verificarError("error_codigo")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Código"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Código del Control
							</small>
							<br />
							<label htmlFor="">Descripción:</label>
							<input
								onChange={this.cambioValor}
								value={controlVehicular.DESCRIPCION}
								type="text"
								name="DESCRIPCION"
								id="DESCRIPCION"
								className={
									(this.verificarError("error_descripcion")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Descripción"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe la descripción del control
							</small>
						</div>
						<div
							className="btn-group"
							role="group"
							aria-label="Basic example"
						>
							<button type="submit" className="btn btn-success">
								Guardar Control
							</button>
							<Link
								to={"/listaControlP"}
								className="btn btn-primary"
							>
								Cancelar
							</Link>
						</div>
					</form>
				</div>
			
			</div>
		);
	}
}
