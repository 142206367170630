import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import { format } from "date-fns";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class ListarCobros extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			cobro: [],
		};
	}

	cargarDatos() {
		console.log("id" + cookies.get("id"));
		fetch(
			Api +
				"cobros.php" +
				"?listar=" +
				cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					cobro: datosRespuesta,
				});
			})
			.catch(console.log);
	}

	componentDidMount() {
		this.cargarDatos();
	}

	borrarRegistro = (ID_OTROS) => {
		fetch(Api + "cobros.php" + "?borrar=" + ID_OTROS)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(ID_OTROS);
				console.log(datosRespuesta);
				this.cargarDatos();
				window.location.href = "/ListarCobros";
			})
			.catch(console.log);
	};
	
	render() {
		
		const { datosCargados, cobro } = this.state;
		return (
			<Fragment>
				<div className="card text-primary">
					<div className={(cookies.get("perfil") == 'DIRECTIVOS' ? "d-none" : "") +" card-header"}>
						<Link
							to={"/registrarCobros"}
							className="btn btn-success"
						>
							Agregar Nuevo Registro
						</Link>
					</div>
					<div className="card-body">
						<h4>Lista de Rubros por Cobrar</h4>
						<table className="table table-hover">
							<caption>Lista de Rubros por Cobrar</caption>
							<thead className="thead-dark|thead-light">
								<tr>
									<th scope="col">Id</th>
									<th scope="col" colSpan="3">
										Condomino
									</th>
									<th scope="col">Número Comprobante</th>
									<th scope="col">Descripción</th>
									<th scope="col">Valor</th>
									<th scope="col">Estado Pago</th>
									<th scope="col">Fecha Registro</th>
								</tr>
							</thead>
							<tbody>
								{cobro.map((cobros) => (
									<tr key={cobros.ID_COBROS}>
										<th scope="row">
											{cobros.ID_COBROS}
										</th>
										<td>{cobros.IDENTIFICACION}</td>
										<td>{cobros.NOMBRE}</td>
										<td>{cobros.APELLIDO}</td>
										<td>{cobros.NUMERO_COMPROBANTE}</td>
										<td>{cobros.DESCRIPCION}</td>
										<td>{cobros.VALOR}</td>
										<td>{cobros.ESTADO_PAGO}</td>
										<td>{cobros.FECHA_REGISTRO}</td>
										<td>
											{" "}
											<div
												className={(cookies.get("perfil") == 'DIRECTIVOS' ? "d-none" : "") +"btn-group"}
												role="group"
												aria-label="Basic example"
											>
												<Link
													to={
														"/editarCobro/" +
														cobros.ID_COBROS
													}
													className="btn btn-warning"
												>
													Editar
												</Link>

												<Link
													to={"/"}
													className="btn btn-danger"
													onClick={() =>
														this.borrarRegistro(
															cobros.ID_COBROS
														)
													}
												>
													Borrar
												</Link>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</Fragment>
		);
	}
}
