import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import { format } from "date-fns";
import Cookies from "universal-cookie";
import { Bar } from "react-chartjs-2";
import logo from "./baner.png";
import dateFormat from "dateformat";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	PDFViewer,
	PDFDownloadLink,
	Image,ReactPDF,
} from "@react-pdf/renderer";
const cookies = new Cookies();

export default class ListarBalance extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			ingresos: [],
			datosRespuesta: [],
			respuestaAlicuotas:[],
			alicuotass:[],
			otros:[],
			respuestaOtros:[],
			convenios:[],
			respuestaConvenios:[],
			Quixote:'',
		};
	}

	cargarIngresos() {
		
		fetch(
			Api +
				"copropietarios.php" +
				"?listarEstadoFinanciero=" +
				this.props.match.params.id
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				this.setState({
					datosCargados: true,
					ingresos: datosRespuesta,
				});			
			})
			.catch(console.log);
	}
	cargarAlicuotas() {
		
		fetch(
			Api +
				"copropietarios.php" +
				"?listarAlicuotas=" +
				this.props.match.params.id
		)
			.then((respuesta) => respuesta.json())
			.then((respuestaAlicuotas) => {
				
				this.setState({
					datosCargados: true,
					alicuotass: respuestaAlicuotas,
				});
				
			})
			.catch(console.log);
	}

	cargarOtros() {
		
		fetch(
			Api +
				"copropietarios.php" +
				"?listarOtros=" +
				this.props.match.params.id
		)
			.then((respuesta) => respuesta.json())
			.then((respuestaOtros) => {
				
				this.setState({
					datosCargados: true,
					otros: respuestaOtros,
				});
				
			})
			.catch(console.log);
	}

cargarConvenios() {
		
		fetch(
			Api +
				"copropietarios.php" +
				"?listarConvenios=" +
				this.props.match.params.id
		)
			.then((respuesta) => respuesta.json())
			.then((respuestaConvenios) => {
				
				this.setState({
					datosCargados: true,
					convenios: respuestaConvenios,
				});
				
			})
			.catch(console.log);
	}

	componentDidMount() {
		this.cargarIngresos();
		this.cargarAlicuotas();
		this.cargarOtros();
		this.cargarConvenios();
	}


	render() {
		var Quixote;
		const { datosCargados, ingresos,alicuotass,otros,convenios } = this.state;
		let alip = ingresos
			.filter(
				(ing) =>
					(ing.ESTADO_PAGO === "PAGADO") &
					(ing.CONCEPTO === "ALICUOTAS")
			)
			.map((ing) => parseInt(ing.SUMA));
			if(alip == "NaN" ){
				alip="0";
			}
		let alicuotasp = parseInt(alip);
		let alicuotas;
		let ali = ingresos
			.filter(
				(ing) =>
					(ing.ESTADO_PAGO === "PENDIENTE") &
					(ing.CONCEPTO === "ALICUOTAS")
			)
			.map((ing) => ing.SUMA);
			if(ali.length==0){
				 alicuotas =0;
			}else{
			
		 alicuotas = parseInt(ali);
		}
	
		let cobp = ingresos
			.filter(
				(ing) =>
					(ing.ESTADO_PAGO === "PENDIENTE") &
					(ing.CONCEPTO === "COBROS")
			)
			.map((ing) => ing.SUMA);
		let cobrosp = parseInt(cobp);
		let cob = ingresos
			.filter(
				(ing) =>
					(ing.ESTADO_PAGO === "PAGADO") & (ing.CONCEPTO === "COBROS")
			)
			.map((ing) => ing.SUMA);
		let cobros = parseInt(cob);
		let vari = ingresos
			.filter((ing) => ing.CONCEPTO === "INGRESOS VARIOS")
			.map((ing) => ing.SUMA);
		let varios = parseInt(vari);
		let saldo = parseInt(alicuotas) ;
		const numero2 = ingresos.map((ing) => ing.ingresos);
console.log(alicuotasp);
		if(alicuotasp=="NaN" || alicuotasp==null){

			alicuotasp="0";
		}
		if(varios=="NaN" || varios==null){
			varios="0";
		}
		if(varios=="NaN" || varios==null){
			varios="0";
		}
		
		console.log(cobros);
		console.log(varios);
		
		let sumar = parseInt(alicuotasp) ;
		
		const data = {
			labels: [
				"Alícuotas Pagadas",
				"Alicuotas Pendientes",				
				"Saldo por Pagar",
			],
			datasets: [
				{
					label: "VALORES",
					backgroundColor: "#cfe2ff",
					borderColor: "#fff3cd",
					borderWidth: 1,
					hoverBackgroundColor: "",
					hoverBorderColor: "#Ff0000",
					data: [
						alicuotasp,
						alicuotas,						
						saldo,
					],
				},
			],
		};
		const opciones = {
			maintainAspectRatio: false,
			responsive: true,
		};
		const BORDER_COLOR = "#bfbfbf";
		const BORDER_STYLE = "solid";
		const COL1_WIDTH = 40;
		const COL2_WIDTH = 60;
		const COLN_WIDTH = (100 - COL1_WIDTH) / 3;
		const styles = StyleSheet.create({
			body: {
				padding: 20,
			},
			iframe: {
				width: "400px !important",
				height: "350px !important",
			},
			table: {
				display: "table",
				width: "auto",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 1,
				borderRightWidth: 0,
				borderBottomWidth: 0,
			},
			tableRow: {
				margin: "auto",
				flexDirection: "row",
			},
			tableCol1Header: {
				width: COL1_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderBottomColor: "#000",
				borderWidth: 1,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableColHeader: {
				width: COLN_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderBottomColor: "#000",
				borderWidth: 1,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableCol1: {
				width: COL1_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 1,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableCol: {
				width: COLN_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 1,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableCellHeader: {
				margin: 5,
				fontSize: 8,
				fontWeight: 200,
			},
			tableCell: {
				margin: 5,
				fontSize: 10,
			},
			section: {
				margin: 5,
				padding: 20,
			},
			titulo: {
				fontSize: 30,
				fontWeight: 500,
			},
			saldo:{
				color:'#a85960',
			},
			descripcion: {
				margin: 3,
				fontSize: 8,
			},
			tableCol2: {
				width: COL2_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 1,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableCol2Header: {
				width: COL2_WIDTH + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderBottomColor: "#000",
				borderWidth: 1,
				borderLeftWidth: 0,
				borderTopWidth: 0,
			},
			tableCol6: {
				width: 10 + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 1,
				borderLeftWidth: 0,
				borderTopWidth: 0,
				align: "left",
			},
			tableCol7: {
				width: 15 + "%",
				borderStyle: BORDER_STYLE,
				borderColor: BORDER_COLOR,
				borderWidth: 1,
				borderLeftWidth: 0,
				borderTopWidth: 0,
				align: "left",
			},
		});
		var nombre = ingresos.map((ing) => ing.NOMBRE);
		var apellido = ingresos.map((ing) => ing.APELLIDO);
		var identificacion = ingresos.map((ing) => ing.IDENTIFICACION);
		var casa_ofi_depa = ingresos.map((ing) => ing.CASA_OFI_DEP);
		var empresa = ingresos.map((ing) => ing.EMPRESA);
		 Quixote = () => (
			<Document>
				<Page style={styles.body}>
					
					<View style={styles.section}>
						<Text style={styles.titulo}>{empresa[0]}</Text>
					</View>
					<View style={styles.section}>
						<Text>
							Copropietario: {nombre[0]} {apellido[0]}
						</Text>
						<Text></Text>
						<Text>Identificación: {identificacion[0]}</Text>
						<Text></Text>
						<Text>
							Casa-Oficina-Departamento: {casa_ofi_depa[0]}
						</Text>
					</View>

					<View style={styles.table}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol1Header}>
								<Text style={styles.tableCellHeader}>
									Concepto
								</Text>
							</View>
							<View style={styles.tableColHeader}>
								<Text style={styles.tableCellHeader}>
									Cantidad de Pagos
								</Text>
							</View>
							<View style={styles.tableColHeader}>
								<Text style={styles.tableCellHeader}>
									Total
								</Text>
							</View>
							<View style={styles.tableColHeader}>
								<Text style={styles.tableCellHeader}>
									Estado Pago
								</Text>
							</View>
						</View>
						<View style={styles.tableRow}>
							<View style={styles.tableCol1}>
								{ingresos.map((ingreso) => (
									<Text style={styles.tableCellHeader}>{ingreso.CONCEPTO}</Text>
								))}
							</View>
							<View style={styles.tableCol}>
								{ingresos.map((ingreso) => (
									<Text style={styles.tableCellHeader}> {ingreso.MESES}</Text>
								))}
							</View>
							<View style={styles.tableCol}>
								{ingresos.map((ingreso) => (
									<Text style={styles.tableCellHeader}>{ingreso.SUMA}</Text>
								))}
							</View>
							<View style={styles.tableCol}>
								{ingresos.map((ingreso) => (
									<Text style={styles.tableCellHeader}>{ingreso.ESTADO_PAGO}</Text>
								))}
							</View>
						</View>
					</View>

					<View style={styles.section}>
						<View style={styles.tableCol1}>
							<Text style={styles.saldo}>Saldo Pendiente</Text>
							<Text style={styles.saldo}>${saldo}</Text>							
						</View>
						
					</View>

					<View style={styles.section}>
						<Text style={styles.titulo}>Alicuotas</Text>
					</View>

					<View style={styles.table}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol6}>
								<Text style={styles.tableCellHeader}>
									Valor
								</Text>
							</View>
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}>
									Fecha
								</Text>
							</View>
							<View style={styles.tableColHeader}>
								<Text style={styles.tableCellHeader}>
									Mes
								</Text>
							</View>
							<View style={styles.tableColHeader}>
								<Text style={styles.tableCellHeader}>
									Concepto
								</Text>
							</View>
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}>
									# Comprobante
								</Text>
							</View>
							<View style={styles.tableCol7}>
								<Text style={styles.tableCellHeader}>
									Estado Pago
								</Text>
							</View>
						</View>
						<View style={styles.tableRow}>
							<View style={styles.tableCol6}>
								{alicuotass.map((ali) => (
									<Text style={styles.tableCellHeader}>{ali.VALOR}</Text>
								))}
							</View>
							<View style={styles.tableCol7}>
								{alicuotass.map((ali) => (
									<Text style={styles.tableCellHeader}>{ali.FECHA}</Text>
								))}
							</View>
							<View style={styles.tableCol}>
								{alicuotass.map((ali) => (
									<Text style={styles.tableCellHeader}>{ali.MES}</Text>
								))}
							</View>
							<View style={styles.tableCol}>
								{alicuotass.map((ali) => (
									<Text style={styles.tableCellHeader}>{ali.descripcion}</Text>
								))}
							</View>
							<View style={styles.tableCol7}>
								{alicuotass.map((ali) => (
									<Text style={styles.tableCellHeader}>{ali.COMPROBANTE}</Text>
								))}
							</View>
							<View style={styles.tableCol7}>
								{alicuotass.map((ali) => (
									<Text style={styles.tableCellHeader}>{ali.ESTADOPAGO}</Text>
								))}
							</View>
						</View>
					</View>
					

					<View style={styles.section}>
						<Text style={styles.titulo}>Convenios de Pago</Text>
					</View>
					<View style={styles.table}>
						<View style={styles.tableRow}>
							<View style={styles.tableCol2Header}>
								<Text style={styles.tableCellHeader}>
									Descripción
								</Text>
							</View>
							<View style={styles.tableColHeader}>
								<Text style={styles.tableCellHeader}>
									Valor Total
								</Text>
							</View>
							<View style={styles.tableColHeader}>
								<Text style={styles.tableCellHeader}>
									Fecha
								</Text>
							</View>							
						</View>
						<View style={styles.tableRow}>
							<View style={styles.tableCol2}>
								{convenios.map((co) => (
									<Text style={styles.tableCellHeader}>{co.DESCRIPCION}</Text>
								))}
							</View>
							<View style={styles.tableCol}>
								{convenios.map((co) => (
									<Text style={styles.tableCellHeader}>{co.VALOR_TOTAL}</Text>
								))}
							</View>
							<View style={styles.tableCol}>
								{convenios.map((co) => (
									<Text style={styles.tableCellHeader}>{co.FECHA}</Text>
								))}
							</View>
							
						</View>
					</View>
				</Page>
			</Document>
		);

		return (
			<Fragment>
				<div className="row d-flex justify-content-center align-items-start h-60">
					<div className="col-md-8 col-lg-6 col-xl-7 offset-xl-1">
						<h4>Lista de Estado Financiero</h4>
						<table className="table table-hover">
							<caption> Ingresos</caption>
							<thead className="thead-dark|thead-light">
								<tr>
									<th scope="col">CONCEPTO</th>
									<th scope="col">CANTIDAD DE PAGOS</th>
									<th scope="col">TOTAL</th>
									<th scope="col">ESTADO PAGO</th>
								</tr>
							</thead>
							<tbody>
								{ingresos.map((ingreso) => (
									<tr key={ingreso.CONCEPTO}>
										<th scope="row">${ingreso.CONCEPTO}</th>
										<th scope="row">{ingreso.MESES}</th>
										<th scope="row">${ingreso.SUMA}</th>
										<th scope="row">
											{ingreso.ESTADO_PAGO}
										</th>
									</tr>
								))}
							</tbody>
						</table>
						<div className="alert alert-primary">
							<strong>
								<h4>Total de Pagos $ {sumar}</h4>
							</strong>
						</div>
					</div>
					<div className="col-md-4 col-lg-6 col-xl-4">
						<PDFViewer className="ClassName">
							<Quixote />

						</PDFViewer>
						<br />
						<PDFDownloadLink
							document={<Quixote />}
							fileName="estadoFinaciero.pdf"
						>
							{({ loading }) =>
								loading ? "Loading" : "Descargar"
							}
						</PDFDownloadLink>
						<Link
														to={"/"}
														className="btn btn-danger"
														onClick={() =>
															this.guardarArchivo()
														}
													>
														Guardar
													</Link>
					</div>
				</div>

				<div className="card text-primary">
					<div className="card-body">
						<h4>Saldo Pendiente</h4>
						<div className="alert alert-danger">
							<strong>
								<h4>Saldo $ {saldo}</h4>
							</strong>
						</div>
					</div>
				</div>
				<div
					className="card-body"
					style={{ width: "100%", height: "500px" }}
				>
					<h2>Grafico de Estado Financiero</h2>
					<Bar data={data} options={opciones} />
				</div>
			</Fragment>
		);
	}
}
