import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import Cookies from "universal-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
const cookies = new Cookies();
export default class ListaControlP extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			controlPeatonal: [],
			currentPage: 0,
			buscar: "",
			busqueda: "",
		};
	}

	cargarDatos() {
		fetch(
			Api + "controlPeatonal.php" + "?listar=" + cookies.get("id_empresa")
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				this.setState({
					datosCargados: true,
					controlPeatonal: datosRespuesta,
				});
			})
			.catch(console.log);
	}

	componentDidMount() {
		this.cargarDatos();
	}

	borrarRegistro = (ID_C_PEATONAL) => {
		fetch(Api + "controlPeatonal.php" + "?borrar=" + ID_C_PEATONAL)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				this.cargarDatos();
				window.location.href = "/listaControlP";
			})
			.catch(console.log);
	};
	render() {
		const { datosCargados, controlPeatonal, busqueda, currentPage } =
			this.state;

		const filtro = controlPeatonal.slice(currentPage, currentPage + 5);

		const nextPage = () => {
			if (busqueda.length === 0) {
				this.setState({ currentPage: currentPage + 5 });
			}
		};
		const previusPage = () => {
			if (currentPage > 0) {
				if (busqueda.length === 0) {
					this.setState({ currentPage: currentPage - 5 });
				}
			}
		};
		const buscar = (e) => {
			this.setState({ busqueda: e.target.value });
			console.log("busqueda:" + e.target.value);
			filtrar(e.target.value);
		};
		const filtrar = (termino) => {
			if (termino.length === 0) {
				this.cargarDatos();
			} else {
				var resultadoBusqueda = controlPeatonal.filter((elemento) => {
					if (
						elemento.APELLIDO.toString()
							.toUpperCase()
							.includes(termino.toUpperCase()) ||
						elemento.NOMBRE.toString()
							.toUpperCase()
							.includes(termino.toUpperCase()) ||
						elemento.CASA_OFI_DEP.toString()
							.toUpperCase()
							.includes(termino.toUpperCase())
					) {
						return elemento;
					}
				});
				this.setState({ controlPeatonal: resultadoBusqueda });
			}
		};
		if (!datosCargados) {
			return (
				<div className="alert alert-info">
					<p>Cargando....</p>
				</div>
			);
		} else {
			return (
				<Fragment>
					<div className="card text-primary">
						<div
							className={
								(cookies.get("perfil") == "DIRECTIVOS"
									? "d-none"
									: "") + " card-header"
							}
						>
							<Link
								to={"/crearControlP"}
								className="btn btn-success"
							>
								Agregar Nuevo Control
							</Link>
						</div>
						<div class="row">
							<div class="col">
								<input
									className="form-control"
									style={{ width: "100%" }}
									value={busqueda}
									placeholder="Buscar por Apellido, Nombre o casa"
									onChange={buscar}
								/>
							</div>
							<div class="col text-right">
								<button className="btn btn-secondary">
									<FontAwesomeIcon icon={faSearch} />
								</button>
							</div>
						</div>
						<div className="card-body">
							<h4>Lista de Controles Peatonales</h4>
							<button
								className="tbn btn-primary"
								onClick={previusPage}
							>
								<i className="far fa-arrow-alt-circle-left"></i>
								Anteriores
							</button>
							&nbsp;
							<button
								className="tbn btn-primary"
								onClick={nextPage}
							>
								<i className="far fa-arrow-alt-circle-right"></i>
								Siguientes
							</button>
							<table className="table table-hover">
								<caption>Lista de Controles Peatonales</caption>
								<thead className="thead-dark|thead-light">
									<tr>
										<th scope="col">Id</th>
										<th scope="col" colSpan="3">
											Condomino
										</th>
										<th scope="col">
											Casa-Departamento-Oficina
										</th>
										<th scope="col">Código</th>
										<th scope="col">Descripción</th>
									</tr>
								</thead>
								<tbody>
									{filtro.map((copropietario) => (
										<tr key={copropietario.ID_C_PEATONAL}>
											<th scope="row">
												{copropietario.ID_C_PEATONAL}
											</th>
											<td>
												{copropietario.IDENTIFICACION}
											</td>
											<td>{copropietario.NOMBRE}</td>
											<td>{copropietario.APELLIDO}</td>
											<td>
												{copropietario.CASA_OFI_DEP}
											</td>
											<td>{copropietario.CODIGO}</td>
											<td>{copropietario.DESCRIPCION}</td>
											<td>
												{" "}
												<div
													className={
														(cookies.get(
															"perfil"
														) == "DIRECTIVOS"
															? "d-none"
															: "") + " btn-group"
													}
													role="group"
													aria-label="Basic example"
												>
													<Link
														to={
															"/editarControlP/" +
															copropietario.ID_C_PEATONAL
														}
														className="btn btn-warning"
													>
														Editar
													</Link>

													<Link
														to={"/"}
														className="btn btn-danger"
														onClick={() =>
															this.borrarRegistro(
																copropietario.ID_C_PEATONAL
															)
														}
													>
														Borrar
													</Link>
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</Fragment>
			);
		}
	}
}
