import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class ListaProveedor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			datosCargados: false,
			proveedores: [],
		};
	}

	borrarRegistro = (ID_PROV) => {
		fetch(Api +"proveedor.php" +"?borrar=" + ID_PROV)
	.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(ID_PROV);
				console.log(datosRespuesta);
				this.cargarDatos();
				window.location.href = "/listaProveedor";
			})
			.catch(console.log);
	};

	cargarDatos() {
		console.log('id'+cookies.get("id"));
		fetch(Api+"proveedor.php"+"?listar="+cookies.get("id_empresa"))
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					proveedores: datosRespuesta,
				});
			})
			.catch(console.log);
	}

	componentDidMount() {
		this.cargarDatos();
	}

	render() {
		const { datosCargados, proveedores } = this.state;
		if (!datosCargados) {
			return <div>Cargando....</div>;
		} else {
			return (
				<Fragment>
					<div className="card text-primary">
						<div className="card-header">
							<Link to={"/crearProveedor"} className="btn btn-success">
								Agregar Nuevo Proveedor
							</Link>
						</div>
						<div className="card-body">
							<h4> Lista de Proveedores</h4>
							<table className="table table-hover">
								<caption>Lista de Proveedores</caption>
								<thead className="thead-dark|thead-light">
									<tr>
										<th scope="col">Id</th>
										<th scope="col">Identificación</th>
										<th scope="col">Nombre</th>
										<th scope="col">Apellido</th>
										<th scope="col">Correo</th>
										<th scope="col">Dirección</th>
										<th scope="col">Teléfono</th>
										<th scope="col">Celular</th>
										<th scope="col">Descripción</th>
										<th scope="col">Acciones</th>
									</tr>
								</thead>
								<tbody>
									{proveedores.map((proveedor) => (
										<tr key={proveedor.ID_PROV}>
											<th scope="row">{proveedor.ID_PROV}</th>
											<td>{proveedor.IDENTIFICACION}</td>
											<td>{proveedor.NOMBRE}</td>
											<td>{proveedor.APELLIDO}</td>
											<td>{proveedor.CORREO}</td>
											<td>{proveedor.DIRECCION}</td>
											<td>{proveedor.TELEFONO}</td>
											<td>{proveedor.CELULAR}</td>
											<td>{proveedor.DESCRIPCION}</td>
											<td>
												{" "}
												<div
													className="btn-group"
													role="group"
													aria-label="Basic example"
												>
													<Link
														to={"/editarProveedor/" + proveedor.ID_PROV}
														className="btn btn-warning"
													>
														Editar
													</Link>

													<Link
														to={"/"}
														className="btn btn-danger"
														onClick={() =>
															this.borrarRegistro(proveedor.ID_PROV)
														}
													>
														Borrar
													</Link>
												</div>{" "}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</Fragment>
			);
		}
	}
}
