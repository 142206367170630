import React, { useState, useEffect } from "react";
import Api from "../servicios/Api";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Container from "../container/Container";
import { MDBContainer, MDBAlert } from 'mdbreact';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import ListarAlicuota from "../registroAlicuotas/ListarAlicuota";
const cookies= new Cookies;
export default class Confirmacion extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			correo: "",
			clave: "",
			errores: [],
			activar: "1",
			usuario: [],
			conter: 0,
		};
	}

	
	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}

	enviarDatos = (e) => {
		fetch(Api + "registroAlicuotas.php" + "?borrar=" + this.props.match.params.id)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {				
				window.location.href = "/ListarAlicuota";
			})
			.catch(console.log);
	};

	

	render() {
		const { correo, clave, usuario, login } = this.state;
		return (

			<Router>
				<Route exact path="/" component={Container}></Route>
				<Modal className="modal-dialog modal-dialog-centered " isOpen={true} tabindex="-1" >
				<section className="vh-80">
					<div
						className=" container-fluid h-custom"						
					>
						<div className="row d-flex justify-content-center align-items-center h-100">
							
							<div className="col-md-1 col-lg-10 col-xl-10 offset-xl-1">
								<form onSubmit={this.enviarDatos}>
								
									<div className="form-outline mb-4">
										
										<label className="form-label" htmlFor="form3Example3">
											Esta Seguro de Eliminar el registro
										</label>
									</div>

									

									<div className="d-flex justify-content-between align-items-center">
										<a href="#!" className="text-body">
											
										</a>
									</div>
								
									<div className="text-center text-lg-start mt-4 pt-2">
									<center>
										<Link
							className="btn btn-success"
							onClick={() => this.enviarDatos()}							
						>
											Aceptar
										</Link>
										<Link
							to={"/listarAlicuota"}
							className="btn btn-primary"
						>
							Cancelar
						</Link>
										</center>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
				</Modal>
			</Router>
		);
	}
}
