import React ,{Component} from "react";

import { Link } from "react-router-dom";
import Api from "../servicios/Api";
import ListarControlV from "../controlVehicular/ListarControlV";
import Cookies from "universal-cookie";
import { MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption } from "mdbreact";
import {
	DatePickerComponent,
	RenderDayCellEventArgs,
	ChangedEventArgs,
	CalendarComponent,
} from "@syncfusion/ej2-react-calendars";
const cookies = new Cookies();

export default class CrearIngresoCaja extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			codigo: "",
			descripcion: "",
			id_condomino: "",
			usuario_creacion: "",
			errores: [],
			valor:"",
			datosCargados:false,
			fecha:"",
		};
	}
	cargarDatos() {
		
		fetch(Api+"cajaChica.php"+"?consultar="+cookies.get("id_empresa"))
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				cookies.set("id_caja", datosRespuesta[0].id_caja);
				this.setState({
					datosCargados: true,
					
				});

			})
			.catch(console.log);
	};

	componentDidMount() {
		this.cargarDatos();		
	}


	enviarDatos = (e) => {
		e.preventDefault();
		
		const { codigo, descripcion, id_caja ,usuario_creacion,valor,fecha} = this.state;
		var datosEnviar = {
			fecha: cookies.get('fecha'),
			descripcion: descripcion,
			valor:valor,
			usuario_creacion:cookies.get('id'),
			id_caja:cookies.get('id_caja'),
		};
		
		var errores = [];
		if (!valor) errores.push("error_valor");
		if (!descripcion) errores.push("error_descripcion");
		this.setState({ errores: errores });

		if (errores.length > 0) return false;
		console.log(datosEnviar);
	fetch(Api + "cajaChica.php" + "?insertarIgresos=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				
				window.location.href = "/listarControlV";
			})
			.catch(console.log);
	};

	cambioValor = (e) => {
		const state = this.state;
		state[e.target.name] = e.target.value;

		this.setState({ state, errores: [] });
	};
	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}

	render() {
		const { codigo, descripcion, id_caja,usuario_creacion,fecha,valor } = this.state;
		const dateValue: Date = new Date(
			new Date().getFullYear(),
			new Date().getMonth,
			20
		);
		const changefecha = (event: any) => {
			const state = this.state;
			state[fecha] = event.target.value;
			cookies.set("fecha", state[fecha], { path: "/" });
			var d = new Date(state[fecha]);
			const options = {
				day: "numeric",
				month: "numeric",
				year: "numeric",
			};
			
		};
		return (
			<div className="card text-primary">
				<div className="card-header">
					<h4> Crear Ingreso Caja</h4>
				</div>
				<div className="card-body">
					<form onSubmit={this.enviarDatos}>
					<div class="col-md-6">
													<label htmlFor="">
														Fecha:
													</label>
													<div className="datepicker-control-section">
														<DatePickerComponent
															onChange={(
																event
															) => {
																changefecha(
																	event
																);
															}}
															dateFormat="YYYY/MM/DD"
															ref={(calendar) =>
																(this.datepickerInstance =
																	calendar)
															}
															value={dateValue}
														></DatePickerComponent>
													</div>
													<br />
												</div>
						<div className="form-group">				
						
							<label htmlFor="">Descripción:</label>
							<input
								onChange={this.cambioValor}
								value={descripcion}
								type="text"
								name="descripcion"
								id="descripcion"
								className={
									(this.verificarError("error_descripcion")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Descripción"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe la descripción del control
							</small>
						</div>
						<br/>
						<div className="form-group">				
						
							<label htmlFor="">Valor:</label>
							<input
								onChange={this.cambioValor}
								value={valor}
								type="number"
								name="valor"
								id="valor"
								className={
									(this.verificarError("error_valor")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Descripción"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el valor del ingreso
							</small>
						</div>
						<br/>
						<div
							className="btn-group"
							role="group"
							aria-label="Basic example"
						>
							<button type="submit" className="btn btn-success">
								Guardar Control
							</button>
							<Link
								to={"/listarCaja"}
								className="btn btn-primary"
							>
								Cancelar
							</Link>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
