import React, { useState, useEffect } from "react";
import Api from "../servicios/Api";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Container from "../container/Container";
import ListaCop from "../copropietario/ListaCop";
import Imagen from "./draw2.svg";
import Session from "react-session-api";
import Cookies from "universal-cookie";
import { MDBContainer, MDBAlert } from 'mdbreact';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
const cookies= new Cookies;
export default class Login extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			correo: "",
			clave: "",
			errores: [],
			activar: "1",
			usuario: [],
			conter: 0,
		};
	}

	componentDidMount() {
		const counter = (data) => {
			this.setState({ counter: data["counter"] });
		};

		Session.onSet(counter);
	}
	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}

	enviarDatos = (e) => {
		e.preventDefault();
		console.log("Formulario enviado.....");
		const { correo, clave } = this.state;
		
		var datosEnviar = {
			correo: correo,
			clave: clave,
		};
		var errores = [];
		if (!correo) errores.push("error_correo");
		if (!clave) errores.push("error_clave");
		this.setState({ errores: errores });
		var activar = "1";

		if (errores.length > 0) return false;

		fetch(Api + "login.php" + "?consultar", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})

			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				let counter = Session.get("counter") + 1;
				window.location.href = "/";
				this.setState({
					usuario: datosRespuesta[0],
					login: true,
				});
				var respuesta=datosRespuesta[0];
				cookies.set('id',respuesta.id_usuario,{path:"/"})
				cookies.set('id_empresa',respuesta.id,{path:"/"})
				cookies.set('empresa',respuesta.empresa,{path:"/"})
				cookies.set('nombre',respuesta.nombre,{path:"/"})
				cookies.set('perfil',respuesta.perfil,{path:"/"})
				Session.set("counter", counter);
				Session.set("1", datosRespuesta[0]);
				console.log(datosRespuesta[0]);
				
			})

			.catch(console.log);
	};

	cambioValor = (e) => {
		const state = this.state;
		state[e.target.name] = e.target.value;
		this.setState({ state, errores: [] });
	};

	render() {
		const { correo, clave, usuario, login } = this.state;
		return (

			<Router>
				<Route exact path="/" component={Container}></Route>
				<Modal className="modal-dialog modal-dialog-centered " isOpen={true} tabindex="-1" >
				<section className="vh-80">
					<div
						className=" container-fluid h-custom"						
					>
						<div className="row d-flex justify-content-center align-items-center h-100">
							
							<div className="col-md-1 col-lg-10 col-xl-10 offset-xl-1">
								<form onSubmit={this.enviarDatos}>
								<img src={Imagen} className="img-fluid" alt="Sample image" />
									<div className="form-outline mb-4">
										<input
											onChange={this.cambioValor}
											value={correo}
											name="correo"
											id="correo"
											type="email"
											className={
												(this.verificarError("error_correo")
													? "is-invalid"
													: "") + " form-control form-control-lg"
											}
											placeholder="Ingrese Correo Electrónico"
										/>
										<label className="form-label" htmlFor="form3Example3">
											Correo Electrónico
										</label>
									</div>

									<div className="form-outline mb-3">
										<input
											onChange={this.cambioValor}
											value={clave}
											name="clave"
											id="clave"
											type="password"
											className={
												(this.verificarError("error_clave")
													? "is-invalid"
													: "") + " form-control form-control-lg"
											}
											placeholder="Ingrese Clave"
										/>
										<label className="form-label" htmlFor="form3Example4">
											Clave
										</label>
									</div>

									<div className="d-flex justify-content-between align-items-center">
										<a href="#!" className="text-body">
											
										</a>
									</div>
								
									<div className="text-center text-lg-start mt-4 pt-2">
									<center>
										<button
											type="submit"
											className="btn btn-success btn-lg"
											style={{
												paddingLeft: "5.5rem",
												paddingRight: "5.5rem",
											}}
										>
											LOGIN
										</button>
										
										</center>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
				</Modal>
			</Router>
		);
	}
}
