import React, { Fragment ,useEffect} from "react";
import './Navbar.css';
import { NavLink } from 'react-router-dom';
import "./Container.css";
import ListaCop from "../copropietario/ListaCop";
import { Route, BrowserRouter as Router } from "react-router-dom";
import CrearCop from "../copropietario/CrearCop";
import EditarCop from "../copropietario/EditarCop";
import Inicio from "../inicio/Inicio";
import { Link } from "react-router-dom";
import Login from "../login/Login";
import Imagen from "../login/draw2.svg";
import Cookies from "universal-cookie";
import ListaControlP from "../controlPeatonal/ListarControlP";
import CrearControlP from "../controlPeatonal/CrearControlP";
import EditarControlP from "../controlPeatonal/EditarControlP";
import ListarControlV from "../controlVehicular/ListarControlV"
import CrearControlV from "../controlVehicular/CrearControlV";
import EditarControlV from "../controlVehicular/EditarControlV";
import ListarAlicuota from "../registroAlicuotas/ListarAlicuota";
import RegistrarAlicuota from "../registroAlicuotas/RegistrarAlicuotaR";
import EditarAlicuota from "../registroAlicuotas/EditarAlicuota";
import ListarInventario from "../inventario/ListarInventario";
import RegistrarInventario from "../inventario/RegistrarInventario";
import EditarInventario from "../inventario/EditarInventario";
import ListarServicio from "../solicitudServicio/ListarServicio";
import RegistrarServicio from "../solicitudServicio/RegistrarServicio";
import EditarServicio from "../solicitudServicio/EditarServicio";
import ListaProveedor from "../proveedor/ListaProveedor";
import CrearProveedor from "../proveedor/CrearProveedor";
import EditarProveedor from "../proveedor/EditarProveedor";
import ListarGasto from "../gastos/ListarGasto";
import RegistrarGasto from "../gastos/RegistrarGasto";
import EditarGasto from "../gastos/EditarGasto";
import ListarConvenio from "../convenioPago/ListarConvenio";
import RegistrarConvenio from "../convenioPago/RegistrarConvenio";
import EditarConvenio from "../convenioPago/EditarConvenio";
import ListarIngreso from "../otrosIngresos/ListarIngresos";
import RegistrarIngreso from "../otrosIngresos/RegistrarIngresos";
import EditarIngresos from "../otrosIngresos/EditarIngreso";
import ListarCobros from "../otrosPorCobrar/ListarCobros";
import RegistrarCobros from "../otrosPorCobrar/RegistrarCobros";
import EditarCobro from "../otrosPorCobrar/EditarCobros";
import Paginador from "../copropietario/Paginador";
import ListarBalance from "../balanceFinanciero/ListarBalance";
import EstadoFinanciero from "../copropietario/EstadoFinanciero";
import Impresion from "../registroAlicuotas/Imprimir";
import PagoConsolidado from "../registroAlicuotas/PagoConsolidado";
import ImpresionConsolidada from "../registroAlicuotas/ImpresionConsolidada";
import Confirmacion from "../registroAlicuotas/Confirmacion";
import ListarCajaChica from "../cajaChica/ListarCajaChica";
import CrearCajaChica from "../cajaChica/CrearCajaChica";
import ListarCostos from "../costosBancarios/ListarCostos";
import RegistrarCostos from "../costosBancarios/RegistrarCostos";
import EditarCostos from "../costosBancarios/EditarCostos";
import ListarSaldo from "../saldoAnterior/ListarSaldo";
import RegistrarSaldo from "../saldoAnterior/RegistrarSaldo";
import EditarSaldo from "../saldoAnterior/EditarSaldo";
import ListarComprobante from "../actualizarComprobante/ListarComprobante";
import EditarComprobante from "../actualizarComprobante/EditarComprobante";
import { Navbar, Nav, Container,NavItem } from 'react-bootstrap';
const cookies = new Cookies();
export default class Containers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded:false,
    };
  }
  componentDidMount() {
    const counter = (data) => {
      this.setState({ counter: data["counter"] });
    };
  }
  borrarCookies() {
    
    cookies.remove("id", { path: "/" });
    cookies.remove("id_empresa", { path: "/" });
    
    window.location.href = "/";
  }
  onClick(expanded){

    this.setState({expanded:expanded});
  }
  render() {
    const{expanded}=this.state;
    return (
      <Router>
        <Fragment>
          <div className=" container-fluid">
            <div
              className={
                (cookies.get("id") != null ? "d-none" : "") + " btn-group"
              }
              role="group"
              aria-label="Basic example"
            >
              <div className="row d-flex justify-content-center align-items-start h-100">
                <div className="col-md-4 col-lg-6 col-xl-4">
                <br/>
                <br/>
                <br/>
                  <center>
                    <Link to={"/login"} className="btn btn-primary col-md-8">
                      Ingresar al Sistema
                    </Link>
                  </center>
                  <img src={Imagen} className="img-fluid" alt="Sample image" />
                </div>
                <div className="col-md-8 col-lg-6 col-xl-7 offset-xl-1">
                  <div className="text-primary col-sm-9">
                    <h2>
                      <small>
                        <span
                          style={{
                            color: "#f4511e",
                          }}
                        >
                          CONDOMINO'S
                        </span>
                      </small>
                    </h2>
                    <hr />
                    <h3>
                      Te brinda todo lo que necesitas para tener la
                      administración de tu condominio o edificio online.
                    </h3>
                    <br />
                    <h5>
                      <span className="label label-danger">
                        La gestión de propiedad horizontal consiste en la
                        planificación, organización, dirección, ejecución y
                        control de las diversas actividades relacionadas con la
                        existencia y uso de un bien inmobiliario.
                      </span>{" "}
                    </h5>
                    <br />
                    <p></p>

                    <h2>
                      <small>
                        <span
                          style={{
                            color: "#f4511e",
                          }}
                        >
                          Confiable y Seguro
                        </span>
                      </small>
                    </h2>
                    <hr />
                    <h3>
                      La información y la base de datos se encuentra
                      completamente protegida. Esto, porque se contemplan
                      perfiles de usuario con diversos niveles de autorización y
                      con métodos de autenticación de identidad. Así mismo, están
                      protegidos contra ataques cibernéticos.
                    </h3>
                    <br />
                    <h5>
                      <span className="label label-danger">
                        “CONDOMINO´S para la gestión de propiedad horizontal
                        ofrece la ventaja de ser accesible, por lo que el
                        administrador puede trabajar desde cualquier lugar y
                        cualquier dispositivo con acceso a internet.”
                      </span>{" "}
                    </h5>
                    <br />
                    

                    <h2>
                      <small>
                        <span
                          style={{
                            color: "#f4511e",
                          }}
                        >
                          Herramientas de Gestión
                        </span>
                      </small>
                    </h2>
                    <hr />
                    <h3>
                      <ul>
                        <li> Registro de Gastos y contratos de servicio</li>
                        <li>
                          {" "}
                          Registro y actualización de datos de Condóminos{" "}
                        </li>
                        <li> Registro de alicuotas y pago de expensas </li>
                        <li>
                          {" "}
                          Registro de controles de acceso vehicular y peatonal
                        </li>
                        <li> Registro de solicitud de servicios comunes </li>
                        <li> Registro de proveedores </li>
                        <li> Registro de compromisos de pago </li>
                        <li> Balance finaciero</li>
                      </ul>
                    </h3>
                    <br />
                  </div>
                   <div className="alert alert-success" >
              <p><h5>
                      <span className="label label-danger">
                        “CONDOMINO´S Todos los derechos reservados Copyright ©2021 Tnlgo. Wilson Herrera, correo electrónico wherrera.web@gmail.com”
                      </span>{" "}
                    </h5></p>
              </div>
                </div>
              </div>

            </div>
            <div className="row">
              <div
                className={
                  (cookies.get("id") == null ? "d-none" : "") + " col col-md-2"
                }
              >
                

                <nav className="navbar bg-warning navbar-light">
                 
                   <Navbar  expanded={expanded} expand='md' >
                 <Container>
                 <Navbar.Toggle  onClick={() =>this.onClick(expanded ? false : "expanded")} aria-controls='responsive-navbar-nav'/>
                 <Navbar.Collapse id='responsive-navbar-nav'>
                 <Nav className="flex-column" >

                  <ul className="nav navbar-nav">
                    <li className="active ">
                      <Link  className="btn btn active btn-outline-primary custom" style={{"borderRadius":"60px"}} to={"/inicio"} onClick={() =>this.onClick(false)}>
                        <span style={{ color: "white", fontSize: 20 }}>
                          <i className="fas fa-home"></i>Inicio
                        </span>
                      </Link>
                    </li>
                   
                    <li>
                      <Link className="btn btn active btn-outline-secondary  custom" style={{"borderRadius":"60px"}} to={"ListarGasto"} onClick={() =>this.onClick(false)}>
                        <span style={{ color: "#ffffff",fontSize: 20 }}>
                           <i className="far fa-money-bill-alt"></i>Gastos Comunes
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn btn active btn-outline-primary custom" style={{"borderRadius":"60px"}} to={"/listaControlP"} onClick={() =>this.onClick(false)}>
                        <span style={{ color: "#ffffff", fontSize: 20 }}>
                          <i className="far fa-address-card"></i> Control Peatonal
                        </span>
                      </Link>
                    </li>
                     <li>
                      <Link className="btn btn active btn-outline-secondary  custom" style={{"borderRadius":"60px"}} to={"listarControlV"} onClick={() =>this.onClick(false)}>
                        <span style={{ color: "#ffffff", fontSize: 20 }}>
                          <i className="fas fa-truck-pickup"></i> Control Vehicular
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn btn active btn-outline-primary custom" style={{"borderRadius":"60px"}} to={"/listaCop"} onClick={() =>this.onClick(false)}>
                        <span style={{ color: "#ffffff", fontSize: 20 }}>
                          <i className="fas fa-users"></i> Copropietario
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn btn active btn-outline-secondary  custom" style={{"borderRadius":"60px"}} to={"/listarAlicuota"} onClick={() =>this.onClick(false)}>
                        <span style={{ color: "#ffffff", fontSize: 20 }}>
                           <i className="fas fa-user-tag"></i>Alicuotas
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn btn active btn-outline-primary custom" style={{"borderRadius":"60px"}} to={"/listarInventario"} onClick={() =>this.onClick(false)}>
                        <span style={{ color: "#ffffff", fontSize: 20 }}>
                           <i className="fas fa-tools"></i> Inventario
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn btn active btn-outline-secondary  custom" style={{"borderRadius":"60px"}} to={"/listarServicio"} onClick={() =>this.onClick(false)}>
                        <span style={{ color: "#ffffff", fontSize: 20 }}>
                           <i className="fas fa-toolbox"></i> Servicios
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn btn active btn-outline-primary custom" style={{"borderRadius":"60px"}} to={"/listarProveedor"} onClick={() =>this.onClick(false)}>
                        <span style={{ color: "#ffffff", fontSize: 20 }}>
                          <i className="fas fa-user-cog"></i>Proveedores
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn btn active btn-outline-secondary  custom" style={{"borderRadius":"60px"}} to={"/listarConvenio"} onClick={() =>this.onClick(false)}>
                        <span style={{ color: "#ffffff", fontSize: 20 }}>
                          <i className="far fa-handshake"></i> Convenio Pago
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn btn active btn-outline-primary custom" style={{"borderRadius":"60px"}} to={"/listarIngresos"} onClick={() =>this.onClick(false)}>
                        <span style={{ color: "#ffffff", fontSize: 20 }}>
                            <i className="fas fa-thumbs-up"></i>Otros Ingresos
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn btn active btn-outline-secondary  custom" style={{"borderRadius":"60px"}} to={"/listarCobros"} onClick={() =>this.onClick(false)}>
                        <span style={{ color: "#ffffff", fontSize: 20 }}>
                            <i className="fas fa-thumbs-down"></i>Otros Por Cobrar
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn btn active btn-outline-primary custom" style={{"borderRadius":"60px"}} to={"/listarBalance"} onClick={() =>this.onClick(false)}>
                        <span style={{ color: "#ffffff", fontSize: 20 }}>

                          <i className="far fa-chart-bar"></i> Balance Finaciero
                        </span>
                      </Link>
                    </li> 
                    <li>
                      <Link className="btn btn active btn-outline-secondary custom" style={{"borderRadius":"60px"}} to={"/listarCostos"} onClick={() =>this.onClick(false)}>
                        <span style={{ color: "#ffffff", fontSize: 20 }}>

                          <i className="fa fa-university"></i> Costos Bancarios
                        </span>
                      </Link>
                    </li>  
                    <li>
                      <Link className="btn btn active btn-outline-primary custom" style={{"borderRadius":"60px"}} to={"/listarSaldo"} onClick={() =>this.onClick(false)}>
                        <span style={{ color: "#ffffff", fontSize: 20 }}>

                          <i className="fa fa-balance-scale"></i> Saldo Anterior
                        </span>
                      </Link>
                    </li> 
                     <li>
                      <Link className="btn btn active btn-outline-secondary  custom" style={{"borderRadius":"60px"}} to={"listarCaja"} onClick={() =>this.onClick(false)}>
                        <span style={{ color: "#ffffff",fontSize: 20 }}>
                           <i className="far fa-money-bill-alt"></i>Caja Chica
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn btn active btn-outline-primary  custom" style={{"borderRadius":"60px"}} to={"listarComprobante"} onClick={() =>this.onClick(false)}>
                        <span style={{ color: "#ffffff",fontSize: 20 }}>
                           <i className="fa fa-question-circle"></i>Editar Comprobante
                        </span>
                      </Link>

                    </li>                     
                  </ul>

                 </Nav>
                 </Navbar.Collapse>

                 </Container>

                 </Navbar>
                </nav>
                

                 
              </div>

              <div className=" col col-md-10">
                <Route exact path="/inicio" component={Inicio}></Route>
                <Route exact path="/login" component={Login}></Route>
                <Route exact path="/listaCop" component={ListaCop}></Route>
                <Route exact path="/crearCop" component={CrearCop}></Route>
                <Route exact path="/editarCop/:id" component={EditarCop}></Route>
                <Route exact path="/listaControlP" component={ListaControlP}></Route>
                <Route exact path="/crearControlP" component={CrearControlP}></Route>
                <Route exact path="/editarControlP/:id" component={EditarControlP}></Route>
                <Route exact path="/listarControlV" component={ListarControlV}></Route>
                 <Route exact path="/crearControlV" component={CrearControlV}></Route>
                 <Route exact path="/editarControlV/:id" component={EditarControlV}></Route>
                 <Route exact path="/listarAlicuota" component={ListarAlicuota}></Route>
                 <Route exact path="/registrarAlicuota" component={RegistrarAlicuota}></Route>
                 <Route exact path="/editarAlicuota/:id" component={EditarAlicuota}></Route>
                 <Route exact path="/listarInventario" component={ListarInventario}></Route>
                 <Route exact path="/registrarInventario" component={RegistrarInventario}></Route>
                  <Route exact path="/editarInventario/:id" component={EditarInventario}></Route>
                  <Route exact path="/listarServicio" component={ListarServicio}></Route>
                 <Route exact path="/registrarServicio" component={RegistrarServicio}></Route> 
                  <Route exact path="/editarServicio/:id" component={EditarServicio}></Route>
                  <Route exact path="/listarProveedor" component={ListaProveedor}></Route>
                  <Route exact path="/crearProveedor" component={CrearProveedor}></Route>
                  <Route exact path="/editarProveedor/:id" component={EditarProveedor}></Route>
                  <Route exact path="/listarGasto" component={ListarGasto}></Route>
                  <Route exact path="/registrarGasto" component={RegistrarGasto}></Route> 
                  <Route exact path="/editarGasto/:id" component={EditarGasto}></Route>
                   <Route exact path="/listarConvenio" component={ListarConvenio}></Route>
                   <Route exact path="/registrarConvenio" component={RegistrarConvenio}></Route> 
                    <Route exact path="/editarConvenio/:id" component={EditarConvenio}></Route>
                     <Route exact path="/listarIngresos" component={ListarIngreso}></Route>
                     <Route exact path="/registrarIngreso" component={RegistrarIngreso}></Route>
                     <Route exact path="/editarIngresos/:id" component={EditarIngresos}></Route>
                      <Route exact path="/listarCobros" component={ListarCobros}></Route>
                      <Route exact path="/registrarCobros" component={RegistrarCobros}></Route>
                      <Route exact path="/editarCobro/:id" component={EditarCobro}></Route>
                       <Route exact path="/listarBalance" component={ListarBalance}></Route>
                        <Route exact path="/paginador" component={Paginador}></Route>
                         <Route exact path="/estadoFinanciero/:id" component={EstadoFinanciero}></Route>
                         <Route exact path="/impresion/:id" component={Impresion}></Route>
                          <Route exact path="/pagoConsolidado" component={PagoConsolidado}></Route>
                          <Route exact path="/impresionConsolidada/:id" component={ImpresionConsolidada}></Route>
                          <Route exact path="/confirmacion/:id" component={Confirmacion}></Route>
                        <Route exact path="/listarCaja" component={ListarCajaChica}></Route>
                        <Route exact path="/crearIngresoCaja" component={CrearCajaChica}></Route>
                         <Route exact path="/listarCostos" component={ListarCostos}></Route>
                          <Route exact path="/registrarCostos" component={RegistrarCostos}></Route>
                          <Route exact path="/editarCosto/:id" component={EditarCostos}></Route>
                           <Route exact path="/listarSaldo" component={ListarSaldo}></Route>
                           <Route exact path="/registrarSaldo" component={RegistrarSaldo}></Route>
                           <Route exact path="/editarSaldo/:id" component={EditarSaldo}></Route>
                            <Route exact path="/listarComprobante" component={ListarComprobante}></Route>
                            <Route exact path="/editarComprobante/:id" component={EditarComprobante}></Route>

              </div>
            </div>
          </div>
       
        </Fragment>
      </Router>
    );
  }
}
