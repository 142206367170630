import './App.css';

import Login from "./componentes/login/Login";
import Cabecera from './componentes/cabecera/Cabecera';
import Container from "./componentes/container/Container";
import React,{Fragment} from 'react';
import Session from 'react-session-api';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Cookies from "universal-cookie";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const cookies= new Cookies;
export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {     
      conter :0,
    };
  }

  /*componentDidMount() {
    // console.log(this.props.match.params.id);
    fetch(Api +"copropietarios.php"+ "?consultar=" + this.props.match.params.id)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        // console.log(datosRespuesta);
        this.setState({
          datosCargados: true,
          copropietario: datosRespuesta[0],
        });
      })
      .catch(console.log);
  }*/
  componentDidMount() {
    const counter = (data) => {
      this.setState({ counter: data["counter"] });
    };
 
    Session.onSet(counter);
  }
render() {   
  const firebaseConfig = {
  apiKey: "AIzaSyBk2VfxF_pTlYhzD036BlBiJPMOUYm9nWg",
  authDomain: "notificacion-condominos.firebaseapp.com",
  projectId: "notificacion-condominos",
  storageBucket: "notificacion-condominos.appspot.com",
  messagingSenderId: "856616502712",
  appId: "1:856616502712:web:093a7c8e04b9ddb2b27b4d",
  measurementId: "G-FQSPHPHVS1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

  if(cookies.get('id')==null){
    // console.log("Nada que ver");
  }else{
    // console.log("Correcto");
  }
  // console.log(Session.get(1));
  return (
  <Fragment>
  <Cabecera/>
  
       <Container/>
}
     </Fragment> 
  );
}
}

