import React from "react";
import Api from "../servicios/Api";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class EditarCop extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datosCargados: false,
			copropietario: [],
			errores: [],
			id_copArr:"",
		};
		this.cambioValor=this.cambioValor.bind(this);
	}
	cambioValor = (e) => {
		const state = this.state.copropietario;
		state[e.target.name] = e.target.value;
		this.setState({copropietario:state, errores: [] });		
	};

	enviarDatos = (e) => {
		e.preventDefault();
		console.log("Formulario enviado.....");
		const{ID_CONDOMINO,ID,IDENTIFICACION,NOMBRE,CORREO,APELLIDO,DIRECCION,TELEFONO,CELULAR,CASA_OFI_DEP,PROPIETARIO_ARRENDATARIO}=this.state.copropietario;
		var datosEnviar = {
			ID_CONDOMINO:ID_CONDOMINO,
			ID:ID,
			IDENTIFICACION: IDENTIFICACION,
			NOMBRE: NOMBRE,
			APELLIDO: APELLIDO,
			CORREO: CORREO,
			DIRECCION: DIRECCION,
			TELEFONO: TELEFONO,
			CELULAR: CELULAR,
			CASA_OFI_DEP: CASA_OFI_DEP,	
			id_copArr:	PROPIETARIO_ARRENDATARIO,	
		};
		if(cookies.get("id_copArr")!=null){
			datosEnviar.id_copArr=cookies.get("id_copArr");
		}
		console.log(datosEnviar);
		var errores = [];
		if (!IDENTIFICACION) errores.push("error_identificacion");
		if (!NOMBRE) errores.push("error_nombre");
		if (!APELLIDO) errores.push("error_apellido");
		if (!CORREO) errores.push("error_correo");
		if (!CASA_OFI_DEP) errores.push("error_casa_ofi_dep");
		this.setState({ errores: errores });

		if (errores.length > 0) return false;

		fetch(Api + "copropietarios.php" + "?actualizar=1", {
			method: "POST",
			body: JSON.stringify(datosEnviar),
		})
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				window.location.href = "/listaCop";
			})
			.catch(console.log);
}
	componentDidMount() {
		console.log(this.props.match.params.id);
		fetch(
			Api +
				"copropietarios.php" +
				"?consultar=" +
				this.props.match.params.id
		)
			.then((respuesta) => respuesta.json())
			.then((datosRespuesta) => {
				console.log(datosRespuesta);
				this.setState({
					datosCargados: true,
					copropietario: datosRespuesta[0],
				});
			})
			.catch(console.log);
	}

	verificarError(elemento) {
		return this.state.errores.indexOf(elemento) !== -1;
	}

	render() {
		const { datosCargados, copropietario ,id_copArr} = this.state;
		let selectedOptionId = 0;
		const changeCopArr = (event: any) => {
			const state = this.state;
			state[id_copArr] = event.target.value;
			cookies.set("id_copArr", state[id_copArr], { path: "/" });
			console.log(cookies.get("id_copArr"));
		};
		
		if (!datosCargados) {
			return <div>Cargando....</div>;
		} else {
		return (
			<div className="card">
				<div className="card-body">
					<form onSubmit={this.enviarDatos}>						
						<div class="form-group ">
						  <label htmlFor="">Id</label>
						  <input onChange={this.cambioValor} readOnly type="text" className="form-control" name="ID_CONDOMINO" id="ID_CONDOMINO" value={copropietario.ID_CONDOMINO}/>
						  <small id="helpNombre" className="invalid-feeback">
								Id
							</small>
						</div>
						<div className="form-group">
						<label htmlFor="">Id Empresa:</label>
							<input readOnly
								onChange={this.cambioValor}
								value={copropietario.ID}
								type="number"
								name="ID"
								id="ID"
								className={
									(this.verificarError("error_identificacion")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Id Empresa"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Id Empresa
							</small>
							<br />
							<label htmlFor="">Identificación:</label>
							<input
								onChange={this.cambioValor}
								value={copropietario.IDENTIFICACION}
								type="number"
								name="IDENTIFICACION"
								id="IDENTIFICACION"
								className={
									(this.verificarError("error_identificacion")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Identificación"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe la Identificación del Condómino
							</small>
							<br />
							<label htmlFor="">Nombre:</label>
							<input
								onChange={this.cambioValor}
								value={copropietario.NOMBRE}
								type="text"
								name="NOMBRE"
								id="NOMBRE"
								className={
									(this.verificarError("error_nombre")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Nombre"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el nombre del Condómino
							</small>
							<br />
							<label htmlFor="">Apellido:</label>
							<input
								onChange={this.cambioValor}
								value={copropietario.APELLIDO}
								type="text"
								name="APELLIDO"
								id="APELLIDO"
								className={
									(this.verificarError("error_apellido")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Apellido"
							/>
							<small
								id="helpApellido"
								className="invalid-feeback"
							>
								Escribe el apellido del Condómino
							</small>
							<br />
							<label htmlFor="">Correo:</label>
							<input
								onChange={this.cambioValor}
								value={copropietario.CORREO}
								type="text"
								name="CORREO"
								id="CORREO"
								className={
									(this.verificarError("error_correo")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Correo Electrónico"
							/>
							<small id="helpCorreo" className="invalid-feeback">
								Escribe el correo del Condómino
							</small>
							<br />
							<label htmlFor="">Dirección:</label>
							<input
								onChange={this.cambioValor}
								value={copropietario.DIRECCION}
								type="text"
								name="DIRECCION"
								id="DIRECCION"
								className=" form-control"
								placeholder="Dirección"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe la Dirección del Condómino
							</small>
							<br />
							<label htmlFor="">Teléfono:</label>
							<input
								onChange={this.cambioValor}
								value={copropietario.TELEFONO}
								type="number"
								name="TELEFONO"
								id="TELEFONO"
								className=" form-control"
								placeholder="Teléfono"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Teléfono del Condómino
							</small>
							<br />
							<label htmlFor="">Celular:</label>
							<input
								onChange={this.cambioValor}
								value={copropietario.CELULAR}
								type="text"
								name="CELULAR"
								id="CELULAR"
								className=" form-control"
								placeholder="Celular"
							/>
							<small id="helpNombre" className="invalid-feeback">
								Escribe el Celular del Condómino
							</small>
							<br />
							<label htmlFor="">Casa-Departamento-Oficina:</label>
							<input
								onChange={this.cambioValor}
								value={copropietario.CASA_OFI_DEP}
								type="text"
								name="CASA_OFI_DEP"
								id="CASA_OFI_DEP"
								className={
									(this.verificarError("error_casa_ofi_dep")
										? "is-invalid"
										: "") + " form-control"
								}
								placeholder="Casa-Departamento-Oficina"
							/>
							<small id="helpCorreo" className="invalid-feeback">
								Escribe la Casa-Departamento-Oficina del
								Condómino
							</small>
							<br/>
							<div className="form-group ">
							<label htmlFor="">Valor Registrado</label>
							<div>
								<div className="alert alert-success">
									<strong>{copropietario.PROPIETARIO_ARRENDATARIO ==='P'? 'PROPIETARIO':'ARRENTARIO'}</strong>
								</div>
							</div>
						</div>
							<label htmlFor="">Propietario / Arrendatario:</label>
							<br/>
							<select
								defaultValue={selectedOptionId}
								class="custom-select custom-select-lg|custom-select-sm"
								onChange={(event) => {
									changeCopArr(event);
								}}
							>
								<option selected>Seleccionar</option>
								<option value="P">COPROPIETARIO</option>
								<option value="A">ARRENDATARIO</option>
							</select>
							<br />
						</div>
						<div
							className="btn-group"
							role="group"
							aria-label="Basic example"
						>
							<button type="submit" className="btn btn-success">
								Actualizar Condómino
							</button>
							<Link to={"/listaCop"} className="btn btn-primary">
								Cancelar
							</Link>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
}
