import React,  { Fragment } from 'react';

export default class Paginador extends React.Component {

render(){
  return(
    <Fragment>
    <div className="card">
      <div className="card-body">
        This is some text within a card body.
      </div>
    </div>
    </Fragment>
    );
}
}


